import React, { useEffect, useState } from 'react'
import { Typography, Box, Stack, FormControlLabel, Checkbox, TextField, CircularProgress } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import ja from 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Layout from '../../components/Layout'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../style/theme'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { BarChart } from '@mui/x-charts/BarChart'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useGetPatientStatusVisualReport } from 'api/report'
import { PatientStatusVisualResponse } from 'api/report/responseType'
import { DateRangePicker } from 'components/DateRangePicker'
import useResetQuery from 'api/useResetQuery'

const Report = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()
  dayjs.locale(ja)

  const [init, setInit] = useState(true)

  const [fromG, setFromG] = useState<Dayjs | null>(dayjs().subtract(1, 'month'))
  const [toG, setToG] = useState<Dayjs | null>(dayjs())
  const {
    data: graphData, isError: isGraphError, isPending: isGraphPending,
    isRefetching: isGraphRefetchPending, isRefetchError: isGraphRefetchError
  } = useGetPatientStatusVisualReport({
    req: {
      from: fromG?.format('YYYY-MM-DD'),
      to: toG?.format('YYYY-MM-DD'),
    }
  })
  const [ graph, setGraph ] = useState<PatientStatusVisualResponse | null>(null)

  const [ fromT, setFromT ] = useState<Dayjs | null>(dayjs().subtract(6, 'month'))
  const [ toT, setToT ] = useState<Dayjs | null>(dayjs())
  const {
    data: tableData, isError: isTableError, isPending: isTablePending,
    isRefetching: isTableRefetchPending, isRefetchError: isTableRefetchError
  } = useGetPatientStatusVisualReport({
    req: {
      unit: 'month',
      from: fromT?.format('YYYY-MM-01'),
      to: toT?.format('YYYY-MM-01'),
    },
    table: true
  })
  const [ table, setTable ] = useState<PatientStatusVisualResponse | null>(null)

  const [ fme, setFme ] = useState(true)
  const [ dia, setDia ] = useState(true)
  const [ cmp, setCmp ] = useState(true)
  const [ at, setAt ] = useState(true)
  const [ ret, setRet ] = useState(true)
  const [ ins, setIns ] = useState(true)
  const resetQuery = useResetQuery()

  const getSeries = () => {
    const series = []
    if (fme) series.push({ dataKey: 'fme', stack: 'A', label: '新規相談'})
    if (dia) series.push({ dataKey: 'dia', stack: 'A', label: '診断前'})
    if (cmp) series.push({ dataKey: 'cmp', stack: 'A', label: '終了'})
    if (at) series.push({ dataKey: 'at', stack: 'B', label: '動的処置中'})
    if (ret) series.push({ dataKey: 'ret', stack: 'B', label: '保定治療中'})
    if (ins) series.push({ dataKey: 'ins', stack: 'B', label: '検査中'})
    return series
  }

  const getColors = () => {
    const colors = []
    if (fme) colors.push('#EAB221')
    if (dia) colors.push('#F9CC57')
    if (cmp) colors.push('#FFEBB8')
    if (at) colors.push('#F44498')
    if (ret) colors.push('#FA99C8')
    if (ins) colors.push('#F7D8E7')
    return colors
  }

  const [ threshold, setThreshold ] = useState(10000)

  useEffect(() => {
    if (init) {
      resetQuery(['patientStatusGraph'], 0)
      resetQuery(['patientStatusTable'], 0)
      setInit(false)
    } else {
      if (
        isGraphError || isGraphRefetchError || isTableError || isTableRefetchError
      ) {
        console.error(isGraphError)
        console.error(isGraphRefetchError)
        console.error(isTableError)
        console.error(isTableRefetchError)
        return
      }
  
      if(!isGraphPending && !isGraphRefetchPending && graphData !== undefined){
        setGraph(graphData)
      }
  
      if(!isTablePending && !isTableRefetchPending && tableData !== undefined){
        setTable(tableData)
      }
    }
  }, [
    graphData, isGraphError, isGraphPending, isGraphRefetchError, isGraphRefetchPending,
    tableData, isTableError, isTablePending, isTableRefetchError, isTableRefetchPending,
    init, resetQuery
  ])

  const changeGraphTerm = () => {
    resetQuery(['patientStatusGraph'])
  }

  const changeTableTerm = () => {
    resetQuery(['patientStatusTable'])
  }

  const changeGraphVisible = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const checked = event.target.checked
    switch (type) {
      case 'fme':
        setFme(checked)
        break
      case 'dia':
        setDia(checked)
        break
      case 'cmp':
        setCmp(checked)
        break
      case 'at':
        setAt(checked)
        break
      case 'ret':
        setRet(checked)
        break
      case 'ins':
        setIns(checked)
        break
      default:
        // do nothing
    }
  }

  const tableBorder = {
    borderLeft: '1px solid #EFEFEF',
    borderRight: '1px solid #EFEFEF',
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1, mb: 2 }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Stack direction="row" alignItems="center" columnGap={2}>
                <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <AssessmentIcon sx={{ color: theme.palette.text.primary }}/>
                </Box>
                <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>グラフで見るレポート</Typography>
              </Stack>
            </Stack>
            <Stack mt={2} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Stack>
                <Stack direction="row" columnGap={4}>
                  <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeGraphVisible(e, 'fme')} />} label="新規相談" />
                  <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeGraphVisible(e, 'dia')} />} label="診断前" />
                  <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeGraphVisible(e, 'cmp')} />} label="終了" />
                </Stack>
                <Stack direction="row" columnGap={4}>
                  <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeGraphVisible(e, 'at')} />} label="動的処置中" />
                  <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeGraphVisible(e, 'ret')} />} label="保定治療中" />
                  <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => changeGraphVisible(e, 'ins')} />} label="検査中" />
                </Stack>
              </Stack>
              <Box>
                <DateRangePicker from={fromG} to={toG} setFrom={setFromG} setTo={setToG} changeTerm={changeGraphTerm} labelLess={true}/>
              </Box>
            </Stack>
            <BarChart
              dataset={init || isGraphPending || isGraphRefetchPending ? [] : graph?.data ?? []}
              xAxis={[{ scaleType: 'band', dataKey: 'group' }]}
              series={getSeries()}
              colors={getColors()}
              loading={init || isGraphPending || isGraphRefetchPending || !(graph?.data)}
              height={350}
              slotProps={{
                legend: {
                  position: { vertical: 'top', horizontal: 'right' },
                  itemMarkHeight: 18,
                  itemMarkWidth: 18,
                  markGap: 4,
                  itemGap: 10,
                  labelStyle: {
                    fontSize: 12,
                  },
                }
              }}
            />
          </Box>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1, mb: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Stack direction="row" alignItems="center" columnGap={2}>
                <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <AssessmentIcon sx={{ color: theme.palette.text.primary }}/>
                </Box>
                <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>表で見るレポート</Typography>
              </Stack>
            </Stack>
            <Stack mt={2} direction="row" justifyContent="space-between" alignItems="flex-end">
              <Box>
                <TextField
                  type='number' value={threshold} onChange={(e) => setThreshold(parseInt(e.target.value))} label='しきい値'
                  sx={{display: 'none'}}
                />
              </Box>
              <DateRangePicker
                from={fromT} to={toT} setFrom={setFromT} setTo={setToT} changeTerm={changeTableTerm}
                monthMode={true} labelLess={true}
              />
            </Stack>
            {
              table?.data ? (
                <Table sx={{ minWidth: 650, mt: 4, tableLayout: 'fixed' }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{background: "#E3E3E3"}}>
                      <TableCell></TableCell>
                      <TableCell align="center" sx={tableBorder}>新規相談</TableCell>
                      <TableCell align="center" sx={tableBorder}>診察前</TableCell>
                      <TableCell align="center" sx={tableBorder}>終了</TableCell>
                      <TableCell align="center" sx={tableBorder}>動的処置中</TableCell>
                      <TableCell align="center" sx={tableBorder}>保定治療中</TableCell>
                      <TableCell align="center" sx={tableBorder}>検査中</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table?.data.map((row, index) => (
                      <TableRow
                        key={index}
                      >
                        <TableCell component="th" scope="row" sx={{background: "#F3F3F3"}}>{row.group}</TableCell>
                        <TableCell align="center" sx={row.fme >= threshold ? {...tableBorder, ...{background: '#FFE4E4'}} : tableBorder}>{row.fme}</TableCell>
                        <TableCell align="center" sx={row.dia >= threshold ? {...tableBorder, ...{background: '#FFE4E4'}} : tableBorder}>{row.dia}</TableCell>
                        <TableCell align="center" sx={row.cmp >= threshold ? {...tableBorder, ...{background: '#FFE4E4'}} : tableBorder}>{row.cmp}</TableCell>
                        <TableCell align="center" sx={row.at >= threshold ? {...tableBorder, ...{background: '#FFE4E4'}} : tableBorder}>{row.at}</TableCell>
                        <TableCell align="center" sx={row.ret >= threshold ? {...tableBorder, ...{background: '#FFE4E4'}} : tableBorder}>{row.ret}</TableCell>
                        <TableCell align="center" sx={row.ins >= threshold ? {...tableBorder, ...{background: '#FFE4E4'}} : tableBorder}>{row.ins}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Stack sx={{ minWidth: 650, mt: 4 }} direction="row" justifyContent="center" >
                  <CircularProgress/>
                </Stack>
              )
            }
          </Box>
        </Layout>
      </ThemeProvider>
    </>
  )

}
export default Report
