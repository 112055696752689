import { client } from 'lib/AxiosClientProvider'
import { DashboardCardResponse, SlackingCountResponse, PatientStatusListResponse, PatientStatusVisualResponse, CapacityResponse } from './responseType'
import { Capacity, DashBoardCard, PatientStatusList, PatientStatusVisual } from './requestType'

export const getDashboardCardReport = async ({
  req
}: {
  req: DashBoardCard
}): Promise<DashboardCardResponse> => {
  const response = await client.get('/report/dashboard', {
    params: req
  })

  return response.data
}

export const getSlackingCountReport = async (): Promise<SlackingCountResponse> => {
  const response = await client.get('/report/slacking')

  return response.data
}

export const getPatientStatusListReport = async ({
  req
}: {
  req: PatientStatusList
}): Promise<PatientStatusListResponse> => {
  const response = await client.get('/report/status/list', {
    params: req
  })

  return response.data
}

export const getPatientStatusVisualReport = async ({
  req
}: {
  req: PatientStatusVisual
}): Promise<PatientStatusVisualResponse> => {
  const response = await client.get('/report/status/visual', {
    params: req
  })

  return response.data
}

export const getCapacityReport = async ({
  req
}: {
  req: Capacity
}): Promise<CapacityResponse> => {
  const response = await client.get('/report/capacity', {
    params: req
  })

  return response.data
}
