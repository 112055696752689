import { useState, useEffect, useCallback } from 'react'

const useSecretModeFlag = (initialValue: boolean) => {
  const key = 'isSecretMode'

  const [flag, setFlag] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(key)
    return storedValue !== null ? JSON.parse(storedValue) : initialValue
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(flag))
  }, [key, flag])

  const toggleFlag = useCallback(() => {
    setFlag(prevFlag => !prevFlag)
  }, [])

  const setFlagValue = useCallback((value: boolean) => {
    setFlag(value)
  }, [])

  return {flag, toggleFlag, setFlagValue} as const
}

export default useSecretModeFlag
