import React, { useState, MutableRefObject, useRef, useEffect } from 'react'
import { Box, Button, Grid, Stack } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'
import NorthWestIcon from '@mui/icons-material/NorthWest'
import NorthIcon from '@mui/icons-material/North'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import WestIcon from '@mui/icons-material/West'
import CircleIcon from '@mui/icons-material/Circle'
import EastIcon from '@mui/icons-material/East'
import SouthWestIcon from '@mui/icons-material/SouthWest'
import SouthIcon from '@mui/icons-material/South'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import CallMissedIcon from '@mui/icons-material/CallMissed'
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing'
import Modal from '@mui/material/Modal'
import theme from '../style/theme'
import { PhotoObject } from 'types/PhotoObject'
import PhotoEditFrame from 'components/PhotoEditFrame'
import { useWindowSize } from 'lib/useWindowSize'
import { LongPress } from 'lib/LongPress'

export type ConfirmModalOnCloseProps = {
  modalStatus: string
  image: PhotoObject
}

export type PhotoEditorProps = {
  onClose: (ConfirmModalOnCloseProps:{ modalStatus :string, image: PhotoObject }) => void
  image: PhotoObject
  imageRefs: MutableRefObject<(HTMLImageElement | null)[]>
}

export const PhotoEditor = (props: PhotoEditorProps) => {
  const { onClose, image, imageRefs } = props
  const [ editedImage, setEditedImage ] = useState(structuredClone(image))
  const { windowWidth } = useWindowSize()
  const [ isIpad, setIsIpad] = useState(windowWidth < 1025)
  const [ isScreen, setIsScreen] = useState(windowWidth >= 1400)
  const ref = useRef<HTMLDivElement | null>(null)
  const basicButtonWidth = isScreen ? 145 : (isIpad ? 120 : 130)

  useEffect(() => {
    setIsIpad(windowWidth < 1025)
    setIsScreen(windowWidth >= 1400)
  }, [windowWidth])

  const flipImage = () => {
    editedImage.affine.flip = !editedImage.affine.flip
    setEditedImage(editedImage)
    imageRefs.current[0]?.click()
  }

  const flopImage = () => {
    editedImage.affine.flop = !editedImage.affine.flop
    setEditedImage(editedImage)
    imageRefs.current[0]?.click()
  }

  const rotateImage = (clock: boolean, perpendicular: boolean) => {
    const rotateDegree = perpendicular ? 90 : 1
    const degree = editedImage.affine.rotate + (clock ? rotateDegree : rotateDegree * -1)
    editedImage.affine.rotate = degree > 359 ? degree - 360 : degree < 0 ? degree + 360 : degree
    setEditedImage(editedImage)
    imageRefs.current[0]?.click()
  }

  const scaleImage = (expand: boolean) => {
    const scale = 0.05
    editedImage.affine.scale = editedImage.affine.scale + (expand ? scale : scale * -1)
    setEditedImage(editedImage)
    imageRefs.current[0]?.click()
  }

  const moveImage = (props: {x: number, y: number}) => {
    editedImage.affine.translateX += props.x * 10
    editedImage.affine.translateY += props.y * 10
    setEditedImage(editedImage)
    imageRefs.current[0]?.click()
  }

  const resetEdit = () => {
    editedImage.affine.rotate = image.affine.rotate
    editedImage.affine.scale = image.affine.scale
    editedImage.affine.flip = image.affine.flip
    editedImage.affine.flop = image.affine.flop
    editedImage.affine.translateX = image.affine.translateX
    editedImage.affine.translateY = image.affine.translateY
    setEditedImage(editedImage)
    imageRefs.current[0]?.click()
  }

  const resetOrigin = () => {
    editedImage.affine.rotate = 0
    editedImage.affine.scale = 1
    editedImage.affine.flip = false
    editedImage.affine.flop = false
    editedImage.affine.translateX = 0
    editedImage.affine.translateY = 0
    setEditedImage(editedImage)
    imageRefs.current[0]?.click()
  }

  const arrowMatrix = [
    { moveFunc: LongPress(() => moveImage({x: -1, y: -1}), true, 100, 500), arrow: <NorthWestIcon sx={{ fontSize: 18 }} />},
    { moveFunc: LongPress(() => moveImage({x: 0, y: -1}), true, 100, 500), arrow: <NorthIcon sx={{ fontSize: 18 }} />},
    { moveFunc: LongPress(() => moveImage({x: 1, y: -1}), true, 100, 500), arrow: <NorthEastIcon sx={{ fontSize: 18 }} />},
    { moveFunc: LongPress(() => moveImage({x: -1, y: 0}), true, 100, 500), arrow: <WestIcon sx={{ fontSize: 18 }} />},
    { moveFunc: LongPress(() => moveImage({x: 0, y: 0}), true, 100, 500), arrow: <CircleIcon sx={{ fontSize: 18 }} />},
    { moveFunc: LongPress(() => moveImage({x: 1, y: 0}), true, 100, 500), arrow: <EastIcon sx={{ fontSize: 18 }} />},
    { moveFunc: LongPress(() => moveImage({x: -1, y: 1}), true, 100, 500), arrow: <SouthWestIcon sx={{ fontSize: 18 }} />},
    { moveFunc: LongPress(() => moveImage({x: 0, y: 1}), true, 100, 500), arrow: <SouthIcon sx={{ fontSize: 18 }} />},
    { moveFunc: LongPress(() => moveImage({x: 1, y: 1}), true, 100, 500), arrow: <SouthEastIcon sx={{ fontSize: 18 }} />}
  ]

  return (
    <>
      <Modal
        open onClose={() => onClose({modalStatus: 'close', image: editedImage})}
        ref={ref}
      >
        <Box
          sx={{
            p:2, width: isIpad ? '660px' : '800px', height: isIpad ? '600px' : '730px',
            position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
            bgcolor: "#333333", borderRadius: "8px"
          }}
        >
          <Stack direction="column" alignItems="center" gap={2}>
            <PhotoEditFrame image={editedImage} imageRefs={imageRefs} />
            <Stack direction="row" gap={ isIpad ? 1 : 2 }>
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={ isIpad ? 1 : 2 }>
                  <Button
                    onClick={flipImage}
                    sx={{
                      width: basicButtonWidth, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                      color: theme.palette.primary.dark, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                      "&:hover": { background: theme.palette.primary.dark, color: "white" }
                    }}
                  >
                      <SwapVertIcon sx={{ mr: 0.5, fontSize: 18 }} />上下反転
                  </Button>
                  <Button
                    onClick={flopImage}
                    sx={{
                      width: basicButtonWidth, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                      color: theme.palette.primary.dark, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                      "&:hover": { background: theme.palette.primary.dark, color: "white" }
                    }}
                  >
                      <SwapHorizIcon sx={{ mr: 0.5, fontSize: 18 }} />左右反転
                  </Button>
                  <Button
                    onClick={() => rotateImage(false, false)}
                    sx={{
                      width: basicButtonWidth, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                      color: theme.palette.primary.dark, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                      "&:hover": { background: theme.palette.primary.dark, color: "white" }
                    }}
                  >
                      <UndoIcon sx={{ mr: 0.5, fontSize: 18 }} />左に回転
                  </Button>
                  <Button
                    onClick={() => rotateImage(true, false)}
                    sx={{
                      width: basicButtonWidth, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                      color: theme.palette.primary.dark, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                      "&:hover": { background: theme.palette.primary.dark, color: "white" }
                    }}
                  >
                      <RedoIcon sx={{ mr: 0.5, fontSize: 18 }} />右に回転
                  </Button>
                </Stack>
                <Stack direction="row" gap={ isIpad ? 1 : 2 }>
                  <Button
                    onClick={() => scaleImage(true)}
                    sx={{
                      width: basicButtonWidth, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                      color: theme.palette.primary.dark, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                      "&:hover": { background: theme.palette.primary.dark, color: "white" }
                    }}
                  >
                      <ZoomOutMapIcon sx={{ mr: 0.5, fontSize: 18 }} />拡大
                  </Button>
                  <Button
                    onClick={() => scaleImage(false)}
                    sx={{
                      width: basicButtonWidth, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                      color: theme.palette.primary.dark, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                      "&:hover": { background: theme.palette.primary.dark, color: "white" }
                    }}
                  >
                      <ZoomInMapIcon sx={{ mr: 0.5, fontSize: 18 }} />縮小
                  </Button>
                  <Button
                    onClick={() => rotateImage(false, true)}
                    sx={{
                      width: basicButtonWidth, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                      color: theme.palette.primary.dark, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                      "&:hover": { background: theme.palette.primary.dark, color: "white" }
                    }}
                  >
                      <CallMissedIcon sx={{ mr: 0.5, fontSize: 18, transform: 'scale(1, -1) rotate(45deg)' }} />左に90°回転
                  </Button>
                  <Button
                    onClick={() => rotateImage(true, true)}
                    sx={{
                      width: basicButtonWidth, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                      color: theme.palette.primary.dark, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                      "&:hover": { background: theme.palette.primary.dark, color: "white" }
                    }}
                  >
                      <CallMissedOutgoingIcon sx={{ mr: 0.5, fontSize: 18, transform: 'scale(1, -1) rotate(-45deg)' }} />右に90°回転
                  </Button>
                </Stack>
              </Stack>
              <Grid container columns={3} sx={{ width: (24 + 12 + 2) * 3 }}>
                {
                  arrowMatrix.map((arrow, index) => {
                    return index !== 4 ? (
                      <Grid item key={index}>
                        <Button
                          sx={{
                            minWidth: 24, width: 38, height: 38, padding: "6px", textAlign: "center", fontSize: "14px", fontWeight: "bold",
                            color: theme.palette.primary.dark, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                            "&:hover": { background: theme.palette.primary.dark, color: "white" }
                          }}
                          {...arrow.moveFunc}
                        >
                          {arrow.arrow}
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item key={index}>
                        <Button
                          sx={{
                            minWidth: 24, width: 38, height: 38, padding: "6px", textAlign: "center", fontSize: "14px", 
                            borderRadius: "40px", border: "1px solid #22BDB400",
                            color: '#00000000', cursor: 'default'
                          }}
                        >
                          {arrow.arrow}
                        </Button>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Stack>
            <Stack direction="row" alignItems="center" gap={ isIpad ? 1 : 3 } sx={{ mt: 1 }}>
              <Button
                onClick={() => onClose({ modalStatus: 'ok', image: editedImage})}
                sx={{
                  width: 150, fontWeight: "bold", textAlign: "center", fontSize: "14px", color: "white",
                  background: theme.palette.primary.main, borderRadius: "40px", "&:hover": { background: "#11867F" }
                }}
              >
                適用
              </Button>
              <Button
                onClick={() => onClose({modalStatus: 'close', image: editedImage })}
                sx={{
                  width: 150, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                  color: theme.palette.primary.main, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                  "&:hover": { background: theme.palette.primary.main, color: "white" }
                }}
              >
                キャンセル
              </Button>
              <Button
                onClick={resetEdit}
                sx={{
                  width: 150, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                  color: theme.palette.primary.main, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                  "&:hover": { background: theme.palette.primary.main, color: "white" }
                }}
              >
                編集リセット
              </Button>
              <Button
                onClick={resetOrigin}
                sx={{
                  width: 150, textAlign: "center", fontSize: "14px", fontWeight: "bold",
                  color: theme.palette.primary.main, background: "white", borderRadius: "40px", border: "1px solid #22BDB4",
                  "&:hover": { background: theme.palette.primary.main, color: "white" }
                }}
              >
                オリジナル
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
export default PhotoEditor;