import { ViewerTooltipFlags } from "components/CarouselPhoto"

export const chunk = <T extends any[]>(arr: T, size: number) => {
  return arr.reduce(
    (newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]),
    [] as T[][]
  )
}

export const angleFlags = (arr: number[]) => {
  const flags: ViewerTooltipFlags = {}
  arr.map(item => {
    flags[item] = false
    return false
  })
  return flags
}