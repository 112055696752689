import React, { useState, useEffect, useRef } from 'react'
import { Box, Stack, CircularProgress } from '@mui/material'
import ImageRender from 'components/ImageRender'
import { PhotoObject } from 'types/PhotoObject'
import { useWindowSize } from 'lib/useWindowSize'

interface PhotoFrameProps {
  image?: PhotoObject
}

const DiffPhotoFrame = (props: PhotoFrameProps) => {
  const { image } = props
  const { windowWidth } = useWindowSize()
  const [ isIpad, setIsIpad] = useState(windowWidth < 1025)
  const [ isScreen, setIsScreen] = useState(windowWidth >= 1400)
  const ref = useRef<HTMLDivElement | null>(null)
  const frameWidth = isScreen ?  620 : (isIpad ? 260 : 320)
  const frameHeight = isScreen ? 465 : (isIpad ? 195 : 240)

  const ImageBoxStyle = {
    width: `${frameWidth}px`, height: `${frameHeight}px`, fontWeight: '600',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    overflow: 'hidden', backgroundColor: 'inherit'
  }

  useEffect(() => {
    setIsIpad(windowWidth < 1025)
    setIsScreen(windowWidth >= 1400)
  }, [windowWidth])

  return (
    <Stack position="relative" ref={ref}>
      <Box {...ImageBoxStyle} sx={{objectFit: 'cover'}}>
        {
          image ?
          <ImageRender
            image={image} isNotLazy={true}
            frameWidth={frameWidth} frameHeight={frameHeight} 
          />:
          <CircularProgress />
        }
      </Box>
    </Stack>
  )
}
export default DiffPhotoFrame
