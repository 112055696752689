import React, { useState } from 'react'
import { Typography, Box, Stack, FormControl, FormLabel, TextField } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'style/theme'
import CircularProgressWithLabel from './CircularProgressWithLabel'

export type Patient02Props = {
  show: boolean
  handleChange: Function
  name: string
  kana: string
  nameError: string[]
  kanaError: string[]
}

const Patient02 = (props: Patient02Props) => {
  const { show, handleChange, name, kana, nameError, kanaError } = props
  const [progress] = useState(40)

  const display = show ? "block" : "none"

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: display }}>
          <Stack sx={{ display: "flex", alignItems: "center", columnGap: "24px" }} direction = "row">
            <Box sx={{ mt: 3, position: 'relative' }}>
              <Box
                sx={{ 
                  margin: "auto",
                  width: "54px",
                  height: "54px",
                  position: 'absolute',
                  border: "solid #D8EAE9 5px",
                  borderRadius: "50%",
                  top: 0,
                  left: 0,
                }}>
              </Box>
              <CircularProgressWithLabel progress={progress} page={2} />
            </Box>
            <Box><Typography sx={{ fontWeight: "600", backgroundColor: "#F1F1F1", borderRadius: "8px", py: 2, px: 4, mt: 2 }} color="#364F4E">患者名を入力してください。</Typography></Box>
          </Stack>
          <Stack sx={{ display: "flex", alignItems: "flex-start", rowGap: "24px", mt: 3, ml: 8 }}>
            <FormControl>
              <Stack sx={{ display: "flex", alignItems: "center", columnGap: "16px" }} direction = "row">
                <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "16px" }}>患者名</FormLabel>
                <Typography sx={{ fontWeight: "600", color: "white", background: "#F4817A", borderRadius: "24px", fontSize: "12px", px: 2 }}>必須</Typography>
              </Stack>
              <Typography sx={{ color: 'gray', fontSize: "12px", mt: 0.5}}>24文字まで入力可能です。</Typography>
              <TextField
                variant="outlined" placeholder="入力してください。" value={name}
                sx={{ background: "white",borderRadius: "8px", mt: 1, width: 480 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'name')}
                error={nameError.length > 0}
                inputProps={{maxLength: 24}}
              />
              {nameError.map((error, index) => {
                return (
                  <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
                )
              })}
            </FormControl>
            <FormControl>
              <Stack sx={{ display: "flex", alignItems: "center", columnGap: "16px" }} direction = "row">
                <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "16px" }}>患者名（よみがな）</FormLabel>
                <Typography sx={{ fontWeight: "600", color: "white", background: "#F4817A", borderRadius: "24px", fontSize: "12px", px: 2 }}>必須</Typography>
              </Stack>
              <Typography sx={{ color: 'gray', fontSize: "12px", mt: 0.5}}>24文字まで入力可能です。</Typography>
              <TextField
                variant="outlined" placeholder="入力してください。" value={kana}
                sx={{ background: "white",borderRadius: "8px", mt: 1, width: 480 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'kana')}
                error={kanaError.length > 0}
                inputProps={{maxLength: 24}}
              />
              {kanaError.map((error, index) => {
                return (
                  <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
                )
              })}
            </FormControl>            
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Patient02
