import { useLayoutEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { Avatar } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { Affine } from 'api/patient/responseType'

interface AvatarRenderProps {
  imagePath: string
  affine: Affine
  frameWidth: number
  frameHeight: number
}

function ceil1000(number: number) {
  return number > 0 ? Math.ceil(number * 1000) / 1000 : Math.floor(number * 1000) / 1000
}

export const AvatarRender = (props: AvatarRenderProps) => {
  const { imagePath, affine, frameWidth, frameHeight } = props
  const imageRef = useRef<HTMLDivElement>(null)
  const [beacon, setBeacon] = useState(false)

  useLayoutEffect(() => {
    setTimeout(() => {
      if (imageRef.current) {
        // 要素定義
        const parentNode = imageRef.current
        const imageNode = imageRef.current.firstElementChild
        if (imageNode instanceof HTMLImageElement) {
          // 各種affine値抽出
          const flop = affine.flop ? -1 : 1
          const flip = affine.flip ? -1 : 1
          const rotate = affine.rotate
          const scale = affine.scale
          const translateX = affine.translateX
          const translateY = affine.translateY
          // 画像の横縦を抽出
          const naturalWidth = imageNode.naturalWidth
          const naturalHeight = imageNode.naturalHeight
          // 表示枠に即した画像の縮小率を算出
          const reduction = Math.max(frameWidth / naturalWidth, frameHeight / naturalHeight) * scale
          // 移動枠に即した移動値の算出
          const positionX = translateX
          const positionY = translateY
          // 各種属性値の設定
          const transformRotate = `rotate(${rotate}deg)`
          const transformScale = `scale(${ceil1000(reduction * flop)}, ${ceil1000(reduction * flip)})`
          const transformTranslate = `translate(${positionX}px, ${positionY}px)`
          imageNode.style.transform = `${transformRotate} ${transformScale} ${transformTranslate}`
          imageNode.style.width = `${naturalWidth}px`
          imageNode.style.height = `${naturalHeight}px`

          // 各種属性値の設定
          parentNode.style.width = `${frameWidth}px`
          parentNode.style.height = `${frameHeight}px`
        }
      }
    }, 10)
  }, [affine, frameWidth, frameHeight, imageRef, beacon])

  const imageRefresh = () => {
    setBeacon(!beacon)
  }

  return (
    <Box>
      <ListItemAvatar>
        <Avatar
          alt="患者アイコン" src={imagePath}
          sx={{ width: `${frameWidth}px`, height: `${frameHeight}px`, backgroundColor: '#777777' }}
          onLoad={() => imageRefresh()} ref={imageRef}
        >
          {!imagePath && <PersonIcon sx={{ fontSize: 64 }} />}
        </Avatar>
      </ListItemAvatar>
    </Box>
  )
}

export default AvatarRender
