import React , {useCallback, useEffect, useRef, useState} from 'react'
import Layout from '../../components/Layout'
import { Box, Typography, Stack, FormControl, FormLabel, TextField, IconButton, CircularProgress, Pagination } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import theme from '../../style/theme'
import SearchIcon from '@mui/icons-material/Search'
import PersonIcon from '@mui/icons-material/Person'
import List from '@mui/material/List'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import type { ListPatient }  from '../../api/patient/requestType'
import type { Patient, PatientIndexes }  from '../../api/patient/responseType'
import { useAuth } from 'hooks/useAuth'
import PatientListItem from '../../pages/list/PatientListItem'
import useSecretModeFlag from 'hooks/useSecretModeFlag'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import OperationProof from 'components/OperationProof'
import { useGetPatientList, useGetPatientAllIndexes } from 'api/patient'
import { SearchDetailSchema } from 'schema/search/searchDetailSchema'
import SearchDetailPatient from 'components/SearchDetailPatient'
import useResetQuery from 'api/useResetQuery'

const PageSize = 10

const PatientList = () => {
  const auth = useAuth()
  const [items, setItems] = useState<Patient[]>([])
  const [indexes, setIndexes] = useState<PatientIndexes[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const pageFromParams = parseInt(searchParams.get('page') || '1', 10)
  const [page, setPage] = useState(pageFromParams)
  const [pageAmount, setPageAmount] = useState(1)
  const parseArrayParam = (param: string | null | undefined): number[] | undefined => {
    return param ? param.split(',').map(Number).filter(n => !isNaN(n)) : undefined
  }

  const [searchParam, setSearchParam] = useState<ListPatient>({
    page: page,
    limit: PageSize,
    ...(searchParams.get('status') !== '' && searchParams.get('status') !== null && { status: Number(searchParams.get('status')) }),
    ...(searchParams.get('age') !== '' && searchParams.get('age') !== null && { age: Number(searchParams.get('age')) }),
    ...(searchParams.get('skeletalElements') !== undefined && { skeletal: parseArrayParam(searchParams.get('skeletalElements')) }),
    ...(searchParams.get('facialFeatures') !== undefined && { faceType: parseArrayParam(searchParams.get('facialFeatures')) }),
    ...(searchParams.get('occlusionRight') !== undefined && { angleRight: parseArrayParam(searchParams.get('occlusionRight')) }),
    ...(searchParams.get('occlusionLeft') !== undefined && { angleLeft: parseArrayParam(searchParams.get('occlusionLeft')) }),
    ...(searchParams.get('otherFactors') !== undefined && { otherElement: parseArrayParam(searchParams.get('otherFactors')) }),
  })

  const resetQuery = useResetQuery()
  const {
    data: patientData, isError: isPatientError, isPending: isPatientPending,
    isRefetchError: isPatientRefetchError, isRefetching: isPatientRefetchPending,
    isPlaceholderData: isPatientPlaceholderData
  } = useGetPatientList(searchParam)
  const {
    data: indexData, isError: isIndexError, isPending: isIndexPending
  } = useGetPatientAllIndexes()
  const { flag, toggleFlag } = useSecretModeFlag(false)
  const navigate = useNavigate()
  const location = useLocation()

  const [searchValues, setSearchValues] = useState<Partial<SearchDetailSchema>>({
    status: undefined,
    age: undefined,
    skeletalElements: [],
    occlusionRight: [],
    occlusionLeft: [],
    facialFeatures: [],
    otherFactors: []
  })

  const hideText = '*****'

  const obfuscateNames = useCallback((patients: Patient[]) => {
    return patients.map(patient => ({
      ...patient,
      name: flag ? patient.name.substring(0, 1) + hideText : patient.name,
      kana: flag ? patient.kana.substring(0, 1) + hideText : patient.kana
    }))
  }, [flag])

  const handleSelectUser = (_event: React.ChangeEvent<undefined>, newValue: PatientIndexes | string | null) => {
    if (newValue && typeof newValue !== 'string') {
      navigate({ pathname: `/${auth.clinicid}/patient/${newValue.id}` })
    }
  }

  const getMaskedLabel = useCallback((option: PatientIndexes | string): string => {
    if (typeof option === 'string') {
      return option
    }

    const maskedName = flag ? option.name.substring(0, 1) + hideText : option.name

    return `${option.patientNo} ${maskedName}`
  }, [flag])

  const handleSearchData = useCallback((data: SearchDetailSchema) => {
    const params = new URLSearchParams()

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          params.append(key, value.join(','))
        }
      } else if (value !== undefined && value !== null && value !== '') {
        params.append(key, value.toString())
      }
    })

    navigate(`?${params.toString()}`)

    setSearchValues(data)
    setPage(1)
    setSearchParam(() => ({
      page: 1,
      limit: PageSize,
      ...(data.status !== undefined && data.status !== '' && { status: Number(data.status) }),
      ...(data.age !== undefined && data.age !== '' && { age: Number(data.age) }),
      ...(data.skeletalElements?.length !== 0 && { skeletal: data.skeletalElements }),
      ...(data.facialFeatures?.length !== 0 && { faceType: data.facialFeatures}),
      ...(data.occlusionRight?.length !== 0 && { angleRight: data.occlusionRight}),
      ...(data.occlusionLeft?.length !== 0 && { angleLeft: data.occlusionLeft}),
      ...(data.otherFactors?.length !== 0 && { otherElement: data.otherFactors}),
    }))

    // 検索条件が変わってrefetchするように変更
    resetQuery(['patientList'])
  }, [navigate, resetQuery])

  const handleReset = useCallback(() => {
    navigate(location.pathname)
  }, [navigate, location.pathname])

  const memoizedResetQuery = useRef(() => {
    resetQuery(['patientList'])
  })

  useEffect(() => {
     const newSearchParam = {
      page: parseInt(searchParams.get('page') || '1', 10),
      limit: PageSize,
      status: searchParams.get('status') !== '' && searchParams.get('status') !== null ? Number(searchParams.get('status')) : undefined,
      age: searchParams.get('age') !== '' && searchParams.get('age') !== null ? Number(searchParams.get('age')) : undefined,
      skeletal: parseArrayParam(searchParams.get('skeletalElements')),
      faceType: parseArrayParam(searchParams.get('facialFeatures')),
      angleRight: parseArrayParam(searchParams.get('occlusionRight')),
      angleLeft: parseArrayParam(searchParams.get('occlusionLeft')),
      otherElement: parseArrayParam(searchParams.get('otherFactors')),
    }

    // 現在のsearchParamとnewSearchParamを比較して、違いがある場合のみリフェッチ
    if (JSON.stringify(searchParam) !== JSON.stringify(newSearchParam)) {
      setSearchParam(newSearchParam)
      setPage(parseInt(searchParams.get('page') || '1', 10))
      memoizedResetQuery.current()
    }

    if (isPatientError || isPatientRefetchError || isIndexError) {
      console.error(isPatientError)
      console.error(isPatientRefetchError)
      console.error(isIndexError)
      return
    }

    if(!isPatientPending && !isPatientRefetchPending && patientData !== undefined){
      setItems(obfuscateNames(patientData.patient))
      setPageAmount(patientData.pageAmount)
    }

    if(!isIndexPending && indexData !== undefined){
      setIndexes(indexData.patients)
    }

    const params = new URLSearchParams(location.search)
    const sp: Partial<SearchDetailSchema> = {
      status: params.get('status') || undefined,
      age: params.get('age') || undefined,
      skeletalElements: params.get('skeletalElements') ? params.get('skeletalElements')!.split(',').map(Number) : [],
      occlusionRight: params.get('occlusionRight') ? params.get('occlusionRight')!.split(',').map(Number) : [],
      occlusionLeft: params.get('occlusionLeft') ? params.get('occlusionLeft')!.split(',').map(Number) : [],
      facialFeatures: params.get('facialFeatures') ? params.get('facialFeatures')!.split(',').map(Number) : [],
      otherFactors: params.get('otherFactors') ? params.get('otherFactors')!.split(',').map(Number) : [],
    }

    setSearchValues(sp)
  }, [
    isPatientError,
    patientData,
    isPatientPending,
    isPatientRefetchError,
    isPatientRefetchPending,
    indexData,
    isIndexError,
    isIndexPending,
    obfuscateNames,
    location.search,
    searchParams,
    searchParam,
  ])

  if (isIndexPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (page >= value || !isPatientPlaceholderData) {
      setPage(value)

      // 現在の検索パラメータを取得
      const params = new URLSearchParams(searchParams)

      // ページ番号が1のときはpageパラメータを削除、それ以外の場合は更新
      if (value === 1) {
        params.delete('page')
      } else {
        params.set('page', value.toString())
      }

      // すべてのパラメータを一括で再設定
      ['status', 'age', 'skeletalElements', 'facialFeatures', 'occlusionRight', 'occlusionLeft', 'otherFactors'].forEach(param => {
        const paramValue = searchParams.get(param)
        if (paramValue !== null) {
          params.set(param, paramValue)
        }
      })

      // URLパラメータを更新
      setSearchParams(params)

      setSearchParam({
        page: value,
        limit: PageSize,
        ...(searchParams.get('status') !== '' && searchParams.get('status') !== null && { status: Number(searchParams.get('status')) }),
        ...(searchParams.get('age') !== '' && searchParams.get('age') !== null && { age: Number(searchParams.get('age')) }),
        ...(searchParams.get('skeletalElements') !== undefined && { skeletal: parseArrayParam(searchParams.get('skeletalElements')) }),
        ...(searchParams.get('facialFeatures') !== undefined && { faceType: parseArrayParam(searchParams.get('facialFeatures')) }),
        ...(searchParams.get('occlusionRight') !== undefined && { angleRight: parseArrayParam(searchParams.get('occlusionRight')) }),
        ...(searchParams.get('occlusionLeft') !== undefined && { angleLeft: parseArrayParam(searchParams.get('occlusionLeft')) }),
        ...(searchParams.get('otherFactors') !== undefined && { otherElement: parseArrayParam(searchParams.get('otherFactors')) }),
      })
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1  }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SearchIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>検索</Typography>
            </Box>
            <Stack sx={{ pt: 2 }}>
              <FormControl>
                <FormLabel sx={{ fontWeight: "600", color: theme.palette.text.primary, fontSize: "14px" }}>患者番号もしくは患者名</FormLabel>
                <Autocomplete
                  freeSolo
                  disableCloseOnSelect
                  disableClearable
                  options={indexes}
                  onChange={handleSelectUser}
                  getOptionLabel={(option) => typeof option === 'string' ? option : `${option.patientNo} ${option.name} ${option.kana}`}
                  renderInput={(params) => (
                    <TextField
                      sx={{ background: "white", borderRadius: "8px", mt: 1 }}
                      {...params}
                      label="入力してください"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <Typography>{getMaskedLabel(option)}</Typography>
                    </Box>
                  )}
                />
              </FormControl>
            </Stack>
            <Typography sx={{ color: 'gray', fontSize: "12px", mt: 0.5 }}>検索候補が出ない場合は画面の更新をしてください。</Typography>
          </Box>
          <SearchDetailPatient onSearch={handleSearchData} defaultValues={searchValues} onReset={handleReset}/>
          <Box sx={{ background: "white", borderRadius: "16px", pt: 3, px: 3, pb: 0.5, mt: 1, mb: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <PersonIcon sx={{ color: theme.palette.text.primary }}/>
                </Box>
                <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>患者一覧</Typography>
                <IconButton sx={{ p: 0, "&:hover": { backgroundColor: "transparent" }, border: "1px solid #A8A8A8", width: "40px", height: "40px" }} onClick={toggleFlag}>
                  {flag ? <VisibilityOffIcon sx={{ color: "#A8A8A8", minWidth: "40px" }} /> : <VisibilityIcon sx={{ color: "#A8A8A8", minWidth: "40px" }} />}
                </IconButton>
              </Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <Pagination
                  count={pageAmount} variant="outlined" size="large" color="primary"
                  onChange={handlePageChange} page={page}
                />
              </Stack>
            </Stack>
            {
              isPatientPending || isPatientRefetchPending ?
              <Stack justifyContent="center" alignItems="center" width={1 / 1} mt={2} mb={4}>
                <CircularProgress />
              </Stack>
              :
              <List sx={{ width: "100%",border: "1px solid #D9D9D9", p: 0, my: 3 }}>
                {items.map((item: Patient, index) => {
                  return (
                    <PatientListItem patientItemData={item} isSecret={flag} page={page} key={index}/>
                  )}
                )}
              </List>
            }
            <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2} mb={2}>
              <Pagination
                count={pageAmount} variant="outlined" size="large" color="primary"
                onChange={handlePageChange} page={page}
              />
            </Stack>
          </Box>
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default PatientList

