import { useMutation, useQuery } from '@tanstack/react-query'
import { exportKeys } from './queryKey'
import { getExportList, postExport, patchExport, getExportStatus } from './request'

export const useGetExportList = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: exportKeys.exportList,
    queryFn: () => getExportList(),
    gcTime: 0,
  })

  return { data, isPending, isError }
}

export const useGetExportStatus = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: exportKeys.exportStatus,
    queryFn: () => getExportStatus(),
    gcTime: 0,
  })

  return { data, isPending, isError }
}

export const usePostExport = () => {
  return useMutation({
    mutationFn: postExport,
    onError: (e) => console.error(e),
  })
}

export const usePatchExport = () => {
  return useMutation({
    mutationFn: patchExport,
    onError: (e) => console.error(e),
  })
}
