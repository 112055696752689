export const reportKeys = {
  dashboardCard: ['dashboardCard'] as const,
  slackingCount: ['slackingCount'] as const,
  patientStatus: ['patientStatus'] as const,
  patientStatusDetail: ['patientStatusDetail'] as const,
  patientStatusGraph: ['patientStatusGraph'] as const,
  patientStatusTable: ['patientStatusTable'] as const,
  capacity: ['capacity'] as const,
  capacityDetail: ['capacityDetail'] as const,
} as const
