import React, { useState } from 'react'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import Modal from '@mui/material/Modal'
import type { Photo } from 'api/photo/responseType'
import ImageRender from './ImageRender'
import { ArrowBack, ArrowForward } from '@mui/icons-material'

export type GalleryModalState = {}

export type GalleryModalProps = {
  onClose: (DiffPhotoModalState: {}) => void
  images: Photo[] | null
  index: number
}

const ImageBoxStyle = {
  width: '880px', height: '660px', fontWeight: '600',
  display: 'flex', justifyContent: 'center', alignItems: 'center',
  overflow: 'hidden', backgroundColor: 'inherit'
}

export const UnassignedPhotoGallery = (props: any) => {
  const { images, index } = props
  const [ viewImage, setViewImage ] = useState<Photo>(images[index])
  const [ viewIndex, setViewIndex ] = useState<number>(index)

  const handleViewImageChange = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, next: boolean) => {
    const changedIndex = next ? viewIndex + 1 : viewIndex - 1
    const calcedIndex = changedIndex < 0 ? images.length - 1 : changedIndex >= images.length ? 0 : changedIndex
    setViewIndex(calcedIndex)
    setViewImage(images[calcedIndex])
  }

  return (
    <>
      <Modal
        open onClose={() => props.onClose()}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#333333',
          boxShadow: 24,
          px: 0,
          py: 2
        }}>
          <Stack direction="row" justifyContent="center">
            <Button onClick={(event) => handleViewImageChange(event, false)}>
              <ArrowBack fontSize="large"/>
            </Button>
            <Box {...ImageBoxStyle} sx={{objectFit: 'cover', border: "4px solid #22BDB4"}}>
              {
                viewImage ?
                <ImageRender image={viewImage} frameWidth={1020} frameHeight={680} />:
                <CircularProgress />
              }
            </Box>
            <Button onClick={(event) => handleViewImageChange(event, true)}>
              <ArrowForward fontSize="large"/>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
export default UnassignedPhotoGallery
