import { QueryKey, useQueryClient } from '@tanstack/react-query'

const useResetQuery = () => {
  const queryClient = useQueryClient()

  const resetQuery = (queryKey: QueryKey, delay?: number) => {
    setTimeout(() => {
      queryClient.resetQueries({ queryKey })
    }, delay ?? 100)
  }

  return resetQuery
}

export default useResetQuery
