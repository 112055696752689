import React, { useEffect, useState } from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import BallotIcon from '@mui/icons-material/Ballot'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import AssessmentIcon from '@mui/icons-material/Assessment'
import CollectionsIcon from '@mui/icons-material/Collections'
import SettingsIcon from '@mui/icons-material/Settings'
import DownloadIcon from '@mui/icons-material/Download'
import EmailIcon from '@mui/icons-material/Email'
import { NavLink, useParams } from "react-router-dom"
import env from 'env'
import { useGetClinicMyself } from 'api/clinic'
import { Clinic } from 'api/clinic/responseType'

const SideMenu = () => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  const [ clinic, setClinic ] = useState<Clinic | null>(null)
  const {
    data: clinicData, isError: isClinicError, isPending: isClinicPending
  } = useGetClinicMyself()

  useEffect(() => {
    if (isClinicError) {
      console.error(isClinicError)
      return
    }
    if(!isClinicPending && clinicData !== undefined){
      setClinic(clinicData.clinic)
    }
  }, [
    clinicData, isClinicError, isClinicPending, 
  ])

  return (
    <>
      <List component="nav" sx={{ pt: "32px" }}>
      <ListItemButton component={NavLink} to={`/${clinicId}/patient`} sx={{ py: "16px", borderTop: "1px solid #cfcfcf", "&:hover": { background: "#2b7f7a" }, '&.active': { background: "#22BDB4" } }} >
        <ListItemIcon sx={{ color: "white", minWidth: "24px" }}>
          <BallotIcon  sx={{ fontSize: "20px" }}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontWeight: "bold", fontSize: "14px", pl: 1 }} primary="患者一覧" />
      </ListItemButton>
      <ListItemButton component={NavLink} to={`/${clinicId}/dashboard`} sx={{ py: "16px", borderTop: "1px solid #cfcfcf", "&:hover": { background: "#2b7f7a" }, '&.active': { background: "#22BDB4" } }} >
        <ListItemIcon sx={{ color: "white", minWidth: "24px" }}>
          <DashboardIcon  sx={{ fontSize: "20px" }}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontWeight: "bold", fontSize: "14px", pl: 1 }} primary="ダッシュボード" />
      </ListItemButton>
      <ListItemButton component={NavLink} to={`/${clinicId}/add-patient`}  sx={{ py: "16px", borderTop: "1px solid #cfcfcf", "&:hover": { background: "#2b7f7a" }, '&.active': { background: "#22BDB4" } }} >
        <ListItemIcon sx={{ color: "white", minWidth: "24px" }}>
          <PersonAddIcon  sx={{ fontSize: "20px" }}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontWeight: "bold", fontSize: "14px", pl: 1 }} primary="新しい患者を追加" />
      </ListItemButton>
      <ListItemButton component={NavLink} to={`/${clinicId}/report`}  sx={{ py: "16px", borderTop: "1px solid #cfcfcf", "&:hover": { background: "#2b7f7a" }, '&.active': { background: "#22BDB4" } }} >
        <ListItemIcon sx={{ color: "white", minWidth: "24px" }}>
          <AssessmentIcon  sx={{ fontSize: "20px" }}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontWeight: "bold", fontSize: "14px", pl: 1 }} primary="レポート" />
      </ListItemButton>
      {
        clinic?.isMigrated ? (
          <ListItemButton component={NavLink} to={`/${clinicId}/unassigned`}  sx={{ py: "16px", borderTop: "1px solid #cfcfcf", "&:hover": { background: "#2b7f7a" }, '&.active': { background: "#22BDB4" } }} >
            <ListItemIcon sx={{ color: "white", minWidth: "24px" }}>
              <CollectionsIcon  sx={{ fontSize: "20px" }}/>
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontWeight: "bold", fontSize: "14px", pl: 1 }} primary="患者未割当写真" />
          </ListItemButton>
        ) : (
          <></>
        )
      }
      <ListItemButton component={NavLink} to={`/${clinicId}/export`} sx={{ py: "16px", borderTop: "1px solid #cfcfcf","&:hover": { background: "#2b7f7a" }, '&.active': { background: "#22BDB4" } }} >
        <ListItemIcon sx={{ color: "white", minWidth: "24px" }}>
          <DownloadIcon  sx={{ fontSize: "20px" }}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontWeight: "bold", fontSize: "14px", pl: 1 }} primary="エクスポート一覧" />
      </ListItemButton>
      <ListItemButton component={NavLink} to={`/${clinicId}/setting`} sx={{ py: "16px", borderTop: "1px solid #cfcfcf","&:hover": { background: "#2b7f7a" }, '&.active': { background: "#22BDB4" } }} >
        <ListItemIcon sx={{ color: "white", minWidth: "24px" }}>
          <SettingsIcon  sx={{ fontSize: "20px" }}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontWeight: "bold", fontSize: "14px", pl: 1 }} primary="設定" />
      </ListItemButton>
      <ListItemButton component={NavLink} to={env.APP_EXTERNAL_URL_CONTACT} target="_blank" rel="noopener noreferrer" sx={{ py: "16px", borderTop: "1px solid #cfcfcf", borderBottom: "1px solid #cfcfcf","&:hover": { background: "#2b7f7a" }, '&.active': { background: "#22BDB4" } }} >
        <ListItemIcon sx={{ color: "white", minWidth: "24px" }}>
          <EmailIcon  sx={{ fontSize: "20px" }}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontWeight: "bold", fontSize: "14px", pl: 1 }} primary="お問い合わせ" />
      </ListItemButton>      
  </List>
    </>
  )
}
export default SideMenu
