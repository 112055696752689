import { client } from 'lib/AxiosClientProvider'
import { DeleteTempPhoto, SaveTempPhoto, UploadTempPhoto } from './requestType'
import { DeleteTempPhotoResponse, SaveTempPhotoResponse } from './responseType'

export const saveTmpPhoto = async ({
  id,
  req
}: {
  id: number,
  req: SaveTempPhoto
}): Promise<SaveTempPhotoResponse> => {
  const response = await client.post(`/tmp/photo/${id}`, req)

  return response.data
}

export const uploadTmpPhoto = async ({
  url,
  req
}: {
  url: string,
  req: UploadTempPhoto
}): Promise<SaveTempPhotoResponse> => {
  const response = await client.put(
    url,
    req.file,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
  )

  return response.data
}

export const deleteTmpPhoto = async ({
  patientId,
  req,
}: {
  patientId: number,
  req: DeleteTempPhoto
}): Promise<DeleteTempPhotoResponse> => {
  const response = await client.delete(
    `/tmp/photo/${patientId}`,
    { data: req },
  )

  return response.data
}
