import { useMutation, useQuery } from '@tanstack/react-query'
import { examinationKeys } from './queryKey'
import { deleteExamination, getAllPhoto, getAllPhotoByExam, getExnamiationList, getPhotoListByExam, getPhotoListByTimelapse, getUnassignedPhoto, patchPhoto, postPhoto, reassignPhoto, swapPhoto } from './request'

export const useGetExaminationList = (patientId: number) => {
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: examinationKeys.photoList(patientId),
    queryFn: () => getExnamiationList(patientId),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const useGetPhotoListByExamResponse = (patientId: number, examinationId: number) => {
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: examinationKeys.listByExam(patientId, examinationId),
    queryFn: () => getPhotoListByExam(patientId, examinationId),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const useGetPhotoListByTimelapseResponse = (patientId: number, angle: number) => {
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: examinationKeys.listByTimelapse(patientId, angle),
    queryFn: () => getPhotoListByTimelapse(patientId, angle),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const useGetAllPhoto = (patientId: number) => {
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: examinationKeys.allPhoto(patientId),
    queryFn: () => getAllPhoto(patientId),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const useGetAllPhotoByExam = (patientId: number, examinationId: number) => {
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: examinationKeys.allPhotoByExam(examinationId),
    queryFn: () => getAllPhotoByExam(patientId, examinationId),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const useGetUnassignedPhoto = () => {
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: examinationKeys.unassigned,
    queryFn: () => getUnassignedPhoto(),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const usePostPhoto = () => {
  return useMutation({
    mutationFn: postPhoto,
    onError: (e) => console.error(e),
  })
}

export const usePatchPhoto = () => {
  return useMutation({
    mutationFn: patchPhoto,
    onError: (e) => console.error(e),
  })
}

export const useReassignPhoto = () => {
  return useMutation({
    mutationFn: reassignPhoto,
    onError: (e) => console.error(e),
  })
}

export const useSwapPhoto = () => {
  return useMutation({
    mutationFn: swapPhoto,
    onError: (e) => console.error(e),
  })
}

export const useDeleteExamination= () => {
  return useMutation({
    mutationFn: deleteExamination,
    onError: (e) => console.error(e),
  })
}
