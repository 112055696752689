import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { PhotoObject } from 'types/PhotoObject'
import { getImageUrl } from 'lib/getImagePath'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface SwitchPhotoProps {
  image: PhotoObject
  frameWidth: number
  frameHeight: number
  isNotLazy?: boolean
}

function ceil1000(number: number) {
  return number > 0 ? Math.ceil(number * 1000) / 1000 : Math.floor(number * 1000) / 1000
}

export const ImageRender = (props: SwitchPhotoProps) => {
  const { image, frameWidth, frameHeight, isNotLazy } = props
  const imageRef = useRef<HTMLDivElement>(null)
  const [beacon, setBeacon] = useState(false)
  const [loading, setLoading] = useState(true)
  const lazyFlg = !isNotLazy

  useLayoutEffect(() => {
    setTimeout(() => {
      if (imageRef.current && !image.initial) {
        // 要素定義
        const parentNode = imageRef.current
        const imageNode = imageRef.current.firstElementChild

        if (imageNode instanceof HTMLImageElement) {
          // 各種affine値抽出
          const flop = image.affine.flop ? -1 : 1
          const flip = image.affine.flip ? -1 : 1
          const rotate = image.affine.rotate
          const scale = image.affine.scale
          const translateX = image.affine.translateX
          const translateY = image.affine.translateY
          // 画像の横縦を抽出
          const naturalWidth = imageNode.naturalWidth
          const naturalHeight = imageNode.naturalHeight
          // 表示枠に即した画像の縮小率を算出
          const reduction = Math.min(frameWidth / naturalWidth, frameHeight / naturalHeight) * scale
          // 各種属性値の設定
          const transformRotate = `rotate(${rotate}deg)`
          const transformScale = `scale(${ceil1000(reduction * flop)}, ${ceil1000(reduction * flip)})`
          imageNode.style.transform = `${transformRotate} ${transformScale}`
          imageNode.style.width = `${naturalWidth}px`
          imageNode.style.height = `${naturalHeight}px`

          // 移動枠に即した移動値の算出
          const positionX = translateX * reduction
          const positionY = translateY * reduction
          // 各種属性値の設定
          const transformTranslate = `translate(${positionX}px, ${positionY}px)`
          parentNode.style.transform = `${transformTranslate}`
          parentNode.style.width = `${frameWidth}px`
          parentNode.style.height = `${frameHeight}px`
        }
      }
    }, 10)
  }, [image, frameWidth, frameHeight, imageRef, beacon])

  const imageRefresh = () => {
    setBeacon(!beacon)
    setLoading(false)
  }

  const getImagePath = useCallback((image: PhotoObject) => {
    return getImageUrl(image.path)
  }, [])

  return image ? (
    <Box className="fitimage imageframe" ref={imageRef}>
      {loading && <CircularProgress />}
      {lazyFlg ? (
        <LazyLoadImage
          src={getImagePath(image)}
          alt={`${image.angle}`}
          className="fitimage"
          onLoad={() => imageRefresh()}
          delayTime={300}
        />
      ) : (
        <img
          src={getImagePath(image)}
          alt={`${image.angle}`}
          className="fitimage"
          onLoad={() => imageRefresh()}
        />
      )}
    </Box>
  ) : (
    <></>
  )
}

export default ImageRender
