import { useRef } from "react"

type LongPressSet = {
  onMouseDown: () => void
  onMouseUp: () => void
  onMouseLeave: () => void
  onTouchStart: () => void
  onTouchEnd: () => void
}

export const LongPress = (
  callback: () => void,
  isSequel: boolean,
  intervalMs: number,
  delayMs: number
): LongPressSet => {
  let timeout: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef()
  let interval: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef()

  const start = () => {
    if (isSequel) {
      callback()
      timeout.current = setTimeout(() => {
        interval.current = setInterval(callback, intervalMs)
      }, delayMs)
    } else {
      timeout.current = setTimeout(callback, delayMs)
    }
  }

  const stop = () => {
    timeout.current && clearTimeout(timeout.current)
    interval.current && clearInterval(interval.current)
    timeout.current = undefined
    interval.current = undefined
  }

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  }
}