import React from 'react'
import { Typography, Box } from '@mui/material'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'

const CircularProgressWithLabel = (props: { progress: number, page: number }) => {
  const {progress, page} = props
  const cpProps: CircularProgressProps & {value: number} = {value: progress}
  return (
    <Box>
      <CircularProgress variant="determinate"
        size = "4" 
        sx={{
          width: "64px",
          height: "64px",
        }} 
        {...cpProps}
      />   
        <Typography
          sx={{
            top: 0,
            left: "18px",
            bottom: "4px",
            fontWeight: "600",
            fontSize: "24px",
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}        
        >
          {page}
        </Typography>
        <Typography
          sx={{
            top: 0,
            left: "32px",
            fontSize: "16px",
            bottom: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}        
        >/5</Typography>
    </Box>
  )
}

export default CircularProgressWithLabel
