import { useCallback, useEffect, useState } from 'react'
import { Box, Button, Typography, Stack, FormControl, FormLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import useForm from 'hooks/useForm'
import SearchDetailSchema, { SearchDetailSchema as SearchDetailSchemaType } from 'schema/search/searchDetailSchema'
import { Controller, SubmitHandler } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { ArrowDropUp } from '@mui/icons-material'
import { angleLeftImages as angleLeft, angleRightImages as angleRight, faceTypeImages as faceType, otherElementImages as otherElement, patientAge, patientStatus, skeletalImages as skeletal } from 'types/PatientObjects'

interface SearchDetailPatientProps {
  onSearch: (data: SearchDetailSchemaType) => void
  defaultValues: Partial<SearchDetailSchemaType>
  onReset: () => void
}

const SearchDetailPatient: React.FC<SearchDetailPatientProps> = ({ onSearch, defaultValues, onReset }) => {
  const [visible, setVisible] = useState(false)
  const theme = useTheme()

  const { control, handleSubmit, reset } = useForm(SearchDetailSchema, {
    mode: 'all',
    defaultValues: {
      status: defaultValues.status !== undefined && defaultValues.status !== null ? defaultValues.status.toString() : '',
      age: defaultValues.age !== undefined && defaultValues.age !== null ? defaultValues.age.toString() : '',
      skeletalElements: defaultValues.skeletalElements || [],
      occlusionRight: defaultValues.occlusionRight || [],
      occlusionLeft: defaultValues.occlusionLeft || [],
      facialFeatures: defaultValues.facialFeatures || [],
      otherFactors: defaultValues.otherFactors || [],
    },
  })

  const onSubmit: SubmitHandler<SearchDetailSchemaType> = (data) => {
    onSearch(data)
  }

  const handleResetClick = () => {
    reset()
    onReset()
  }

  const handleCheckboxChange = useCallback(
    (field: any, value: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        field.onChange([...field.value, value])
      } else {
        field.onChange(field.value.filter((val: number) => val !== value))
      }
    },
    []
  )

  // URLパラメータが変わったときにフォームをリセットするためのuseEffect
  useEffect(() => {
    reset({
      status: defaultValues.status !== undefined && defaultValues.status !== null ? defaultValues.status.toString() : '',
      age: defaultValues.age !== undefined && defaultValues.age !== null ? defaultValues.age.toString() : '',
      skeletalElements: defaultValues.skeletalElements || [],
      occlusionRight: defaultValues.occlusionRight || [],
      occlusionLeft: defaultValues.occlusionLeft || [],
      facialFeatures: defaultValues.facialFeatures || [],
      otherFactors: defaultValues.otherFactors || [],
    })
  }, [defaultValues, reset])

  return (
    <>
      <Box sx={{ background: 'white', borderRadius: '16px', p: 3, mt: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
            <Box sx={{ width: '40px', height: '40px', borderRadius: '50%' , background: theme.palette.secondary.main, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <SearchIcon sx={{ color: theme.palette.text.primary }}/>
            </Box>
            <Typography sx={{ fontWeight: '600', fontSize: '18px', color: theme.palette.text.primary }}>詳細検索</Typography>
          </Box>
          <Button onClick={() => setVisible(!visible)} variant='text'>
            <Typography sx={{ color: theme.palette.primary.main, fontWeight: '600', fontSize: '16px' }}>
              {visible ? '閉じる' : '詳細検索する'}
            </Typography>
            {visible ? <ArrowDropUp sx={{ fontSize: '32px' }} /> : <ArrowDropDownIcon sx={{ fontSize: '32px' }} />}
          </Button>
        </Box>
        {visible && (
          <Stack sx={{ mt: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ display: 'flex', columnGap: '24px', mb: 3 }}>
                <Stack sx={{ width: '50%' }}>
                  <FormControl fullWidth>
                    <FormLabel sx={{ fontWeight: '600', color: theme.palette.text.primary, fontSize: '14px', mb: 1 }}>ステータス</FormLabel>
                    <Controller
                      name='status'
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          displayEmpty
                          sx={{ background: 'white', borderRadius: '8px' }}
                          value={field.value ?? ''}
                        >
                          <MenuItem value='' disabled>
                            全て
                          </MenuItem>
                          {Object.entries(patientStatus).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack sx={{ width: '50%' }}>
                  <FormControl fullWidth>
                    <FormLabel sx={{ fontWeight: '600', color: theme.palette.text.primary, fontSize: '14px', mb: 1 }}>年齢</FormLabel>
                    <Controller
                      name='age'
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          displayEmpty
                          sx={{ background: 'white', borderRadius: '8px' }}
                          value={field.value ?? ''}
                        >
                          <MenuItem value='' disabled>
                            全て
                          </MenuItem>
                          {Object.entries(patientAge).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Box>
              <Typography sx={{ fontWeight: '600', color: theme.palette.text.primary, fontSize: '14px', mb: 2 }}>状態</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', mb: 3 }}>
                <Box sx={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#808080', mb: 1 }}>骨格的要素</Typography>
                  <Controller
                    name='skeletalElements'
                    control={control}
                    render={({ field }) => (
                      <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        {skeletal.filter(skeletal => skeletal.key !== 99).map((skeletal) => (
                          <FormControlLabel
                            key={skeletal.key}
                            control={
                              <Checkbox
                                checked={field.value?.includes(skeletal.key)}
                                onChange={handleCheckboxChange(field, skeletal.key)}
                                value={skeletal.key}
                              />
                            }
                            label={skeletal.title}
                          />
                        ))}
                      </FormGroup>
                    )}
                  />
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#808080', mb: 1 }}>咬合関係（右）</Typography>
                  <Controller
                    name='occlusionRight'
                    control={control}
                    render={({ field }) => (
                      <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        {angleRight.filter(angleRight => angleRight.key !== 99).map((angleRight) => (
                          <FormControlLabel
                            key={angleRight.key}
                            control={
                              <Checkbox
                                checked={field.value?.includes(angleRight.key)}
                                onChange={handleCheckboxChange(field, angleRight.key)}
                                value={angleRight.key}
                              />
                            }
                            label={angleRight.title}
                          />
                        ))}
                      </FormGroup>
                    )}
                  />
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#808080', mb: 1 }}>顔貌</Typography>
                  <Controller
                    name='facialFeatures'
                    control={control}
                    render={({ field }) => (
                      <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        {faceType.filter(faceType => faceType.key !== 99).map((face) => (
                          <FormControlLabel
                            key={face.key}
                            control={
                              <Checkbox
                                checked={field.value?.includes(face.key)}
                                onChange={handleCheckboxChange(field, face.key)}
                                value={face.key}
                              />
                            }
                            label={face.title}
                          />
                        ))}
                      </FormGroup>
                    )}
                  />
                </Box>

                <Box sx={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#808080', mb: 1 }}>咬合関係（左）</Typography>
                  <Controller
                    name='occlusionLeft'
                    control={control}
                    render={({ field }) => (
                      <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        {angleLeft.filter(angleLeft => angleLeft.key !== 99).map((angleLeft) => (
                          <FormControlLabel
                            key={angleLeft.key}
                            control={
                              <Checkbox
                                checked={field.value?.includes(angleLeft.key)}
                                onChange={handleCheckboxChange(field, angleLeft.key)}
                                value={angleLeft.key}
                              />
                            }
                            label={angleLeft.title}
                          />
                        ))}
                      </FormGroup>
                    )}
                  />
                </Box>

                <Box>
                  <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#808080', mb: 1 }}>その他要素</Typography>
                  <Controller
                    name='otherFactors'
                    control={control}
                    render={({ field }) => (
                      <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                        {otherElement.map((otherElement) => (
                          <FormControlLabel
                            key={otherElement.key}
                            control={
                              <Checkbox
                                checked={field.value?.includes(otherElement.key)}
                                onChange={handleCheckboxChange(field, otherElement.key)}
                                value={otherElement.key}
                              />
                            }
                            label={otherElement.title}
                          />
                        ))}
                      </FormGroup>
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center'}} >
                <Button
                  size='medium'
                  variant='outlined'
                  sx={{ textAlign: 'center', width: '260px', fontSize: '14px', borderRadius: '40px', px: 1, mr: 1, fontWeight: 'bold', '&:hover': { background: '#11867F', color: 'white' } }}
                  onClick={handleResetClick}
                >
                    検索フィルターをクリアする
                </Button>
                <Button
                  type='submit'
                  size='medium'
                  variant='outlined'
                  sx={{ py: 1, width: '260px', color: 'white', background: theme.palette.primary.main, textAlign: 'center', fontSize: '14px', borderRadius: '40px', px: 8, fontWeight: 'bold', '&:hover': { color: theme.palette.primary.main } }}>
                  検索する
                </Button>
              </Box>
            </form>
          </Stack>
        )}
      </Box>
    </>
  )
}

export default SearchDetailPatient

