import React,{useState, useEffect} from 'react';
import { Typography, Box, Link, Button } from '@mui/material';
import Layout from '../../components/Layout';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../style/theme';
import SettingsIcon from '@mui/icons-material/Settings';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import HelpIcon from '@mui/icons-material/Help';
import { Link as RouterLink, useParams } from 'react-router-dom';
import type { Clinic }  from '../../api/clinic/responseType';
import { useGetClinicMyself } from '../../api/clinic';
import OperationProof from 'components/OperationProof';
import env from 'env'

const Setting = () => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  const [ clinic, setClinic ] = useState<Clinic>();
  const { data: clinicData, isError: isClinicnError, isPending: isClinicPending } = useGetClinicMyself();

  useEffect(() => {
    if (isClinicnError) {
      console.error(isClinicnError)
      return
    }
    if(!isClinicPending  && clinicData !== undefined){
      setClinic(clinicData.clinic);
    }
  }, [isClinicnError, clinicData, isClinicPending])

  if (isClinicPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SettingsIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>アカウント情報</Typography>
            </Box>
            <Table sx={{ width: 680, mt: "40px", mx: "auto", mb:0 }} aria-label="simple table">
              <TableBody>
                  <TableRow sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)"}} >
                    <TableCell sx={{ background: "#F4F4F4", fontWeight: "bold" }} align="center">ID</TableCell>
                    <TableCell align="left">{clinic?.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ background: "#F4F4F4", fontWeight: "bold" }} align="center">クリニック名</TableCell>
                    <TableCell align="left">{clinic?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ background: "#F4F4F4", fontWeight: "bold" }} align="center">メールアドレス</TableCell>
                    <TableCell align="left">{clinic?.email}</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px", mb: 2 }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SettingsIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>各種設定</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Button variant="contained" disableElevation component={RouterLink} to={`/${clinicId}/setting/flip`} sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                <Typography sx={{ fontWeight: "bold" }}>反転の設定</Typography>
              </Button>
              <Button variant="contained" disableElevation component={RouterLink} to={`/${clinicId}/setting/angle-name`} sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                <Typography sx={{ fontWeight: "bold" }}>ラベルの設定</Typography>
              </Button>
              <Button variant="contained" disableElevation component={RouterLink} to={`/${clinicId}/setting/export`} sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                <Typography sx={{ fontWeight: "bold" }}>エクスポートの設定</Typography>
              </Button>
            </Box>
          </Box>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Button variant="outlined" component={RouterLink} to={env.APP_EXTERNAL_URL_CONTACT} target="_blank" rel="noopener noreferrer" sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <ChangeCircleIcon sx={{ width: "32px", height: "32px" }}/>
                <Typography sx={{ fontWeight: "bold" }}>支払い変更</Typography>
              </Button>
              <Button variant="outlined" component={RouterLink} to="news" sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <CircleNotificationsIcon sx={{ width: "32px", height: "32px" }}/>
                <Typography sx={{ fontWeight: "bold" }}>お知らせ</Typography>
              </Button>
              <Button variant="outlined" component={RouterLink} to={env.APP_EXTERNAL_URL_FAQ}  target="_blank" rel="noopener noreferrer" sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <HelpIcon sx={{ width: "32px", height: "32px" }}/>
                <Typography sx={{ fontWeight: "bold" }}>よくある質問</Typography>
              </Button>
            </Box>
          </Box>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "24px" }}>
              <Link sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: "14px" }} color="inherit" underline="always" href={env.APP_EXTERNAL_URL_CORPORATE} target="_blank" rel="noopener noreferrer">運営について</Link>
              <Link sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: "14px" }} color="inherit" underline="always" component={RouterLink} to={`/${clinicId}/setting/privacy-policy`}>プライバシーポリシー</Link>
              <Link sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: "14px" }} color="inherit" underline="always" component={RouterLink} to={`/${clinicId}/setting/terms-of-service`}>利用規約</Link>
              <Link sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: "14px" }} color="inherit" underline="always" href={env.APP_EXTERNAL_URL_RELEASE} target="_blank" rel="noopener noreferrer">リリース情報</Link>
            </Box>
          </Box>
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default Setting;
