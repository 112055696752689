import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#22BDB4',
      contrastText: '#fff',
      dark: '#1B7D78',
      light: '#4ecac3',
    }, 
    secondary: {
      main: '#C6ECEA', 
      light: '#A0A0A0',     
    },
    text: {
      primary: '#364F4E',
    },     
  },
  typography: {
    h1: {
      fontSize: '48px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '32px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 600,
    },    
    h4: {
        fontSize: '20px',
        fontWeight: 600,
    },
    h5: {
      fontSize: '18px',
      fontWeight: 600,
    }, 
    subtitle2: {
      fontWeight: 600,
    },        
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#474747",
          color: "white"
        }
      }
    }
  }
});

export default responsiveFontSizes(theme);