import { useQuery } from '@tanstack/react-query'
import { informationKeys } from './queryKey'
import { getInformation, getInformationList } from './request'

export const useGetInformationList = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: informationKeys.informationList,
    queryFn: () => getInformationList(),
  })

  return { data, isPending, isError }
}

export const useGetInformation = (id: number) => {
  const { data, isPending, isError } = useQuery({
    queryKey: informationKeys.detail(id),
    queryFn: () => getInformation(id),
  })

  return { data, isPending, isError }
}
