import { client } from 'lib/AxiosClientProvider'
import { GetInformationListResponse, GetInformationResponse } from './responseType'

export const getInformationList = async (): Promise<GetInformationListResponse> => {
  const response = await client.get('/information')

  return response.data
}

export const getInformation = async (id: number): Promise<GetInformationResponse> => {
  const response = await client.get(`/information/${id}`)

  return response.data
}
