import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import Layout from '../../components/Layout';
import PageBackButton from '../../components/PageBackButton';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../style/theme';
import NotificationsIcon from '@mui/icons-material/Notifications';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { useGetInformationList } from '../../api/information';
import SettingNewsListItem from '../setting/SettingNewsListItem';
import type { Information }  from '../../api/information/responseType';
import { useParams } from 'react-router-dom';
import OperationProof from 'components/OperationProof';

export const SettingNewsList = () => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  const [ posts, setPosts ] = useState<Information[]>([]);
  const { data: informationData, isError: isInformationError, isPending: isInformationPending } = useGetInformationList();

  useEffect(() => {
    if (isInformationError) {
      console.error(isInformationError)
      return
    }
    if(!isInformationPending && informationData !== undefined){
      setPosts(informationData.information);
    }  
  }, [isInformationError, informationData, isInformationPending])

  if (isInformationPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={`/${clinicId}/setting`} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1  }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <NotificationsIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>お知らせ</Typography>
            </Box>
            <List sx={{ border: "1px solid #B9B9B9", p: 0, mt: 3 }}>
            {posts.map((post, index) => {
              const divider = posts.length - 1 > index ? (<Divider component="li" sx={{ background: "#B9B9B9"}} /> ) : (<></>)
              return (
                <>
                  <SettingNewsListItem newsItemData={post}/>
                  {divider}
                </>
              )}              
            )}          
            </List>
          </Box>
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default SettingNewsList;
