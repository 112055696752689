import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import type { Examination, Photo } from 'api/photo/responseType'
import ImageRender from './ImageRender'
import { ArrowBack, ArrowForward, ArrowLeft, ArrowRight, Close as CloseIcon } from '@mui/icons-material'
import { useWindowSize } from 'lib/useWindowSize'
import { FullScreen, FullScreenHandle, useFullScreenHandle } from 'react-full-screen'
import { Patient } from 'api/patient/responseType'

export type CarouselPhotoModalState = {}

export type CarouselPhotoModalProps = {
  onClose: (CarouselPhotoModalState: {}) => void
  patientData: Patient
  examinations: Examination[]
  carousel: (Photo | undefined)[][]
  initialDateIndex: number
  initialAngleIndex: number
  angleNameGetter: Function
  onlyHorizon?: boolean 
}

export type ViewerTooltipFlags = {
  [key: number]: boolean
}

export const CarouselPhoto = (props : CarouselPhotoModalProps) => {
  const { onClose, patientData, examinations, carousel, initialDateIndex, initialAngleIndex, angleNameGetter, onlyHorizon } = props
  const [ viewExamination, setViewExamination ] = useState<Examination>(examinations[initialDateIndex])
  const [ viewImage, setViewImage ] = useState<Photo | undefined>(carousel[initialDateIndex][initialAngleIndex])
  const [ viewDateIndex, setViewDateIndex ] = useState<number>(initialDateIndex)
  const [ viewAngleIndex, setViewAngleIndex ] = useState<number>(initialAngleIndex)
  const { windowWidth } = useWindowSize()
  const [ frameWidth, setFrameWidth ] = useState(900)
  const [ frameHeight, setFrameHeight ] = useState(675)
  const [ boxWidth, setBoxWidth ] = useState(1300)
  const ref = useRef<HTMLDivElement | null>(null)
  const fsHandle = useFullScreenHandle()
  const [ activeFullscreenMenu, setActiveFullscreenMenu ] = useState(false)

  const getActiveDate = (angleIndex: number) => {
    return carousel.filter(item => {
      return item[angleIndex] !== undefined
    })
  }
  const getActiveAngle = (dateindex: number) => {
    return carousel[dateindex].filter(item => {
      return item !== undefined
    })
  }
  const [ activeDateLength, setActiveDateLength ] = useState<number>(getActiveDate(initialAngleIndex).length)
  const [ activeAngleLength, setActiveAngleLength ] = useState<number>(getActiveAngle(initialDateIndex).length)

  const handleDateChange = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, next: boolean) => {
    const dateLength = carousel.length
    let calcedIndex = next ? viewDateIndex + 1 : viewDateIndex - 1
    while (true) {
      calcedIndex = calcedIndex < 0 ? dateLength - 1 : calcedIndex >= dateLength ? 0 : calcedIndex
      if (carousel[calcedIndex][viewAngleIndex]) {
        break
      } else {
        calcedIndex = next ? calcedIndex + 1 : calcedIndex - 1
      }
    }
    setViewDateIndex(calcedIndex)
    setViewExamination(examinations[calcedIndex])
    setViewImage(carousel[calcedIndex][viewAngleIndex])
    setActiveAngleLength(getActiveAngle(calcedIndex).length)
  }

  const handleAngleChange = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, next: boolean) => {
    const angleLength = carousel[viewDateIndex].length
    let calcedIndex = next ? viewAngleIndex + 1 : viewAngleIndex - 1
    while (true) {
      calcedIndex = calcedIndex < 0 ? angleLength - 1 : calcedIndex >= angleLength ? 0 : calcedIndex
      if (carousel[viewDateIndex][calcedIndex] !== undefined) {
        break
      } else {
        calcedIndex = next ? calcedIndex + 1 : calcedIndex - 1
      }
    }
    setViewAngleIndex(calcedIndex)
    setViewImage(carousel[viewDateIndex][calcedIndex])
    setActiveDateLength(getActiveDate(calcedIndex).length)
  }

  useEffect(() => {
    setBoxWidth(windowWidth >= 1400 ? 1000 : (windowWidth < 1025 ? 500 : 600))
    setFrameWidth(windowWidth >= 1400 ? 900 : (windowWidth < 1025 ? 420 : 520))
    setFrameHeight(windowWidth >= 1400 ? 675 : (windowWidth < 1025 ? 315 : 390))
  }, [windowWidth])

  const ImageBoxStyle = {
    width: frameWidth, height: frameHeight, fontWeight: '600',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    overflow: 'hidden', backgroundColor: 'inherit',
  }

  const handleFullScreen = () => {
    if (!fsHandle.active) {
      fsHandle.enter()
    } else {
      setActiveFullscreenMenu(!activeFullscreenMenu)
    }
  }

  const handleFullScreenClose = () => {
    if (fsHandle.active) {
      fsHandle.exit()
    }
  }

  const reportChange = useCallback((state: boolean, handle: FullScreenHandle) => {
    if (state) {
      const availHeight = window.screen.availHeight - 12
      const availWidth = window.screen.availWidth - 12
      if (availWidth > (availHeight * 4 / 3)) {
        setFrameWidth(availHeight * 4 / 3)
        setFrameHeight(availHeight)
      } else {
        setFrameWidth(availWidth)
        setFrameHeight(availWidth * 3 / 4)
      }
    } else {
      setFrameWidth(windowWidth >= 1400 ? 900 : (windowWidth < 1025 ? 420 : 520))
      setFrameHeight(windowWidth >= 1400 ? 675 : (windowWidth < 1025 ? 315 : 390))
    }
  }, [windowWidth])

  return (
    <>
      <Modal
        open onClose={() => onClose({})}
        ref={ref}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: boxWidth,
          bgcolor: '#333333',
          boxShadow: 24,
          p: 4
        }}>
          <Typography color="#F4F4F4" align="center" fontSize="16px" mt={-1}>
            {angleNameGetter(viewImage?.angle)}
          </Typography>
          <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
            {
              !onlyHorizon &&
              <Button
                onClick={(event) => handleDateChange(event, true)} disabled={activeDateLength === 1}
                title='古い写真'
              >
                <ArrowLeft/>
              </Button>
            }
            <Typography color="#F4F4F4" align="center" fontSize="18px">
              {viewExamination.examinationDate}
            </Typography>
            {
              !onlyHorizon &&
              <Button
                onClick={(event) => handleDateChange(event, false)} disabled={activeDateLength === 1}
                title='新しい写真'
              >
                <ArrowRight/>
              </Button>
            }
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Button onClick={(event) => handleAngleChange(event, false)} disabled={activeAngleLength === 1} title="前のアングル">
              <ArrowBack fontSize="large"/>
            </Button>
            <FullScreen handle={fsHandle} onChange={reportChange}>
              {
                fsHandle.active && activeFullscreenMenu ?
                <Stack
                direction='row' justifyContent='center' alignItems='center' gap={2}
                  position='absolute' top={0} left={0} width={window.screen.availWidth} height={80}
                  sx={{
                    background: 'linear-gradient(rgba(0, 0, 0, 0.75) 75%, rgba(0, 0, 0, 0.0))',
                    zIndex: 9999
                  }}
                >
                  <CloseIcon
                    sx={{
                      position: 'absolute', top: 0, left: 0,
                      fontSize: 40, p: 2, color: 'white', cursor: 'pointer'
                    }}
                    onClick={handleFullScreenClose}
                  />
                  <Typography color='white' fontSize={24}>
                    {patientData?.name} ({patientData?.patientNo})
                  </Typography>
                  <Typography color='white' fontSize={24}>
                    {viewExamination.examinationDate}
                  </Typography>
                  <Typography color='white' fontSize={24}>
                    {angleNameGetter(viewImage?.angle)}
                  </Typography>
                </Stack> :
                <></>
              }
              <Box
                {...ImageBoxStyle} className='fsTarget'
                sx={{objectFit: 'cover', border: "4px solid #22BDB4", cursor: !fsHandle.active ? 'pointer' : 'default'}}
                onClick={handleFullScreen}
              >
                {
                  viewImage ?
                  <ImageRender
                    image={viewImage}
                    frameWidth={frameWidth}
                    frameHeight={frameHeight}
                    isNotLazy={true}
                  />:
                  <CircularProgress />
                }
              </Box>
            </FullScreen>
            <Button onClick={(event) => handleAngleChange(event, true)} disabled={activeAngleLength === 1} title="次のアングル">
              <ArrowForward fontSize="large"/>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
export default CarouselPhoto
