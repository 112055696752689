import { client } from 'lib/AxiosClientProvider'
import { ExportStart, ExportUpdate } from './requestType'
import { GetExportListResponse, PostExportStartResponse, PatchExportResponse, GetExportStatusResponse } from './responseType'

export const getExportList = async (): Promise<GetExportListResponse> => {
  const response = await client.get(`/export`)

  return response.data
}

export const getExportStatus = async (): Promise<GetExportStatusResponse> => {
  const response = await client.get(`/export/status`)

  return response.data
}

export const postExport = async ({
  req
}: {
  req: ExportStart,
}): Promise<PostExportStartResponse> => {
  const response = await client.post(`/export`, req)

  return response.data
}

export const patchExport = async ({
  id,
  req
}: {
  id: number,
  req: ExportUpdate,
}): Promise<PatchExportResponse> => {
  const response = await client.patch(`/export/${id}/update`, req)

  return response.data
}
