import React, { FC, useEffect, useState } from 'react'
import { Box, Typography, Stack, CircularProgress } from '@mui/material'
import ImageRender from 'components/ImageRender'
import { PhotoObject } from 'types/PhotoObject'

interface CheckablePhotoFrameProps {
  image?: PhotoObject
  alterPath?: string
  frameWidth?: number
  frameHeight?: number
  isNotLazy?: boolean
  angleName?: string
  isTextAlter?: boolean
  clickHandler?: Function
  checked: boolean
  disabled?: boolean
}

const CheckablePhotoFrame: FC<CheckablePhotoFrameProps> = (props: CheckablePhotoFrameProps) => {
  const {
    image, alterPath, frameWidth, frameHeight, isNotLazy, angleName, isTextAlter, clickHandler,
    checked, disabled
  } = props
  const [checkedStyle, setCheckedStyle] = useState({
    border: '1px solid #A6A6A6',
    boxShadow: '0px 0px 0px 0px #ffffff'
  })
  const [backgroundColor, setBackgroundColor] = useState<string>('inherit')
  const [disabledStyle, setDisabledStyle] = useState({
    filter: 'grayscale(0%)',
    cursor: 'pointer',
    opacity: 1.0
  })

  const width = frameWidth ?? 152
  const height = frameHeight ?? 114

  const ImageBoxStyle = {
    width: `${width}px`, height: `${height}px`, fontWeight: '600',
    borderRadius: '8px',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    overflow: 'hidden', backgroundColor: 'inherit'
  }
  const ImageBoxStyleClone = structuredClone(ImageBoxStyle)

  if (!image || !image.initial || image.loading) {
    if (!isTextAlter) {
      ImageBoxStyleClone.backgroundColor = '#777777'
    }
  }

  useEffect(() => {
    setCheckedStyle(checked ? {
      border: '1px solid #22BDB4',
      boxShadow: '0px 0px 2px 2px #22BDB4'
    } : {
      border: '1px solid #A6A6A6',
      boxShadow: '0px 0px 0px 0px #ffffff'
    })
    setDisabledStyle(disabled ? {
      filter: 'grayscale(100%)',
      cursor: 'not-allowed',
      opacity: 0.7
    } : {
      filter: 'grayscale(0%)',
      cursor: 'pointer',
      opacity: 1.0
    })
    setBackgroundColor(disabled ? '#A6A6A6' : 'inherit')
    
  }, [checked, disabled])

  return (
    <Stack position="relative"
      onClick={() => {
        if (clickHandler && !disabled) {
          clickHandler()
        }
      }}
      sx={{backgroundColor, borderRadius: '8px'}}
    >
      <Box {...ImageBoxStyleClone} sx={{
        objectFit: 'cover',
        ...checkedStyle,
        ...disabledStyle
        }}
      >
        {
          !image ?
          isTextAlter ?
          <Typography sx={{ textAlign: 'center' }}>{angleName}</Typography> :
          <img src={alterPath} style={{ width: width, height: height }} alt=""/> :
          image.loading ?
          <CircularProgress /> :
          // 画像表示は「表示枠」「移動枠」「画像」の入れ子になっている
          // ここでは「移動枠」と「画像」の設定を行う
          <ImageRender image={image} frameWidth={width} frameHeight={height} isNotLazy={isNotLazy} />
        }
      </Box>
    </Stack>
  )
}
export default CheckablePhotoFrame
