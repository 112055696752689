import { z } from 'zod'

const schema = z.object({
  PUBLIC_URL: z.string().default(process.env.REACT_APP_PUBLIC_URL || ''),
  AUTH_REGION: z.string().default(process.env.REACT_APP_AUTH_REGION || ''),
  AUTH_USER_POOL_ID: z.string().default(process.env.REACT_APP_AUTH_USER_POOL_ID || ''),
  AUTH_USER_POOL_WEB_CLIENT_ID: z.string().default(process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID || ''),
  AUTH_COOKIE_STORAGE_DOMAIN: z.string().default(process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN || ''),
  ENVIRONMENT: z.string().default(process.env.REACT_APP_ENVIRONMENT || ''),
  API_BASE_URL: z.string().default(process.env.REACT_APP_API_BASE_URL || ''),
  APP_EXTERNAL_URL_FAQ: z.string().default(process.env.REACT_APP_EXTERNAL_URL_FAQ || ''),
  APP_EXTERNAL_URL_RELEASE: z.string().default(process.env.REACT_APP_EXTERNAL_URL_RELEASE || ''),
  APP_EXTERNAL_URL_HOW_TO_PASS: z.string().default(process.env.REACT_APP_EXTERNAL_URL_HOW_TO_PASS || ''),
  APP_EXTERNAL_URL_HOW_TO_BROWSER: z.string().default(process.env.REACT_APP_EXTERNAL_URL_HOW_TO_BROWSER || ''),
  APP_EXTERNAL_URL_CORPORATE: z.string().default(process.env.REACT_APP_EXTERNAL_URL_CORPORATE || ''),
  APP_EXTERNAL_URL_CONTACT: z.string().default(process.env.REACT_APP_EXTERNAL_URL_CONTACT || ''),
})

const parsed = schema.safeParse({})

if (!parsed.success) {
  throw Error(`Invalid Env. ${JSON.stringify(parsed.error.format())}`)
}

const env = parsed.data

export default env
