import React, { useState, useRef } from 'react'
import { FormControl, FormLabel, Stack, TextField, Container, Button, Checkbox, FormControlLabel, Link, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Controller } from 'react-hook-form'
import { useParams, useNavigate } from "react-router-dom"
import Alert from '@mui/material/Alert'
import logo from '../../assets/ref-logo.png'
import theme from '../../style/theme'
import { useAuth } from 'hooks/useAuth'
import useForm from 'hooks/useForm'
import LoginSchema from 'schema/login/loginSchema'
import useSecretModeFlag from 'hooks/useSecretModeFlag'
import Snackbar from '@mui/material/Snackbar'
import env from 'env'

const LoginPage = () => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')

  const auth = useAuth()
  const navigate = useNavigate()
  const { flag, setFlagValue } = useSecretModeFlag(false)
  const submitButton = useRef<HTMLButtonElement>(null)

  const { control, handleSubmit, formState: { errors } } = useForm(LoginSchema, {
    mode: 'all',
    defaultValues: { id: '', password: '', isSecretMode: flag },
  })

  const handleSnackClose = () => {
    setSnackBarOpen(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter'){
      return
    }
    if (submitButton.current) {
      submitButton.current.click()
    }
  }

  const executeSignIn = async (data: LoginSchema) => {
    // secretモードフラグを localStorage に登録する
    setFlagValue(data.isSecretMode)

    const result = await auth.SignIn(data.id, data.password, clinicId)
    if (result.success) {
      navigate({ pathname: `/${clinicId}/patient` })
    } else {
      // alert(result.message)
      setSnackBarOpen(true)
      setSnackBarMessage(result.message)
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Stack bgcolor="#474747" height='100vh' direction="column" alignItems="center" justifyContent="center">
          <img src={logo} alt="oralisロゴ" style={{ width: "160px", marginBottom: "16px" }}/>
          <Container maxWidth="xs">
            <Stack spacing={2}>
              <FormControl>
                <FormLabel sx={{ color: "white", fontWeight: "bold" }}>ID</FormLabel>
                <Controller
                  name="id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      sx={{ background: "white", borderRadius: "8px" }}
                      placeholder="IDを入力してください。"
                      onKeyDown={handleKeyDown}
                    />
                  )}
                />
                {errors.id && (
                    <Typography color="error" fontSize={12} sx={{pt: 1}}> {errors.id.message}</Typography>
                )}
              </FormControl>
              <FormControl>
                <FormLabel sx={{ color: "white", fontWeight: "bold" }}>パスワード</FormLabel>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="password"
                      sx={{ background: "white", borderRadius: "8px" }}
                      placeholder="パスワードを入力してください。"
                      onKeyDown={handleKeyDown}
                    />
                  )}
                />
                {errors.password && (
                    <Typography color="error" fontSize={12} sx={{pt: 1}}> {errors.password.message}</Typography>
                )}
              </FormControl>
            </Stack>
          </Container>
          <Stack spacing={2} sx={{ mt: 4 }}>
            <FormControl>
              <Controller
                name="isSecretMode"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        sx={{
                          color: 'white',
                          '&.Mui-checked': { color: theme.palette.primary.main }
                        }}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="シークレットモードでログインする"
                    sx={{ color: 'white', fontWeight: 'bold' }}
                  />
                )}
              />
            </FormControl>
            <Button
              variant="contained"
              sx={{
                px: 2 ,py: 2 ,background: theme.palette.primary.main , color: "white",
                borderRadius: "60px", fontWeight: "bold", letterSpacing: "0.08em"
              }}
              onClick={handleSubmit(executeSignIn)}
              ref={submitButton}
            >
              ログインする
            </Button>
          </Stack>
          <Stack spacing={2} sx={{ mt: 4 }}  alignItems="center" >
            <Stack direction="row" spacing={2} >
              <Link href={env.APP_EXTERNAL_URL_HOW_TO_PASS} target="_blank" rel="noopener noreferrer" sx={{ px: 1, py: 1, background: "white", borderRadius: "4px" , color: "#474747", fontWeight: "bold", textDecoration: "none", fontSize: "small"}}>パスワードとIDの保存の方法</Link>
              <Link  href={env.APP_EXTERNAL_URL_HOW_TO_BROWSER} target="_blank" rel="noopener noreferrer" sx={{ px: 1, py: 1 , background: "white", borderRadius: "4px" , color: "#474747", fontWeight: "bold", textDecoration: "none", fontSize: "small"}}>ブラウザ保存の方法</Link>
            </Stack>
          </Stack>
        </Stack>
        <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={snackBarOpen}
            onClose={handleSnackClose}
        >
          <Alert
            severity={'error'}
            sx={{ width: '100%' }}
            variant="filled"
          >{snackBarMessage}</Alert>
        </Snackbar>
      </ThemeProvider>
    </>
  )
}

export default LoginPage
