import { useSyncExternalStore } from "react"

type WindowSize = {
  windowWidth: number
}

const getWindowWidth = () => {
  return window.innerWidth
}

const subscribeWindowSizeChange = (callback: () => void) => {
  window.addEventListener("resize", callback)
  return () => window.removeEventListener("resize", callback)
}

export const useWindowSize = (): WindowSize => {
  const width = useSyncExternalStore(subscribeWindowSizeChange, getWindowWidth)
  return { windowWidth: width }
}