import { useMutation } from '@tanstack/react-query'
import { deleteTmpPhoto, saveTmpPhoto, uploadTmpPhoto } from './request'

export const useSaveTempPhoto = () => {
  return useMutation({
    mutationFn: saveTmpPhoto,
    onError: (e) => console.error(e),
  })
}

export const useUploadTempPhoto = () => {
  return useMutation({
    mutationFn: uploadTmpPhoto,
    onError: (e) => console.error(e),
  })
}

export const useDeleteTempPhoto = () => {
  return useMutation({
    mutationFn: deleteTmpPhoto,
    onError: (e) => console.error(e),
  })
}
