import { Amplify, ResourcesConfig } from 'aws-amplify'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { CookieStorage } from 'aws-amplify/utils'
import env from 'env'

const AuthConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    userPoolId: env.AUTH_USER_POOL_ID ?? '',
    userPoolClientId: env.AUTH_USER_POOL_WEB_CLIENT_ID ?? '',
  },
}

Amplify.configure({
  Auth: AuthConfig,
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    // refs: https://github.com/aws-amplify/amplify-js/issues/12629#issuecomment-1833960742
    domain: env.AUTH_COOKIE_STORAGE_DOMAIN,
    path: '/',
    expires: 365,
    sameSite: 'strict',
    secure: true,
  })
)
