import { MutableRefObject, SyntheticEvent, useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import { PhotoObject } from 'types/PhotoObject'
import { getImageUrl } from 'lib/getImagePath'

interface SwitchPhotoProps {
  image: PhotoObject
  frameWidth: number
  frameHeight: number
  imageRefs: MutableRefObject<(HTMLImageElement | null)[]>
  isEdit: boolean
}

export const ControlledImage = (props: SwitchPhotoProps) => {
  const { image, frameWidth, frameHeight, imageRefs, isEdit } = props
  const [imageStyle, setImageStyle] = useState({ width: frameWidth, height: frameHeight })
  const refIndex = isEdit ? 0 : image.angle

  const renderImage = (event: SyntheticEvent<HTMLImageElement, Event>, image: PhotoObject) => {
    // 各種affine値抽出
    const flop = image.affine.flop ? -1 : 1
    const flip = image.affine.flip ? -1 : 1
    const rotate = image.affine.rotate
    const scale = image.affine.scale
    const translateX = image.affine.translateX
    const translateY = image.affine.translateY
    // 画像の横縦を抽出
    const naturalWidth = event.currentTarget.naturalWidth
    const naturalHeight = event.currentTarget.naturalHeight
    // 表示枠に即した画像の縮小率を算出
    const reduction = Math.min(frameWidth / naturalWidth, frameHeight / naturalHeight) * scale
    // 各種属性値の設定
    const transformRotate = `rotate(${rotate}deg)`
    const transformScale = `scale(${reduction * flop}, ${reduction * flip})`
    event.currentTarget.style.transform = `${transformRotate} ${transformScale}`
    setImageStyle({ width: event.currentTarget.naturalWidth, height: event.currentTarget.naturalHeight })
    // 移動は移動枠に対して行う
    if (event.currentTarget.parentElement) {
      // 移動枠に即した移動値の算出
      const positionX = translateX * reduction
      const positionY = translateY * reduction
      // 各種属性値の設定
      const transformTranslate = `translate(${positionX}px, ${positionY}px)`
      event.currentTarget.parentElement.style.transform = `${transformTranslate}`
    }
  }

  const getImagePath = useCallback((image: PhotoObject) => {
    return getImageUrl(image.path)
  }, [])

  return (
    <Box className="fitimage imageframe">
      <img src={getImagePath(image)} alt={`${image.anglename}`} className="fitimage"
        style={imageStyle} ref={el => (imageRefs.current[refIndex] = el)}
        onLoad={(event: SyntheticEvent<HTMLImageElement, Event>) => renderImage(event, image)}
        onClick={(event: SyntheticEvent<HTMLImageElement, Event>) => renderImage(event, image)}
      />
    </Box>
  )
}

export default ControlledImage
