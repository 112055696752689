import React, { useEffect, useState } from 'react'
import { Typography, Box, Stack, Button, Table, TableBody, TableRow, TableCell, Snackbar, Alert } from '@mui/material'
import Layout from 'components/Layout'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'style/theme'
import ImageIcon from '@mui/icons-material/Image'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import Tabs from '@mui/material/Tabs'
import Tab from "@mui/material/Tab"
import PageBackConfirmButton from 'components/PageBackConfirmButton'
import { ConfirmModal, ConfirmModalProps } from 'components/ConfirmModal'
import { CompleteModal, CompleteModalOnCloseProps, CompleteModalProps } from 'components/CompleteModal'
import { useParams, useNavigate } from "react-router-dom"
import { useAuth } from 'hooks/useAuth'
import { useGetAllPhotoByExam, useSwapPhoto } from 'api/photo'
import { Photo, SwapPhotoResponse } from 'api/photo/responseType'
import { AngleName } from 'api/setting/responseType'
import { PhotoObject, getAlterPath, getAngleName, getInitialPhotos, getTabName } from 'types/PhotoObject'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import OperationProof from 'components/OperationProof'
import { AxiosError } from 'axios'
import useResetQuery from 'api/useResetQuery'
import { useGetAngleNameSetting } from 'api/setting'
import { useGetPatient } from 'api/patient'
import { Patient } from 'api/patient/responseType'
import CheckablePhotoFrame from 'components/CheckablePhotoFrame'
import PhotoFrame from 'components/PhotoFrame'
import { useWindowSize } from 'lib/useWindowSize'
import { chunk } from 'lib/ArrayService'

type PhotoProps = {
  id: string
  examId: string
  clinicId: string
}

type ConfirmModalOnCloseProps = {
  modalStatus: string
}

type SwapProps = {
  id?: number
  table: 'photos' | 'vagabonds'
  angle: number
  path?: string
  angleName?: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  tabValue: number
}

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const SwapAngle = () => {
  const urlParams = useParams<PhotoProps>()
  const id = urlParams.id ? parseInt(urlParams.id) : 0
  const examId = urlParams.examId ? parseInt(urlParams.examId) : 0
  const clinicId = urlParams.clinicId ?? ''
  const auth = useAuth()
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()
  const {
    data: angleNameSettingData, isError: isAngleNameSettingError, isPending: isAngleNameSettingPending
  } = useGetAngleNameSetting()
  const [angleNameSetting, setAngleNameSetting] = useState<AngleName[]>([])
  const {
    data: photoData, isError: isPhotoError, isPending: isPhotoPending,
    isRefetchError: isPhotoRefetchError, isRefetching: isPhotoRefetchPending
  } = useGetAllPhotoByExam(id, examId)
  const {
    data: patientData, isError: isPatientError, isPending: isPatientPending
  } = useGetPatient(id)
  const initialPhotos = getInitialPhotos(auth.clinicid, id)
  const [patient, setPatient] = useState<Patient | null>(null)
  const [photos, setPhotos] = useState<PhotoObject[]>([])
  const [vagabonds, setVagabonds] = useState<PhotoObject[]>([])
  const [swapItems, setSwapItems] = useState<SwapProps[]>([])
  const [examinationDate, setExaminationDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [confirmModalConfig, setConfirmModalConfig] = useState<ConfirmModalProps | undefined>()
  const [completeModalConfig, setCompleteModalConfig] = useState<CompleteModalProps | undefined>()
  const { mutate: swapMutate } = useSwapPhoto()
  const [tabValue, setTabValue] = useState(0)
  const [operationProofing, setOperationProofing] = useState(false)
  const navigate = useNavigate()
  const resetQuery = useResetQuery()
  const [checked, setChecked] = useState<number[]>([])
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarFlag, setSnackBarFlag] = useState('')

  const { windowWidth } = useWindowSize()
  const [ frameWidth, setFrameWidth] = useState(windowWidth >= 1400 ? 280 : (windowWidth < 1025 ? 240 : 260))
  const [ frameHeight, setFrameHeight] = useState(windowWidth >= 1400 ? 210 : (windowWidth < 1025 ? 180 : 195))

  useEffect(() => {
    setFrameWidth(windowWidth >= 1400 ? 280 : (windowWidth < 1025 ? 240 : 260))
    setFrameHeight(windowWidth >= 1400 ? 210 : (windowWidth < 1025 ? 180 : 195))
  }, [windowWidth])

  useEffect(() => {
    if (isPhotoError || isAngleNameSettingError || isPatientError || isPhotoRefetchError) {
      console.error(isPhotoError)
      console.error(isAngleNameSettingError)
      console.error(isPatientError)
      console.error(isPhotoRefetchError)
      return
    }

    if (
      !isPhotoPending &&
      !isPhotoRefetchPending &&
      photoData !== undefined &&
      photoData.examination !== undefined
    ) {
      if (photos.length === 0) {
        setPhotos(photoData.examination.photo ?? [])
      }
      if (vagabonds.length === 0 && vagabonds[0] === undefined) {
        setVagabonds(photoData.examination.vagabond ?? [])
      }
      setExaminationDate(photoData.examination.examinationDate ?? '')
    }

    if (!isPatientPending && patientData !== undefined) {
      setPatient(patientData.patient)
    }

    if (!isAngleNameSettingPending && angleNameSettingData !== undefined) {
      setAngleNameSetting(angleNameSettingData.angleNames)
    }
  }, [
    isPhotoError, photoData, isPhotoPending, isPhotoRefetchPending, isPhotoRefetchError,
    angleNameSettingData, isAngleNameSettingError, isAngleNameSettingPending,
    patientData, isPatientError, isPatientPending,
    initialPhotos, photos, vagabonds
  ])

  if (isPhotoPending) {
    return (
      <OperationProof initial={true} />
    )
  }

  const getSettingAngleName = (angle: number) => {
    const setting = angleNameSetting.find(item => item.angle === angle)
    const defaultName = getAngleName(angle)
    return setting ? setting.name : defaultName
  }

  const handleLeave = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    const ret = await new Promise<ConfirmModalOnCloseProps>((resolve) => {
      setConfirmModalConfig({
        onClose: resolve,
        title: '変更は保存されていません。離脱しますか？'
      })
    })
    setConfirmModalConfig(undefined)
    if (ret.modalStatus === 'ok') {
      navigate(`/${clinicId}/patient/${id}`)
    }
    if (ret.modalStatus === 'cancel') {
      return
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleClick = (
    table: 'photos' | 'vagabonds',
    image: PhotoObject | undefined,
    angle: number,
    serial: number
  ) => {
    const newSwap = structuredClone(swapItems)
    const newChecked = structuredClone(checked)
    const swapObject: SwapProps = {
      id: image?.id,
      table: table,
      angle: angle,
      path: image ? image.path : getAlterPath(angle),
      angleName: getSettingAngleName(angle)
    }
    swapObject.table = table
    if (newChecked.filter(item => item === serial).length > 0) {
      const index = newChecked.findIndex(item => item === serial)
      newSwap.splice(index, 1)
      newChecked.splice(index, 1)
    } else {
      newSwap.push(swapObject)
      newChecked.push(serial)
    }
    if (newChecked.length > 2) {
      return false
    }
    setSwapItems(newSwap)
    setChecked(newChecked)
  }

  const handleUpload = async () => {
    const ret = await new Promise<ConfirmModalOnCloseProps>((resolve) => {
      setConfirmModalConfig({
        onClose: resolve,
        title: '更新しますか？'
      })
    })
    setConfirmModalConfig(undefined)
    const updatedAngles: number[] = [swapItems[0].angle, swapItems[1].angle]
    if (ret.modalStatus === 'ok') {
      setOperationProofing(true)

      swapMutate(
        { patientId: id, examinationId: examId, req: {
          one: {
            fromId: swapItems[0].id ?? 0,
            fromTable: swapItems[0].table,
            angle: swapItems[0].angle
          },
          another: {
            fromId: swapItems[1].id ?? 0,
            fromTable: swapItems[1].table,
            angle: swapItems[1].angle
          }
        } },
        {
          onSuccess: async (response: SwapPhotoResponse) => {
            if (response.status === 'OK') {
              setOperationProofing(false)
              await new Promise<CompleteModalOnCloseProps>((resolve) => {
                setCompleteModalConfig({
                  onClose: resolve,
                  title: `更新が完了しました。`
                })
              })
              setOperationProofing(true)
              setCompleteModalConfig(undefined)

              resetQuery(['photoList'])
              resetQuery(['patientList'])
              resetQuery(['patientDetail', id])
              resetQuery(['allPhoto', id])
              resetQuery(['allPhotoByExam', examId])
              resetQuery(['listByExam', id, examId])

              updatedAngles.forEach(angle => {
                resetQuery(['listByTimelapse', id, angle])
              })

              navigate(`/${clinicId}/patient/${id}`)
            } else {
              setOperationProofing(false)
              setSnackBarOpen(true)
              setSnackBarMessage('エラーが発生しました。')
              setSnackBarFlag('error')
            }
          },
          onError: (error: AxiosError) => {
            setOperationProofing(false)
            setSnackBarOpen(true)
            setSnackBarMessage(error.message)
            setSnackBarFlag('error')
          }
        }
      )
    }
    if (ret.modalStatus === 'cancel') {
      return
    }
  }

  const handleSnackClose = () => {
    setSnackBarOpen(false)
  }

  const imageGetter = (photos: Photo[], angle: number) => {
    return photos?.find((photo:Photo) => photo.angle === angle)
  }

  const textAlterTarget = [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 50, 51, 52, 53, 54, 31, 34, 35, 36, 37, 38]

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackConfirmButton onClick={handleLeave}/>
          <Box sx={{ background: 'white', borderRadius: '16px', p: 3, mt: 1, mb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
              <Box sx={{ width: '40px', height: '40px', borderRadius: '50%', background: theme.palette.secondary.main, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ImageIcon sx={{ color: theme.palette.text.primary }} />
              </Box>
              <Typography sx={{ fontWeight: '600', fontSize: '18px', color: theme.palette.text.primary }}>写真入れ替え</Typography>
            </Box>
            <Typography sx={{ fontSize: '16px', color: theme.palette.text.primary, mt: 2 }}>2つの写真のアングルを入れ替えることができます。</Typography>
            <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)", tableLayout: "fixed", mt: 2 }}>
              <TableBody>
                  <TableRow>
                    <TableCell width={40} sx={{ background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                      名前
                    </TableCell>
                    <TableCell align="left" width={160} sx={{borderRight: "1px solid rgba(224, 224, 224, 1)"}}>{patient?.name}</TableCell>
                    <TableCell width={40} sx={{ background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                      患者番号
                    </TableCell>
                    <TableCell align="left" width={80} sx={{borderRight: "1px solid rgba(224, 224, 224, 1)"}}>{patient?.patientNo ?? '-'}</TableCell>
                    <TableCell width={40} sx={{ background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                      撮影日
                    </TableCell>
                    <TableCell align="left" width={80}>{examinationDate}</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
            <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2}>
              <Stack direction='row' width={3 / 7} height={114} p={1} sx={{border: 'solid 1px rgba(224, 224, 224, 1)'}}>
                {
                  swapItems[0] ?
                  <Stack direction='row' justifyContent='flex-start' gap={2} width={1 / 1}>
                    <PhotoFrame
                      image={imageGetter(swapItems[0].table === 'photos' ? photos : vagabonds, swapItems[0].angle)} angleName={getSettingAngleName(swapItems[0].angle)}
                      isNotLazy={true} alterPath={getAlterPath(swapItems[0].angle)}
                      isTextAlter={textAlterTarget.includes(swapItems[0].angle)}
                    />
                    <Stack>
                      <Typography>{getTabName(swapItems[0].table === 'photos' ? swapItems[0].angle : 0)}</Typography>
                      <Typography>{swapItems[0].angleName}</Typography>
                    </Stack>
                  </Stack> :
                  <Stack justifyContent='center' alignItems='center' width={1 / 1}>
                    <Typography>選択なし</Typography>
                  </Stack>
                }
              </Stack>
              <SwapHorizIcon/>
              <Stack direction='row' width={3 / 7} height={114} p={1} sx={{border: 'solid 1px rgba(224, 224, 224, 1)'}}>
                {
                  swapItems[1] ?
                  <Stack direction='row' justifyContent='flex-start' gap={2} width={1 / 1}>
                    <PhotoFrame
                      image={imageGetter(swapItems[1].table === 'photos' ? photos : vagabonds, swapItems[1].angle)} angleName={getSettingAngleName(swapItems[1].angle)}
                      isNotLazy={true} alterPath={getAlterPath(swapItems[1].angle)}
                      isTextAlter={textAlterTarget.includes(swapItems[1].angle)}
                    />
                    <Stack>
                      <Typography>{getTabName(swapItems[1].table === 'photos' ? swapItems[1].angle : 0)}</Typography>
                      <Typography>{swapItems[1].angleName}</Typography>
                    </Stack>
                  </Stack> :
                  <Stack justifyContent='center' alignItems='center' width={1 / 1}>
                    <Typography>選択なし</Typography>
                  </Stack>
                }
              </Stack>
            </Stack>
            <Stack sx={{ display: 'flex', alignItems: 'flex-end', mt: 2 }} >
              <Button
                onClick={handleUpload}
                disabled={swapItems.length < 2}
                size='medium'
                variant='outlined'
                sx={{ py: 1, width: '260px', color: 'white', background: theme.palette.primary.main, textAlign: 'center', fontSize: '14px', borderRadius: '40px', px: 8, fontWeight: 'bold',
                  '&:hover': { color: theme.palette.primary.main },
                  '&:disabled': { backgroundColor: 'white' }
                }}
              >
                交換する
              </Button>
            </Stack>
            <Box sx={{ mt: 2, width: '100%'}}>
              <Tabs
                value={tabValue}
                centered
                variant = "fullWidth"
                onChange={handleTabChange}
                sx={{ borderBottom: "1px solid #F4F4F4" }}
              >
                <Tab label="口腔内" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="その他" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="顔" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="レントゲン" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="保管庫" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
              </Tabs>
              <TabPanel tabValue={tabValue} index={0}>
                <Stack sx={{ mt: 2, display: 'flex', justifyContent: 'center' }} direction='row'>
                  {[11].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        alterPath={getAlterPath(angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[13, 10, 14].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        alterPath={getAlterPath(angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ mt: 2, display: 'flex', justifyContent: 'center' }} direction='row'>
                  {[12].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        alterPath={getAlterPath(angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
              </TabPanel>
              <TabPanel tabValue={tabValue} index={1}>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[20, 21, 22].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        isTextAlter={!imageGetter(photos, angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[23, 24, 25].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        isTextAlter={!imageGetter(photos, angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[26, 27, 28].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        isTextAlter={!imageGetter(photos, angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[29, 50, 51].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        isTextAlter={!imageGetter(photos, angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[52, 53, 54].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        isTextAlter={!imageGetter(photos, angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
              </TabPanel>
              <TabPanel tabValue={tabValue} index={2}>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[32, 30, 33].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        alterPath={getAlterPath(angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[31, 34, 35].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        isTextAlter={!imageGetter(photos, angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[36, 37, 38].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        isTextAlter={!imageGetter(photos, angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
              </TabPanel>
              <TabPanel tabValue={tabValue} index={3}>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[40, 41].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        alterPath={getAlterPath(angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[42, 43].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        alterPath={getAlterPath(angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[44, 45, 46].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        alterPath={getAlterPath(angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {[47, 48].map((angle) => {
                    const image = photos.find(photo => angle === photo.angle)
                    return (
                      <CheckablePhotoFrame
                        image={imageGetter(photos, angle)} key={angle} angleName={getSettingAngleName(angle)}
                        isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                        alterPath={getAlterPath(angle)}
                        clickHandler={() => handleClick('photos', image, angle, angle)}
                        checked={checked.includes(angle)}
                        disabled={checked.length === 2 && !checked.includes(angle)}
                      />
                    )
                  })}
                </Stack>
              </TabPanel>
              <TabPanel tabValue={tabValue} index={4}>
                {
                  chunk(vagabonds, 3).map((chunk: Photo[], outerIndex: number) => (
                    <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row' key={outerIndex}>
                      {chunk.map((image, index) => (
                        <CheckablePhotoFrame
                          image={imageGetter(vagabonds, image.angle)} key={index}
                          isNotLazy={true} frameWidth={frameWidth} frameHeight={frameHeight}
                          clickHandler={() => handleClick('vagabonds', image, image.angle, 100 + index)}
                          checked={checked.includes(100 + index)}
                          disabled={
                            (checked[0] >= 100 || checked.length === 2) && checked[0] !== 100 + index
                          }
                        />
                      ))}
                    </Stack>
                  ))
                }
              </TabPanel>
            </Box>
            <Stack sx={{ display: 'flex', alignItems: 'flex-end', mt: 4 }} >
              <Button
                onClick={handleUpload}
                disabled={swapItems.length < 2}
                size='medium'
                variant='outlined'
                sx={{ py: 1, width: '260px', color: 'white', background: theme.palette.primary.main, textAlign: 'center', fontSize: '14px', borderRadius: '40px', px: 8, fontWeight: 'bold',
                  '&:hover': { color: theme.palette.primary.main },
                  '&:disabled': { backgroundColor: 'white' }
                }}
              >
                交換する
              </Button>
            </Stack>
          </Box>
          {confirmModalConfig && <ConfirmModal {...confirmModalConfig} />}
          {completeModalConfig && <CompleteModal {...completeModalConfig} />}
          {operationProofing && <OperationProof initial={false} />}
          <Snackbar
              autoHideDuration={3000}
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              open={snackBarOpen}
              onClose={handleSnackClose}
          >
            <Alert
              severity={snackBarFlag === 'error' ? 'error' : 'success'}
              sx={{ width: '100%' }}
              variant="filled"
            >{snackBarMessage}</Alert>
          </Snackbar>
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default SwapAngle
