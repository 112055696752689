import React, { useEffect } from 'react'
import { Typography, Box, Stack, CircularProgress  } from '@mui/material'
import Layout from '../../components/Layout'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../style/theme'
import FlagIcon from '@mui/icons-material/Flag'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import PageBackButton from '../../components/PageBackButton'
import { useParams } from "react-router-dom"
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useInView } from 'react-intersection-observer'
import { useGetPatientStatusDetailReport } from 'api/report'
import OperationProof from 'components/OperationProof'

const DashboardDayReport = () => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()
  dayjs.locale(ja)
  
  const { ref, inView } = useInView()

  const {
    data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage
  } = useGetPatientStatusDetailReport()

  useEffect(() => {
    if (isError) {
      console.error(isError)
      return
    }

    if (hasNextPage && inView) {
      fetchNextPage()
    }
  }, [inView, isError, hasNextPage, fetchNextPage])

  if (isLoading || !data) {
    return <OperationProof initial/>
  }

  const status = data.pages.map(item => item.data).flat()

  const convertDate = (date: string, dayOfWeek: boolean) => {
    const format = dayOfWeek ? 'YYYY/MM/DD (dd)' : 'YYYY/MM/DD'
    const converted = dayjs(date).format(format)
    return converted
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={`/${clinicId}/dashboard`} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1, mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FlagIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>レポート情報</Typography>
            </Box>
            {
              status.map((item, index) => {
                const date = convertDate(item.date, false)
                return (
                  <Box sx={{ border: "1px solid #E4E4E4", mt: 3 }} key={index}>
                    <Box sx={{ background: "#FBFBFB", borderBottom: "1px solid #E4E4E4" }}>
                      <Typography sx={{ fontWeight: "600", fontSize: "16px", p: 2 }}>
                        {convertDate(item.date, true)}
                      </Typography>
                    </Box>
                    <List>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の新規相談は{item.fme}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の診断前は{item.dia}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の終了患者は{item.cmp}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の動的処置中は{item.at}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の保定治療中は{item.ret}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の検査中は{item.ins}件でした。
                        </Typography>
                      </ListItem>
                      {
                        item.slacking ? (
                          <ListItem>
                            <Typography sx={{ fontSize: "14px", p: 0, color: "#E33B3B", fontWeight: "600" }}>
                              1ヶ月以上治療していない患者は{item.slacking}件でした。
                            </Typography>
                          </ListItem>
                        ) : (
                          <></>
                        )
                      }
                    </List>
                  </Box>
                )
              })
            }                  
          </Box>
          {
            isFetchingNextPage ? (
              <Stack alignItems="center" justifyContent="center" mb={2}>
                <CircularProgress />
              </Stack>
            ) : (
              <Box ref={ref}/>
            )
          }
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default DashboardDayReport