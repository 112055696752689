import { client } from 'lib/AxiosClientProvider'
import { ListPatient, FirstStepCheckPatient, SecondStepCheckPatient, ThirdStepCheckPatient, FourthStepCheckPatient, UpsertPatient, PatchPatientStatus, PatchPatientBracket, DeletePatient } from './requestType'
import {
  DeletePatientResponse, GetPatientAllIndexesResponse, GetPatientListResponse, GetPatientResponse, PatchPatientBracketResponse, PatchPatientStatusResponse,
  PostPatientFirstStepCheckResponse, PostPatientSecondStepCheckResponse, PostPatientThirdStepCheckResponse, PostPatientFourthStepCheckResponse, PostPatientUpsertResponse
} from './responseType'

export const getPatientList = async (req: ListPatient): Promise<GetPatientListResponse> => {
  const response = await client.get(`/patient`, {
    params: req
  })

  return response.data
}

export const getPatient = async (id: number): Promise<GetPatientResponse> => {
  const response = await client.get(`/patient/${id}`)

  return response.data
}

export const getPatientAllIndexes = async (): Promise<GetPatientAllIndexesResponse> => {
  const response = await client.get(`/patient/indexes`, {
  })

  return response.data
}

export const postFirstPatientStep = async ({
  req
}: {
  req: FirstStepCheckPatient
}): Promise<PostPatientFirstStepCheckResponse> => {
  const response = await client.post(`/patient/check/1`, req)

  return response.data
}

export const postSecondPatientStep = async ({
  req
}: {
  req: SecondStepCheckPatient
}): Promise<PostPatientSecondStepCheckResponse> => {
  const response = await client.post(`/patient/check/2`, req)

  return response.data
}

export const postThirdPatientStep = async ({
  req
}: {
  req: ThirdStepCheckPatient
}): Promise<PostPatientThirdStepCheckResponse> => {
  const response = await client.post(`/patient/check/3`, req)

  return response.data
}

export const postFourthPatientStep = async ({
  req
}: {
  req: FourthStepCheckPatient
}): Promise<PostPatientFourthStepCheckResponse> => {
  const response = await client.post(`/patient/check/4`, req)

  return response.data
}

export const postPatient = async ({
  req
}: {
  req: UpsertPatient
}): Promise<PostPatientUpsertResponse> => {
  const response = await client.post(`/patient/save`, req)

  return response.data
}

export const patchPatient = async ({
  id,
  req
}: {
  id: number,
  req: UpsertPatient
}): Promise<PostPatientUpsertResponse> => {
  const response = await client.patch(`/patient/${id}/save`, req)

  return response.data
}

export const patchPatientStatus = async ({
  id,
  req
}: {
  id: number,
  req: PatchPatientStatus
}): Promise<PatchPatientStatusResponse> => {
  const response = await client.patch(`/patient/${id}/status`, req)

  return response.data
}

export const patchPatientBracket = async ({
  id,
  req
}: {
  id: number,
  req: PatchPatientBracket
}): Promise<PatchPatientBracketResponse> => {
  const response = await client.patch(`/patient/${id}/bracket`, req)

  return response.data
}

export const deletePatient = async ({
  id,
  req
}: {
  id: number,
  req: DeletePatient
}): Promise<DeletePatientResponse> => {
  const response = await client.delete(`/patient/${id}`, { data: req })

  return response.data
}
