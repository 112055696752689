import React, { FC, useEffect, useState } from 'react'
import { Typography, Stack, CircularProgress } from '@mui/material'
import ImageRender from 'components/ImageRender'
import { PhotoObject } from 'types/PhotoObject'

interface PhotoFrameProps {
  image?: PhotoObject
  alterPath?: string
  frameWidth?: number
  frameHeight?: number
  isNotLazy?: boolean
  angleName?: string
  isTextAlter?: boolean
}

const PhotoFrame: FC<PhotoFrameProps> = (props: PhotoFrameProps) => {
  const { image, alterPath, frameWidth, frameHeight, isNotLazy, angleName, isTextAlter } = props
  const [backgroundColor, setBackgroundColor] = useState('inherit')

  const width = frameWidth ?? 152
  const height = frameHeight ?? 114

  useEffect(() => {
    if (!image && isTextAlter) {
      setBackgroundColor('inherit')
    } else {
      setBackgroundColor('#777777')
    }
  }, [image, isTextAlter])

  return (
    <Stack position="relative">
      <Stack
        width={width} height={height} fontWeight={600} border='1px solid #A6A6A6' borderRadius='8px'
        justifyContent='center' alignItems='center' overflow='hidden'
        sx={{objectFit: 'cover', backgroundColor: backgroundColor}}
      >
        {
          !image ?
          isTextAlter ?
          <Typography sx={{ textAlign: 'center' }}>{angleName}</Typography> :
          <img src={alterPath} style={{ width: width, height: height }} alt=""/> :
          image.loading ?
          <CircularProgress /> :
          // 画像表示は「表示枠」「移動枠」「画像」の入れ子になっている
          // ここでは「移動枠」と「画像」の設定を行う
          <ImageRender image={image} frameWidth={width} frameHeight={height} isNotLazy={isNotLazy} />
        }
      </Stack>
    </Stack>
  )
}
export default PhotoFrame
