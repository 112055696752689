import React from 'react';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const PageBackConfirmButton = (props: any) => {
  const { onClick } = props

  return (
    <>
      <Box sx={{ background: "white", borderRadius: "16px", mt: 1 }}>
        <ListItemButton
          onClick={onClick}
          sx={{ "&:active": { background: "none" }, "&:hover": { textDecoration: "none", background: "white" }, display: "flex", alignItems: "center", borderRadius: "8px"}}
        >
          <ListItemIcon sx={{ color: "#A6A6A6", minWidth: "40px" }}>
            <ArrowBackIcon sx={{ width: "32px", height: "32px", color: "#A6A6A6" }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontWeight: "bold", color: "#A6A6A6" }} primary="戻る" />
        </ListItemButton> 
      </Box>
    </>
  )
}
export default PageBackConfirmButton;
