import React, { useState } from 'react'
import { Typography, Box, Stack, Grid } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../../style/theme'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { Dayjs } from 'dayjs'
import CircularProgressWithLabel from './CircularProgressWithLabel'
import { sexValues, ImageType } from 'types/PatientObjects'
import { generatePatientStatesIndividual } from 'lib/patientService'

export type Patient05Props = {
  show: boolean
  patientNo: string
  name: string
  kana: string
  sex: number
  birthday: Dayjs
  renderSkeletal: ImageType | undefined
  renderFaceType: ImageType | undefined
  renderAngleRight: ImageType | undefined
  renderAngleLeft: ImageType | undefined
  renderOtherElement: ImageType[]
  handleJump: Function
}

const Patient05 = (props: Patient05Props) => {
  const {
    show, patientNo, name, kana, sex, birthday, renderSkeletal, renderFaceType, renderAngleRight, renderAngleLeft, renderOtherElement, 
    handleJump
  } = props
  const [progress] = useState(100)

  const display = show ? "block" : "none"

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: display }}>
          <Stack sx={{ display: "flex", alignItems: "center", columnGap: "24px" }} direction = "row">
            <Box sx={{ mt: 3, position: 'relative' }}>          
              <CircularProgressWithLabel progress={progress} page={5} />
            </Box>
            <Box><Typography sx={{ fontWeight: "600", backgroundColor: "#F1F1F1", borderRadius: "8px", py: 2, px: 4, mt: 2 }} color="#364F4E">こちらで登録しますか？正しくない場合は修正してください。</Typography></Box>
          </Stack>
          <Stack sx={{ mt: 6 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ width: "180px", fontSize: "16px" }}>
                    患者番号
                  </TableCell>
                  <TableCell sx={{ minWidth: "590px", fontWeight: "600", fontSize: "18px" }}>
                    {patientNo}
                  </TableCell>  
                  <TableCell sx={{ width: "80px" }} align="right">
                    <Typography
                      sx={{ color: "#727272", fontSize: 14, fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) => handleJump(event, 1)}
                    >
                      修正する
                      <BorderColorIcon sx={{ color: "#727272", fontSize: "16px", ml: "2px" }}/> 
                    </Typography>   
                  </TableCell>                                
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: "180px", fontSize: "16px" }}>
                    患者名
                  </TableCell>
                  <TableCell sx={{ minWidth: "590px", fontWeight: "600", fontSize: "18px" }}>
                    {name}
                  </TableCell>
                  <TableCell sx={{ width: "80px" }} align="right">
                    <Typography
                      sx={{ color: "#727272", fontSize: 14, fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) => handleJump(event, 2)}
                    >
                      修正する
                      <BorderColorIcon sx={{ color: "#727272", fontSize: "16px", ml: "2px" }}/> 
                    </Typography>    
                  </TableCell>                                
                </TableRow> 
                <TableRow>
                  <TableCell sx={{ width: "180px", fontSize: "16px" }}>
                    患者名(よみがな)
                  </TableCell>
                  <TableCell sx={{ minWidth: "590px", fontWeight: "600", fontSize: "18px" }}>
                    {kana}
                  </TableCell>  
                  <TableCell sx={{ width: "80px" }} align="right">              
                    <Typography
                      sx={{ color: "#727272", fontSize: 14, fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) => handleJump(event, 2)}
                    >
                      修正する
                      <BorderColorIcon sx={{ color: "#727272", fontSize: "16px", ml: "2px" }}/> 
                    </Typography>  
                  </TableCell>                                
                </TableRow>  
                <TableRow>
                  <TableCell sx={{ width: "180px", fontSize: "16px" }}>
                    性別
                  </TableCell>
                  <TableCell sx={{ minWidth: "590px", fontWeight: "600", fontSize: "18px" }}>
                    {sexValues.find((item) => item.key === sex)?.title}
                  </TableCell>  
                  <TableCell sx={{ width: "80px" }} align="right">
                    <Typography
                      sx={{ color: "#727272", fontSize: 14, fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) => handleJump(event, 3)}
                    >
                      修正する
                      <BorderColorIcon sx={{ color: "#727272", fontSize: "16px", ml: "2px" }}/> 
                    </Typography>   
                  </TableCell>                                
                </TableRow>  
                <TableRow>
                  <TableCell sx={{ width: "180px", fontSize: "16px" }}>
                    生年月日
                  </TableCell>
                  <TableCell sx={{ minWidth: "590px", fontWeight: "600", fontSize: "18px" }}>
                    {birthday.format('YYYY/MM/DD')}
                  </TableCell>  
                  <TableCell sx={{ width: "80px" }} align="right">
                    <Typography
                      sx={{ color: "#727272", fontSize: 14, fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) => handleJump(event, 3)}
                    >
                      修正する
                      <BorderColorIcon sx={{ color: "#727272", fontSize: "16px", ml: "2px" }}/> 
                    </Typography>    
                  </TableCell>                                
                </TableRow>   
                <TableRow>
                  <TableCell sx={{ width: "180px", fontSize: "16px" }}>
                    状態
                  </TableCell>
                  <TableCell sx={{ minWidth: "590px", fontWeight: "600", fontSize: "18px" }}>
                    {generatePatientStatesIndividual(renderSkeletal, renderFaceType, renderAngleRight, renderAngleLeft, renderOtherElement)}
                    <Grid container sx={{ mt:2 }} gap={2} columns={4}>
                      {
                        renderSkeletal?.key !== 99 ? (
                          <Stack sx={{ width: "134px"}}>
                            <Typography sx={{ textAlign: "center" }}>骨格的要素</Typography>
                            <Box sx={{width: "134px", fontWeight: "600", height: "134px", border: "1px solid #333333",borderRadius: "8px" , fontSize: "18px", textAlign: "center" }}>
                              <img src={`${process.env.PUBLIC_URL}${renderSkeletal?.url}`} style={{ width: 90, height: 90, marginTop: "8px" }} alt='' />
                              <Typography sx={{ textAlign: "center" }}>
                                {renderSkeletal?.title}
                              </Typography>
                            </Box>
                          </Stack>
                        ) : (
                          <></>
                        )
                      }
                      {
                        renderFaceType?.key !== 99 ? (
                          <Stack sx={{ width: "134px"}}>
                            <Typography sx={{ textAlign: "center" }}>顔貌</Typography>
                            <Box sx={{ width: "134px", fontWeight: "600", height: "134px", border: "1px solid #333333",borderRadius: "8px" , fontSize: "18px", textAlign: "center" }}>
                              <img src={`${process.env.PUBLIC_URL}${renderFaceType?.url}`} style={{ width: 90, height: 90, marginTop: "8px" }} alt='' />
                              <Typography sx={{ textAlign: "center" }}>
                                {renderFaceType?.title}
                              </Typography>
                            </Box>
                          </Stack>
                        ) : (
                          <></>
                        )
                      }
                      {
                        renderAngleRight?.key !== 99 ? (
                          <Stack sx={{ width: "134px"}}>
                            <Typography sx={{ textAlign: "center" }}>咬合関係（右）</Typography>
                            <Box sx={{ width: "134px", fontWeight: "600", height: "134px", border: "1px solid #333333",borderRadius: "8px" , fontSize: "18px", textAlign: "center" }}>
                              <img src={`${process.env.PUBLIC_URL}${renderAngleRight?.url}`} style={{ width: 90, height: 90, marginTop: "8px" }} alt='' />
                              <Typography sx={{ textAlign: "center" }}>
                                {renderAngleRight?.title}
                              </Typography>
                            </Box>
                          </Stack>
                        ) : (
                          <></>
                        )
                      }
                      {
                        renderAngleLeft?.key !== 99 ? (
                          <Stack sx={{ width: "134px"}}>
                            <Typography sx={{ textAlign: "center" }}>咬合関係（左）</Typography>
                            <Box sx={{ width: "134px", fontWeight: "600", height: "134px", border: "1px solid #333333",borderRadius: "8px" , fontSize: "18px", textAlign: "center" }}>
                              <img src={`${process.env.PUBLIC_URL}${renderAngleLeft?.url}`} style={{ width: 90, height: 90, marginTop: "8px" }} alt='' />
                              <Typography sx={{ textAlign: "center" }}>
                                {renderAngleLeft?.title}
                              </Typography>
                            </Box>
                          </Stack>
                        ) : (
                          <></>
                        )
                      }
                      {
                        renderOtherElement.map((item: ImageType) => {
                          return (
                            <Stack sx={{ width: "134px"}} key={item.key}>
                              <Typography sx={{ textAlign: "center" }}>その他</Typography>
                              <Box sx={{
                                width: "134px", fontWeight: "600", height: "134px", border: "1px solid #333333",
                                borderRadius: "8px" , fontSize: "18px", textAlign: "center", overflow: "hidden"
                              }}>
                                <img src={`${process.env.PUBLIC_URL}${item.url}`} style={{ width: 120, height: 120, marginTop: "-16px", marginBottom: "-8px" }} alt='' />
                                <Typography sx={{ textAlign: "center" }}>
                                  {item.title}
                                </Typography>
                              </Box>
                            </Stack>
                          )
                        })
                      }                                                                                      
                    </Grid>                    
                  </TableCell>  
                  <TableCell sx={{ width: "80px" }} align="right">
                    <Typography
                      sx={{ color: "#727272", fontSize: 14, fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) => handleJump(event, 4)}
                    >
                      修正する
                      <BorderColorIcon sx={{ color: "#727272", fontSize: "16px", ml: "2px" }}/> 
                    </Typography>    
                  </TableCell>                                
                </TableRow>                                                                
              </TableBody>
            </Table>
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Patient05
