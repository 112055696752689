import { Box, Typography } from '@mui/material'
import logo from '../../assets/ref-logo.png'

const Page404 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#474747',
        textAlign: 'center',
      }}
    >
      <img src={logo} alt="oralisロゴ" style={{ width: "160px", marginBottom: "16px" }}/>
      <Typography
        variant="h1"
        component="div"
        sx={{ fontSize: '6em', color: '#fff', m: 3 }}
      >
        404
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: '1.2em', color: '#ddd' }}
      >
        お探しのページは見つかりませんでした。
      </Typography>
    </Box>
  )
}

export default Page404
