import React, { useEffect, useState } from 'react'
import { Typography, Box, Stack, Button } from '@mui/material'
import Layout from '../../components/Layout'
import dayjs, { Dayjs } from 'dayjs'
import ja from 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../style/theme'
import AssessmentIcon from '@mui/icons-material/Assessment'
import FlagIcon from '@mui/icons-material/Flag'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import { BarChart } from '@mui/x-charts/BarChart'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { NavLink, useParams } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import { useGetSlackingCountReport, useGetDashboardCardReport, useGetPatientStatusListReport, useGetCapacityReport, useGetPatientStatusVisualReport } from 'api/report'
import { SlackingCountResponse, DashboardCardResponse, PatientStatusListResponse, CapacityResponse, PatientStatusVisualResponse } from 'api/report/responseType'
import { DateRangePicker } from 'components/DateRangePicker'
import useResetQuery from 'api/useResetQuery'

const Dashboard = () => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()
  dayjs.locale(ja)
  const [init, setInit] = useState(true)
  const [from, setFrom] = useState<Dayjs | null>(dayjs().subtract(1, 'month'))
  const [to, setTo] = useState<Dayjs | null>(dayjs())
  const colors: string[] = ['#EAB221', '#F9CC57', '#FFEBB8', '#F44498', '#FA99C8', '#F7D8E7']

  const {
    data: slackingData, isError: isSlackingError, isPending: isSlackingPending
  } = useGetSlackingCountReport()
  const [ slacking, setSlacking ] = useState<SlackingCountResponse | null>(null)

  const {
    data: cardData, isError: isCardError, isPending: isCardPending,
    isRefetching: isCardRefetchPending, isRefetchError: isCardRefetchError
  } = useGetDashboardCardReport({
    req: {
      from: from?.format('YYYY-MM-DD'),
      to: to?.format('YYYY-MM-DD'),
    }
  })
  const [ cards, setCards ] = useState<DashboardCardResponse | null>(null)

  const {
    data: graphData, isError: isGraphError, isPending: isGraphPending,
    isRefetching: isGraphRefetchPending, isRefetchError: isGraphRefetchError
  } = useGetPatientStatusVisualReport({
    req: {
      from: from?.format('YYYY-MM-DD'),
      to: to?.format('YYYY-MM-DD'),
    }
  })
  const [ graph, setGraph ] = useState<PatientStatusVisualResponse | null>(null)

  const {
    data: statusData, isError: isStatusError, isPending: isStatusPending
  } = useGetPatientStatusListReport({
    req: {
      limit: 3
    }
  })
  const [ status, setStatus ] = useState<PatientStatusListResponse | null>(null)

  const {
    data: capacityData, isError: isCapacityError, isPending: isCapacityPending
  } = useGetCapacityReport({
    req: {
      limit: 1
    }
  })
  const [ capacity, setCapacity ] = useState<CapacityResponse | null>(null)
  const resetQuery = useResetQuery()

  useEffect(() => {
    if (init) {
      resetQuery(['patientStatusGraph'])
      setInit(false)
    } else {
      if (
        isSlackingError || isCardError || isStatusError || isCapacityError
        || isCardRefetchError || isGraphRefetchError
      ) {
        console.error(isSlackingError)
        console.error(isCardError)
        console.error(isStatusError)
        console.error(isCapacityError)
        console.error(isCardRefetchError)
        console.error(isGraphRefetchError)
        return
      }
  
      if(!isSlackingPending && slackingData !== undefined){
        setSlacking(slackingData)
      }
  
      if(!isCardPending && !isCardRefetchPending && cardData !== undefined){
        setCards(cardData)
      }
  
      if(!isGraphPending && !isGraphRefetchPending && graphData !== undefined){
        setGraph(graphData)
      }
  
      if(!isStatusPending && statusData !== undefined){
        setStatus(statusData)
      }
  
      if(!isCapacityPending && capacityData !== undefined){
        setCapacity(capacityData)
      }
    }
  }, [
    slackingData, isSlackingError, isSlackingPending,
    cardData, isCardError, isCardPending, isCardRefetchError, isCardRefetchPending,
    graphData, isGraphError, isGraphPending, isGraphRefetchError, isGraphRefetchPending,
    statusData, isStatusError, isStatusPending,
    capacityData, isCapacityError, isCapacityPending,
    init, resetQuery
  ])

  const convertDate = (date: string, dayOfWeek: boolean) => {
    const format = dayOfWeek ? 'YYYY/MM/DD (dd)' : 'YYYY/MM/DD'
    const converted = dayjs(date).format(format)
    return converted
  }

  const convertMonth = (date: string) => {
    const converted = dayjs(date).format('YYYY年MM月')
    return converted
  }

  const changeTerm = () => {
    setTimeout(() => {
      resetQuery(['dashboardCard'])
      resetQuery(['patientStatusGraph'])
    }, 1)
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          {
            slacking && slacking.slacking > 0 ? (
              <Box sx={{
                background: "linear-gradient(90deg, #d32f2f, #d32f2f 16px, white 16px, white)",
                borderRadius: "16px", py: 2, px: 4, mt: 2
              }}>
                <Typography color="error" fontSize={14} fontWeight="bold" display="flex" alignItems="center">
                  <InfoIcon color="error" sx={{ mr: 1 }}/>
                  1ヶ月以上来院していない患者がいます。
                </Typography>
              </Box>
            ) : (
              <></>
            )
          }
          <Stack sx={{ alignItems: "flex-start", mt: 2 }} direction="row">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box sx={{ background: "#4FD0D9", borderRadius: "8px", p: 2 }}>
                  <Typography sx={{ fontWeight: "600", fontSize: "16px", color: "white" }}>
                    動的治療開始患者数
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "white" }}>
                    {from?.format('YYYY/MM/DD')}-{to?.format('YYYY/MM/DD')}
                  </Typography>
                  <Stack sx={{ alignItems: "center",  justifyContent: "center", columnGap: "4px" }} direction="row">
                    <Typography sx={{ fontSize: "48px", color: "white" }}>
                      {cards?.active}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "white" }}>
                      人
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ background: "#4F9FD9", borderRadius: "8px", p: 2 }}>
                  <Typography sx={{ fontWeight: "600", fontSize: "16px", color: "white" }}>
                    保定治療開始患者数
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "white" }}>
                    {from?.format('YYYY/MM/DD')}-{to?.format('YYYY/MM/DD')}
                  </Typography>
                  <Stack sx={{ alignItems: "center",  justifyContent: "center", columnGap: "4px" }} direction="row">
                    <Typography sx={{ fontSize: "48px", color: "white" }}>
                      {cards?.retention}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "white" }}>
                      人
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ background: "#4F76D9", borderRadius: "8px", p: 2 }}>
                  <Typography sx={{ fontWeight: "600", fontSize: "16px", color: "white" }}>
                    治療終了患者数
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "white" }}>
                    {from?.format('YYYY/MM/DD')}-{to?.format('YYYY/MM/DD')}
                  </Typography>
                  <Stack sx={{ alignItems: "center",  justifyContent: "center", columnGap: "4px" }} direction="row">
                    <Typography sx={{ fontSize: "48px", color: "white" }}>
                      {cards?.completed}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "white" }}>
                      人
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Stack>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 2 }}>
            <DateRangePicker from={from} to={to} setFrom={setFrom} setTo={setTo} changeTerm={changeTerm}/>
          </Box>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <AssessmentIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>レポート</Typography>
            </Box>
            <BarChart
              dataset={init || isGraphPending || isGraphRefetchPending ? [] : graph?.data ?? []}
              xAxis={[{scaleType: 'band', dataKey: 'group' }]}
              series={[
                { dataKey: 'fme', stack: 'A', label: '新規相談'},
                { dataKey: 'dia', stack: 'A', label: '診断前'},
                { dataKey: 'cmp', stack: 'A', label: '終了'},
                { dataKey: 'at', stack: 'B', label: '動的処置中'},
                { dataKey: 'ret', stack: 'B', label: '保定治療中'},
                { dataKey: 'ins', stack: 'B', label: '検査中'},
              ]}
              loading={init || isGraphPending || isGraphRefetchPending || !(graph?.data)}
              colors={colors}
              height={350}
              slotProps={{
                legend: {
                  position: { vertical: 'top', horizontal: 'right' },
                  itemMarkHeight: 18,
                  itemMarkWidth: 18,
                  markGap: 4,
                  itemGap: 10,
                  labelStyle: {
                    fontSize: 12,
                  },
                }
              }}
            />
            <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
              <Button
              component={NavLink} to={`/${clinicId}/report`} onClick={() => resetQuery(['patientStatusGraph'])}
              sx={{ textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 8, fontWeight: "bold", mt: 2, "&:hover": { background: "#11867F" } }}>
                一覧をみる
              </Button>
            </Box>
          </Box>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FlagIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>レポート情報</Typography>
            </Box>
            {
              status?.data.map((item, index) => {
                const date = convertDate(item.date, false)
                return (
                  <Box sx={{ border: "1px solid #E4E4E4", mt: 3 }} key={index}>
                    <Box sx={{ background: "#FBFBFB", borderBottom: "1px solid #E4E4E4" }}>
                      <Typography sx={{ fontWeight: "600", fontSize: "16px", p: 2 }}>
                        {convertDate(item.date, true)}
                      </Typography>
                    </Box>
                    <List>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の新規相談は{item.fme}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の診断前は{item.dia}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の終了患者は{item.cmp}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の動的処置中は{item.at}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の保定治療中は{item.ret}件でした。
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>
                          {date}の検査中は{item.ins}件でした。
                        </Typography>
                      </ListItem>
                      {
                        item.slacking ? (
                          <ListItem>
                            <Typography sx={{ fontSize: "14px", p: 0, color: "#E33B3B", fontWeight: "600" }}>
                              1ヶ月以上治療していない患者は{item.slacking}件でした。
                            </Typography>
                          </ListItem>
                        ) : (
                          <></>
                        )
                      }
                    </List>
                  </Box>
                )
              })
            }
            <Box sx={{ display: "flex", alignItems: "flex-end", mt: 1, justifyContent: "flex-end" }}>
              <Button
                component={NavLink} to={`/${clinicId}/dashboard/day`}
                sx={{ textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 8, fontWeight: "bold", mt: 3, "&:hover": { background: "#11867F" } }}>
                  一覧をみる
              </Button>
            </Box>
          </Box>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, my: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <QueryStatsIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>使用データ量</Typography>
            </Box>
            {
              capacity?.data.map((item, index) => {
                return (
                  <Box sx={{ border: "1px solid #E4E4E4", mt: 3 }} key={index}>
                    <Box sx={{ background: "#FBFBFB", borderBottom: "1px solid #E4E4E4" }}>
                      <Typography sx={{ fontWeight: "600", fontSize: "16px", p: 2 }}>
                        {convertMonth(item.month)}のデータ量
                      </Typography>
                    </Box>
                    <List>
                      <ListItem>
                        <Stack sx={{ alignItems: "center", columnGap: "24px" }} direction="row">
                          <Typography sx={{ fontSize: "14px", p: 0 }}>今月データ量</Typography>
                          <Typography sx={{ fontSize: "18px", p: 0, fontWeight: "600" }}>{item.capacity}</Typography>
                        </Stack>
                      </ListItem>
                      <ListItem>
                        <Stack sx={{ alignItems: "center", columnGap: "24px" }} direction="row">
                          <Typography sx={{ fontSize: "14px", p: 0 }}>超過量</Typography>
                          <Typography sx={{ fontSize: "18px", p: 0, fontWeight: "600" }}>{item.excessed}</Typography>
                        </Stack>
                      </ListItem>
                      <Divider component="li"  sx={{ my: 1 }}/>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>基本料金 {item.fee.toLocaleString()}円</Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>超過料金 {item.excessFee.toLocaleString()}円</Typography>
                      </ListItem>
                      <Divider component="li"  sx={{ my: 1 }}/>
                      <ListItem sx={{ justifyContent: "flex-end" }}>
                        <Stack sx={{ display: "flex", alignItems: "center", columnGap: "24px"}} direction="row">
                          <Typography sx={{ fontSize: "14px", p: 0 }}>今月の請求金額</Typography>
                          <Typography sx={{ fontSize: "22px", p: 0, fontWeight: "600" }}>
                            {(item.fee + item.excessFee).toLocaleString()}円
                          </Typography>
                        </Stack>
                      </ListItem>
                    </List>
                  </Box>
                )
              })
            }
            <Box sx={{ display: "flex", alignItems: "flex-end", mt: 1, justifyContent: "flex-end" }}>
              <Button
                component={NavLink} to={`/${clinicId}/dashboard/payment`}
                sx={{ textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 8, fontWeight: "bold", mt: 3, "&:hover": { background: "#11867F" } }}>
                  一覧をみる
              </Button>
            </Box>
          </Box>
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default Dashboard
