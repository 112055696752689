import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'App'
import { AuthProvider } from 'hooks/useAuth'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from 'lib/queryClient'
import './styles.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <App/>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)

serviceWorkerRegistration.register()
