import { useQuery } from '@tanstack/react-query'
import { clinicKeys } from './queryKey'
import { getClinicMyself } from './request'

export const useGetClinicMyself = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: clinicKeys.myself,
    queryFn: getClinicMyself,
  })

  return { data, isPending, isError }
}
