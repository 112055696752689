import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import { patientKeys } from './queryKey'
import {
  deletePatient, getPatient, getPatientAllIndexes, getPatientList, patchPatient, patchPatientBracket, patchPatientStatus, postPatient,
  postFirstPatientStep, postSecondPatientStep, postThirdPatientStep, postFourthPatientStep
} from './request'
import { ListPatient } from './requestType'

export const useGetPatientList = (params: ListPatient) => {
  const { data, isPending, isError, isRefetching, isRefetchError, isPlaceholderData } = useQuery({
    queryKey: patientKeys.patientList(params.page),
    queryFn: () => getPatientList(params),
    placeholderData: keepPreviousData,
  })

  return { data, isPending, isError, isRefetching, isRefetchError, isPlaceholderData }
}

export const useGetPatient = (id: number) => {
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: patientKeys.detail(id),
    queryFn: () => getPatient(id),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const useGetPatientAllIndexes = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: patientKeys.indexes(),
    queryFn: () => getPatientAllIndexes(),
  })

  return { data, isPending, isError }
}

export const usePostPatientStep1 = () => {
  return useMutation({
    mutationFn: postFirstPatientStep,
    onError: (e) => console.error(e),
  })
}

export const usePostPatientStep2 = () => {
  return useMutation({
    mutationFn: postSecondPatientStep,
    onError: (e) => console.error(e),
  })
}

export const usePostPatientStep3 = () => {
  return useMutation({
    mutationFn: postThirdPatientStep,
    onError: (e) => console.error(e),
  })
}

export const usePostPatientStep4 = () => {
  return useMutation({
    mutationFn: postFourthPatientStep,
    onError: (e) => console.error(e),
  })
}

export const usePostPatient = () => {
  return useMutation({
    mutationFn: postPatient,
    onError: (e) => console.error(e),
  })
}

export const usePatchPatient = () => {
  return useMutation({
    mutationFn: patchPatient,
    onError: (e) => console.error(e),
  })
}

export const usePatchPatientStatus = () => {
  return useMutation({
    mutationFn: patchPatientStatus,
    onError: (e) => console.error(e),
  })
}

export const usePatchPatientBracket = () => {
  return useMutation({
    mutationFn: patchPatientBracket,
    onError: (e) => console.error(e),
  })
}

export const useDeletePatient = () => {
  return useMutation({
    mutationFn: deletePatient,
    onError: (e) => console.error(e),
  })
}
