import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { reportKeys } from './queryKey'
import { getDashboardCardReport, getSlackingCountReport, getPatientStatusListReport, getPatientStatusVisualReport, getCapacityReport } from './request'
import { Capacity, DashBoardCard, PatientStatusList, PatientStatusVisual } from './requestType'
import dayjs from 'dayjs'
import { CapacityResponse, PatientStatusListResponse } from './responseType'

export const useGetDashboardCardReport = (param: { req: DashBoardCard }) => {
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: reportKeys.dashboardCard,
    queryFn: () => getDashboardCardReport(param),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const useGetSlackingCountReport = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: reportKeys.slackingCount,
    queryFn: () => getSlackingCountReport(),
  })

  return { data, isPending, isError }
}

export const useGetPatientStatusListReport = (param: { req: PatientStatusList }) => {
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: reportKeys.patientStatus,
    queryFn: () => getPatientStatusListReport(param),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const useGetPatientStatusDetailReport = () => {
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: reportKeys.patientStatusDetail,
    queryFn: ({pageParam}) => getPatientStatusListReport(pageParam),
    initialPageParam: {
      req: {
        offsetDate: dayjs().format('YYYY-MM-DD')
      }
    },
    getNextPageParam: (lastPage: PatientStatusListResponse) => {
      if (!lastPage) {
        return undefined
      }
      const lastData = lastPage.data[lastPage.data.length - 1]
      if (!lastData) {
        return undefined
      }
      return {
        req: {
          offsetDate: dayjs(lastData.date).subtract(1, 'day').format('YYYY-MM-DD')
        }
      }
    }
  })

  return { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage }
}

export const useGetPatientStatusVisualReport = (param: { req: PatientStatusVisual, table?: boolean }) => {
  const isTable = !!param.table
  const { data, isPending, isError, isRefetching, isRefetchError } = useQuery({
    queryKey: isTable ? reportKeys.patientStatusTable : reportKeys.patientStatusGraph,
    queryFn: () => getPatientStatusVisualReport(param),
  })

  return { data, isPending, isError, isRefetching, isRefetchError }
}

export const useGetCapacityReport = (param: { req: Capacity }) => {
  const { data, isPending, isError } = useQuery({
    queryKey: reportKeys.capacity,
    queryFn: () => getCapacityReport(param),
  })

  return { data, isPending, isError }
}

export const useGetCapacityDetailReport = () => {
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: reportKeys.capacityDetail,
    queryFn: ({pageParam}) => getCapacityReport(pageParam),
    initialPageParam: {
      req: {
        offsetMonth: dayjs().format('YYYY-MM-01')
      }
    },
    getNextPageParam: (lastPage: CapacityResponse) => {
      if (!lastPage) {
        return undefined
      }
      const lastData = lastPage.data[lastPage.data.length - 1]
      if (!lastData) {
        return undefined
      }
      return {
        req: {
          offsetMonth: dayjs(lastData.month).subtract(1, 'month').format('YYYY-MM-01')
        }
      }
    }
  })

  return { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage }
}
