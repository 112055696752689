import React, { useState, useEffect } from 'react';
import { Typography, Box, FormControl, FormLabel, TextField, Stack, Button, Tabs, Tab } from '@mui/material';
import Layout from '../../components/Layout';
import PageBackButton from '../../components/PageBackButton';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../style/theme';
import { useParams } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import OperationProof from 'components/OperationProof';
import type { PatchAngleNameResponse }  from '../../api/setting/responseType';
import { useGetAngleNameSetting, usePatchAngleNameSetting } from 'api/setting/index'
import { ErrorModal, ErrorModalOnCloseProps, ErrorModalProps } from 'components/ErrorModal';
import { CompleteModal, CompleteModalOnCloseProps, CompleteModalProps } from 'components/CompleteModal';
import { AxiosError } from 'axios';
import useResetQuery from 'api/useResetQuery';
import { getAngleName } from 'types/PhotoObject';

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const SettingAngleName = () => {
  const urlParams = useParams<{clinicId: string | undefined}>();
  const clinicId = urlParams.clinicId ?? '';
  const { data: inAngleSettingData, isError: isAngleSettingError, isPending: isAngleSettingPending } = useGetAngleNameSetting();
  const { mutate: postMutate } = usePatchAngleNameSetting();
  const [completeModalConfig, setCompleteModalConfig] = useState<CompleteModalProps | undefined>();
  const [operationProofing, setOperationProofing] = useState(false);
  const [errorModalConfig, setErrorModalConfig] = useState<ErrorModalProps | undefined>();
  const resetQuery = useResetQuery();
  const [tabValue, setTabValue] = useState(0)
  const [angle20, setAngle20] = useState('')
  const [angle21, setAngle21] = useState('')
  const [angle22, setAngle22] = useState('')
  const [angle23, setAngle23] = useState('')
  const [angle24, setAngle24] = useState('')
  const [angle25, setAngle25] = useState('')
  const [angle26, setAngle26] = useState('')
  const [angle27, setAngle27] = useState('')
  const [angle28, setAngle28] = useState('')
  const [angle29, setAngle29] = useState('')
  const [angle50, setAngle50] = useState('')
  const [angle51, setAngle51] = useState('')
  const [angle52, setAngle52] = useState('')
  const [angle53, setAngle53] = useState('')
  const [angle54, setAngle54] = useState('')
  const [angle34, setAngle34] = useState('')
  const [angle35, setAngle35] = useState('')
  const [angle36, setAngle36] = useState('')
  const [angle37, setAngle37] = useState('')
  const [angle38, setAngle38] = useState('')
  const angleStates = [
    {code: 20, value: angle20, setter: setAngle20},
    {code: 21, value: angle21, setter: setAngle21},
    {code: 22, value: angle22, setter: setAngle22},
    {code: 23, value: angle23, setter: setAngle23},
    {code: 24, value: angle24, setter: setAngle24},
    {code: 25, value: angle25, setter: setAngle25},
    {code: 26, value: angle26, setter: setAngle26},
    {code: 27, value: angle27, setter: setAngle27},
    {code: 28, value: angle28, setter: setAngle28},
    {code: 29, value: angle29, setter: setAngle29},
    {code: 50, value: angle50, setter: setAngle50},
    {code: 51, value: angle51, setter: setAngle51},
    {code: 52, value: angle52, setter: setAngle52},
    {code: 53, value: angle53, setter: setAngle53},
    {code: 54, value: angle54, setter: setAngle54},
    {code: 34, value: angle34, setter: setAngle34},
    {code: 35, value: angle35, setter: setAngle35},
    {code: 36, value: angle36, setter: setAngle36},
    {code: 37, value: angle37, setter: setAngle37},
    {code: 38, value: angle38, setter: setAngle38},
  ]

  useEffect(() => {
    if (isAngleSettingError) {
      console.error(isAngleSettingError)
      return
    }
    if(!isAngleSettingPending && inAngleSettingData !== undefined){
      setAngle20(inAngleSettingData.angleNames.find(item => item.angle === 20)?.name ?? '')
      setAngle21(inAngleSettingData.angleNames.find(item => item.angle === 21)?.name ?? '')
      setAngle22(inAngleSettingData.angleNames.find(item => item.angle === 22)?.name ?? '')
      setAngle23(inAngleSettingData.angleNames.find(item => item.angle === 23)?.name ?? '')
      setAngle24(inAngleSettingData.angleNames.find(item => item.angle === 24)?.name ?? '')
      setAngle25(inAngleSettingData.angleNames.find(item => item.angle === 25)?.name ?? '')
      setAngle26(inAngleSettingData.angleNames.find(item => item.angle === 26)?.name ?? '')
      setAngle27(inAngleSettingData.angleNames.find(item => item.angle === 27)?.name ?? '')
      setAngle28(inAngleSettingData.angleNames.find(item => item.angle === 28)?.name ?? '')
      setAngle29(inAngleSettingData.angleNames.find(item => item.angle === 29)?.name ?? '')
      setAngle50(inAngleSettingData.angleNames.find(item => item.angle === 50)?.name ?? '')
      setAngle51(inAngleSettingData.angleNames.find(item => item.angle === 51)?.name ?? '')
      setAngle52(inAngleSettingData.angleNames.find(item => item.angle === 52)?.name ?? '')
      setAngle53(inAngleSettingData.angleNames.find(item => item.angle === 53)?.name ?? '')
      setAngle54(inAngleSettingData.angleNames.find(item => item.angle === 54)?.name ?? '')
      setAngle34(inAngleSettingData.angleNames.find(item => item.angle === 34)?.name ?? '')
      setAngle35(inAngleSettingData.angleNames.find(item => item.angle === 35)?.name ?? '')
      setAngle36(inAngleSettingData.angleNames.find(item => item.angle === 36)?.name ?? '')
      setAngle37(inAngleSettingData.angleNames.find(item => item.angle === 37)?.name ?? '')
      setAngle38(inAngleSettingData.angleNames.find(item => item.angle === 38)?.name ?? '')
    }  
  }, [isAngleSettingError, inAngleSettingData, isAngleSettingPending])

  if (isAngleSettingPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  const editableSubOralCodes = [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 50, 51, 52, 53, 54]
  const editableFaceCodes = [34, 35, 36, 37, 38]

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, code: number) => {
    const setter = angleStates.find(item => item.code === code)?.setter
    if (setter) {
      setter(event.target.value)
    }
  }

  const handleAngle = async () => {
    setOperationProofing(true)
    let reqData = {}
    editableSubOralCodes.forEach(code => {
      reqData = {
        ...reqData
        , [code]: angleStates.find(item => item.code === code)?.value
      }
    })
    editableFaceCodes.forEach(code => {
      reqData = {
        ...reqData
        , [code]: angleStates.find(item => item.code === code)?.value
      }
    })

    postMutate(
      {
        req: reqData
      },
      {
        onSuccess: async (response: PatchAngleNameResponse) => {
          setOperationProofing(false)
          if (response.status === 'OK') {
            await new Promise<CompleteModalOnCloseProps>((resolve) => {
              setCompleteModalConfig({
                onClose: resolve,
                title: `登録が完了しました。`
              })
            })
            setCompleteModalConfig(undefined)

            resetQuery(['angleNameSetting'])

          } else {
            await new Promise<ErrorModalOnCloseProps>((resolve) => {
              setErrorModalConfig({
                onClose: resolve,
                title: '登録に失敗しました。'
              })
            })
            setErrorModalConfig(undefined)
          }
        },
        onError: async (error: AxiosError) => {
          setOperationProofing(false)
          await new Promise<ErrorModalOnCloseProps>((resolve) => {
            setErrorModalConfig({
              onClose: resolve,
              title: '登録に失敗しました。'
            })
          })
          setErrorModalConfig(undefined)

        }
      }
    )
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={`/${clinicId}/setting`} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 4, mt: 1, mb: 4}}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SettingsIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>ラベルの設定</Typography>
            </Box>
            <Typography sx={{ mt: 2 }}>「その他」系統のラベルを変更できます。</Typography>
            <Button
                variant="outlined"
                onClick={handleAngle}
                sx={{ mt: 4, mb: 2, py: 0.5 , px: 2 ,background: theme.palette.primary.main , color: "white", borderRadius: "60px", fontWeight: "bold", width: "160px", fontSize: "14px", "&:hover": { backgroundColor: "white", color: theme.palette.primary.main } }}
              >
                すべて適用する
            </Button>
            <Tabs
              value={tabValue}
              centered
              variant = "fullWidth"
              onChange={handleTabChange}
              sx={{ borderBottom: "1px solid #F4F4F4" }}
            >
              <Tab label="その他" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
              <Tab label="顔" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Stack sx={{ rowGap: 4, mt: 2 }}>
                {
                  editableSubOralCodes.map(code => {
                    return (
                      <Box key={code}>
                        <FormControl>
                          <FormLabel sx={{ fontWeight: "bold", mb: 1 }}>{getAngleName(code)}</FormLabel>
                            <TextField
                              sx={{ background: "white", borderRadius: "8px", width: "320px" }}
                              placeholder="ラベル名を入力してください。"
                              value={angleStates.find(item => item.code === code)?.value ?? ''}
                              onChange={(e) => handleChange(e, code)}
                            />
                        </FormControl>
                      </Box>
                    )
                  })
                }
              </Stack>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Stack sx={{ rowGap: 4, mt: 2 }}>
                {
                  editableFaceCodes.map(code => {
                    return (
                      <Box key={code}>
                        <FormControl>
                          <FormLabel sx={{ fontWeight: "bold", mb: 1 }}>{getAngleName(code)}</FormLabel>
                            <TextField
                              sx={{ background: "white", borderRadius: "8px", width: "320px" }}
                              placeholder="ラベル名を入力してください。"
                              value={angleStates.find(item => item.code === code)?.value ?? ''}
                              onChange={(e) => handleChange(e, code)}
                            />
                        </FormControl>
                      </Box>
                    )
                  })
                }
              </Stack>
            </TabPanel>
            <Button
              variant="outlined"
              onClick={handleAngle}
              sx={{
                width: "160px", py: 0.5, px: 2, mt: 4,
                background: theme.palette.primary.main, color: "white",
                borderRadius: "60px", fontWeight: "bold", fontSize: "14px",
                "&:hover": { backgroundColor: "white", color: theme.palette.primary.main}
              }}
            >
              すべて適用する
            </Button>
          </Box>
          {completeModalConfig && <CompleteModal {...completeModalConfig} />}
          {errorModalConfig && <ErrorModal {...errorModalConfig} />}
          {operationProofing && <OperationProof initial={false} />}
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default SettingAngleName