import React from 'react'
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom'
import LoginPage from 'pages/login/LoginPage'
import PatientList from 'pages/list/PatientList'
import PatientDetail from 'pages/list/PatientDetail'
import Setting from 'pages/setting/Setting'
import SettingNewsList from 'pages/setting/SettingNewsList'
import SettingNewsListPost from 'pages/setting/SettingNewsListPost'
import SettingPrivacyPolicy from 'pages/setting/SettingPrivacyPolicy'
import SettingTermsOfService from 'pages/setting/SettingTermsOfService'
import SettingFlip from 'pages/setting/SettingFlip'
import SettingAngleName from 'pages/setting/SettingAngleName'
import SettingExport from 'pages/setting/SettingExport'
import AddPatient from 'pages/add-patient/AddPatient'
import EditPatient from 'pages/add-patient/EditPatient'
import UploadPhoto from 'pages/upload/UploadPhoto'
import ModifyPhoto from 'pages/upload/ModifyPhoto'
import Report from 'pages/report/Report'
import Dashboard from 'pages/dashboard/Dashboard'
import DashboardPaymentReport from 'pages/dashboard/DashboardPaymentReport'
import DashboardDayReport from 'pages/dashboard/DashboardDayReport'
import Page404 from 'pages/error/Page404'
import PrivateRoute from 'components/PrivateRoute'
import 'lib/amplify'
import { AxiosClientProvider } from 'lib/AxiosClientProvider'
import ClinicRoute from 'components/ClinicRoute'
import PrintMontage from 'pages/print/PrintMontage'
import PrintComparison from 'pages/print/PrintComparison'
import UnassignedPhotos from 'pages/unassigned/UnassignedPhotos'
import ExportList from 'pages/export/ExportList'
import SwapAngle from 'pages/list/SwapAngle'

const App = () => {
  return (
    <BrowserRouter>
      <AxiosClientProvider>
        <Routes>
          <Route path=':clinicId' element={<ClinicRoute><Outlet/></ClinicRoute>}>
            <Route path='login' element={<LoginPage/>} />
            <Route element={<PrivateRoute/>}>
              <Route path='patient' element={<PatientList/>} />
              <Route path='patient/:id' element={<PatientDetail/>} />
              <Route path='patient/:id/edit' element={<EditPatient/>} />
              <Route path='patient/:id/:examId/montage' element={<PrintMontage/>} />
              <Route path='patient/:id/comparison' element={<PrintComparison/>} />
              <Route path='setting' element={<Setting/>} />
              <Route path='setting/news' element={<SettingNewsList/>} />
              <Route path='setting/news/post/:id' element={<SettingNewsListPost/>} />
              <Route path='setting/privacy-policy' element={<SettingPrivacyPolicy/>} />
              <Route path='setting/terms-of-service' element={<SettingTermsOfService/>} />
              <Route path='setting/flip' element={<SettingFlip/>} />
              <Route path='setting/angle-name' element={<SettingAngleName/>} />
              <Route path='setting/export' element={<SettingExport/>} />
              <Route path='add-patient' element={<AddPatient/>} />
              <Route path='patient/upload/:id' element={<UploadPhoto/>} />
              <Route path='patient/upload/:id/:examId' element={<ModifyPhoto/>} />
              <Route path='patient/swap/:id/:examId' element={<SwapAngle/>} />
              <Route path='report' element={<Report/>} />
              <Route path='dashboard' element={<Dashboard/>} />
              <Route path='dashboard/payment' element={<DashboardPaymentReport/>} />
              <Route path='dashboard/day' element={<DashboardDayReport/>} />
              <Route path='unassigned' element={<UnassignedPhotos/>} />
              <Route path='export' element={<ExportList/>} />
              <Route path='*' element={<Page404/>} />
            </Route>
          </Route>
          <Route path="*" element={<Page404/>}></Route>
        </Routes>
      </AxiosClientProvider>
    </BrowserRouter>
  )
}

export default App
