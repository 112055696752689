import React from 'react'
import { Box, Typography, Stack, Button } from '@mui/material'
import Modal from '@mui/material/Modal'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import theme from '../style/theme'

export type ErrorModalOnCloseProps = {
  modalStatus: string
}

export type ErrorModalProps = {
  onClose: (CompleteModalOnCloseProps:{ modalStatus :string }) => void
  title?: string
}

export const ErrorModal = (props: ErrorModalProps) => {
  const { onClose, title } = props

  return (
    <>
      <Modal
        open onClose={() => onClose({modalStatus: 'OK'})}
      >
        <Box sx={{ p:4, width: 400, position: "absolute",top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", borderRadius: "8px"}}>
          <Stack sx={{ alignItems: "center" }} direction="column">
            <ErrorOutlineIcon sx={{ color: theme.palette.error.main, fontSize: "64px" }}/>
            <Typography sx={{ textAlign: "center", fontSize: "18px", mt: 1 }} fontWeight = "bold">{title}</Typography>
            <Button
              onClick={() => onClose({modalStatus: 'OK'})}
              sx={{ textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 8, fontWeight: "bold", mt: 3, "&:hover": { background: "#11867F" } }}>
                確認しました
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
