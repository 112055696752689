export type ImageType = {
  key: number
  url: string
  title: string
  code: string
}

interface NumberToString {
  [key: number]: string
}

export const sexValues = [
  {
    key: 0,
    title: '女'
  },
  {
    key: 1,
    title: '男'
  },
  {
    key: 2,
    title: 'その他'
  },
]

export const patientStatus: NumberToString = {
  0: '初診',
  1: '検査',
  2: '診断',
  3: '動的処置',
  4: '保定期間',
  5: '治療終了',
}

export const patientAge: NumberToString = {
  0: '0歳から10歳まで',
  10: '10歳から20歳まで',
  20: '20歳から30歳まで',
  30: '30歳から40歳まで',
  40: '40歳から50歳まで',
  50: '50歳から60歳まで',
  60: '60歳から70歳まで',
  70: '70歳から80歳まで',
  80: '80歳から90歳まで',
  90: '90歳から100歳まで',
}

export const skeletalImages: ImageType[] = [
  {
    key: 1,
    url: '/images/skeletal_1.png',
    title: '下顎後退',
    code: 'ClassⅡ',
  },
  {
    key: 0,
    url: '/images/skeletal_0.png',
    title: '標準',
    code: 'ClassI',
  },
  {
    key: 2,
    url: '/images/skeletal_2.png',
    title: '下顎前突',
    code: 'ClassⅢ',
  },
  {
    key: 99,
    url: '',
    title: '該当なし',
    code: 'ー',
  },
]

export const faceTypeImages: ImageType[] = [
  {
    key: 1,
    url: '/images/face_type_1.png',
    title: '長顔',
    code: 'long',
  },
  {
    key: 0,
    url: '/images/face_type_0.png',
    title: '標準',
    code: 'normal',
  },
  {
    key: 2,
    url: '/images/face_type_2.png',
    title: '短顔',
    code: 'short',
  },
  {
    key: 99,
    url: '',
    title: '該当なし',
    code: 'ー',
  },
]

export const angleRightImages: ImageType[] = [
  {
    key: 0,
    url: '/images/angle_right_0.png',
    title: '1級',
    code: 'ClassI',
  },
  {
    key: 1,
    url: '/images/angle_right_1.png',
    title: '2級1類',
    code: 'ClassⅡ-1',
  },
  {
    key: 2,
    url: '/images/angle_right_2.png',
    title: '2級2類',
    code: 'ClassⅡ-2',
  },
  {
    key: 3,
    url: '/images/angle_right_3.png',
    title: '3級',
    code: 'ClassⅢ',
  },
  {
    key: 99,
    url: '',
    title: '該当なし',
    code: 'ー',
  },
]

export const angleLeftImages: ImageType[] = [
  {
    key: 0,
    url: '/images/angle_left_0.png',
    title: '1級',
    code: 'ClassI',
  },
  {
    key: 1,
    url: '/images/angle_left_1.png',
    title: '2級1類',
    code: 'ClassⅡ-1',
  },
  {
    key: 2,
    url: '/images/angle_left_2.png',
    title: '2級2類',
    code: 'ClassⅡ-2',
  },
  {
    key: 3,
    url: '/images/angle_left_3.png',
    title: '3級',
    code: 'ClassⅢ',
  },
  {
    key: 99,
    url: '',
    title: '該当なし',
    code: 'ー',
  },
]

export const otherElementImages: ImageType[] = [
  {
    key: 0,
    url: '/images/other_element_0.png',
    title: '叢生',
    code: '叢生',
  },
  {
    key: 1,
    url: '/images/other_element_1.png',
    title: 'オープンバイト',
    code: 'オープンバイト',
  },
  {
    key: 2,
    url: '/images/other_element_2.png',
    title: '交叉咬合',
    code: '交叉咬合',
  },
  {
    key: 3,
    url: '/images/other_element_3.png',
    title: '過蓋咬合',
    code: '過蓋咬合',
  },
  {
    key: 4,
    url: '/images/other_element_4.png',
    title: '空隙歯列',
    code: '空隙歯列',
  },
  {
    key: 5,
    url: '/images/other_element_5.png',
    title: '正中の不正一致',
    code: '正中の不正一致',
  },
  {
    key: 6,
    url: '/images/other_element_6.png',
    title: '上顎前突',
    code: '上顎前突',
  },
  {
    key: 7,
    url: '/images/other_element_7.png',
    title: '反対咬合',
    code: '反対咬合',
  },
  {
    key: 8,
    url: '/images/other_element_8.png',
    title: '上下顎前突',
    code: '上下顎前突',
  },
]
