import React, { useState } from 'react'
import { Typography, Box, Stack, FormControl, FormLabel, TextField } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'style/theme'
import CircularProgressWithLabel from './CircularProgressWithLabel'

export type Patient01Props = {
  show: boolean
  handleChange: Function
  patientNo: string
  patientNoError: string[]
}

const Patient01 = (props: Patient01Props) => {
  const { show, handleChange, patientNo, patientNoError } = props
  const [progress] = useState(20)

  const display = show ? "block" : "none"

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: display }}>
          <Stack sx={{ display: "flex", alignItems: "center", columnGap: "24px" }} direction = "row">
            <Box sx={{ mt: 3, position: 'relative' }}>
              <Box
                sx={{ 
                  margin: "auto",
                  width: "54px",
                  height: "54px",
                  position: 'absolute',
                  border: "solid #D8EAE9 5px",
                  borderRadius: "50%",
                  top: 0,
                  left: 0,
                }}>
              </Box>
              <CircularProgressWithLabel progress={progress} page={1} />
            </Box>
            <Box><Typography sx={{ fontWeight: "600", backgroundColor: "#F1F1F1", borderRadius: "8px", py: 2, px: 4, mt: 2 }} color="#364F4E">患者番号を追加してください。</Typography></Box>
          </Stack>
          <Stack sx={{ mt: 3, ml: 8 }}>
            <FormControl>
              <Stack sx={{ display: "flex", alignItems: "center", columnGap: "16px" }} direction = "row">
                <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "16px" }}>患者番号</FormLabel>
              </Stack>
              <Typography sx={{ color: 'gray', fontSize: "12px", mt: 0.5 }}>半角英数字、ハイフン(-)、アンダーバー(_)、ドット(.)が利用可能です。</Typography>
              <Typography sx={{ color: 'gray', fontSize: "12px"}}>8文字まで入力可能です。</Typography>
              <TextField
                variant="outlined" placeholder="患者番号を入力してください。" value={patientNo}
                sx={{ background: "white",borderRadius: "8px", width: "440px", mt: 1 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'patientNo')}
                error={patientNoError.length > 0}
                inputProps={{maxLength: 8}}
              />
              {patientNoError.map((error, index) => {
                return (
                  <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
                )
              })}
            </FormControl>
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Patient01
