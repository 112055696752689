import Page404 from 'pages/error/Page404'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'

// UUID形式を検証するユーティリティ関数
const isValidUUID = (uuid: string): boolean => {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  return uuidRegex.test(uuid)
}

// カスタムルートガードコンポーネント
interface ClinicRouteGuardProps {
  children: ReactNode
}

const ClinicRoute: React.FC<ClinicRouteGuardProps> = ({ children }) => {
  const { clinicId } = useParams<{ clinicId: string }>()

  if (!clinicId || !isValidUUID(clinicId)) {
    return <Page404/>
  }

  return <>{children}</>
}

export default ClinicRoute
