import { Patient } from 'api/patient/responseType'
import { skeletalImages, faceTypeImages, angleRightImages, angleLeftImages, otherElementImages, ImageType } from 'types/PatientObjects'
import dayjs from 'dayjs'

export const otherElementSplit = (other_element: string) => {
  const string_array = other_element !== '' ? other_element.split(',') : []
  return string_array.map((item) => parseInt(item))
}

export const generatePatientStates = (items: Patient | null) => {
  const skeletal = skeletalImages.find((item) => item.key === (items?.skeletal ?? 99))?.code
  const faceType = faceTypeImages.find((item) => item.key === (items?.faceType ?? 99))?.code
  const angleRight = angleRightImages.find((item) => item.key === (items?.angleRight ?? 99))?.code
  const angleLeft = angleLeftImages.find((item) => item.key === (items?.angleLeft ?? 99))?.code
  const otherElement = otherElementImages.filter((item) => {
    return otherElementSplit(items?.otherElement ?? '').includes(item.key)
  }).map((item) => {
    return `${item.code}`
  })
  return renderState(skeletal, faceType, angleRight, angleLeft, otherElement)
}

export const generatePatientStatesIndividual = (
  renderSkeletal: ImageType | undefined, renderFaceType: ImageType | undefined,
  renderAngleRight: ImageType | undefined, renderAngleLeft: ImageType | undefined,
  renderOtherElement: ImageType[]
) => {
  const skeletal = renderSkeletal?.code
  const faceType = renderFaceType?.code
  const angleRight = renderAngleRight?.code
  const angleLeft = renderAngleLeft?.code
  const otherElement = renderOtherElement.map((item) => {
    return `${item.code}`
  })
  return renderState(skeletal, faceType, angleRight, angleLeft, otherElement)
}

const renderState = (skeletal?: string, faceType?: string, angleRight?: string, angleLeft?: string, otherElement?: string[]) => {
  const comma = otherElement?.length !== 0 ? ', ' : ''
  return `Skeletal:${skeletal}, Face:${faceType}, Angle:R/${angleRight} L/${angleLeft}${comma}${otherElement}`
}

export const calculateThatTimeAge = (birthday?: string, examinationDate?: string) => {
  if (!birthday) {
    return ''
  }
  const diffYear = dayjs(examinationDate).diff(dayjs(birthday), 'year')
  const diffMonth = dayjs(examinationDate).diff(dayjs(birthday), 'month') % 12
  return `${diffYear}歳${diffMonth}ヶ月`
}