import { QueryClient } from '@tanstack/react-query'

// refs: https://zenn.dev/brachio_takumi/articles/20210226-react-query#defaultoptions-%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%81%B9%E3%81%97
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  },
})
