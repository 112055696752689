import React, { useEffect, useState } from 'react'
import { Box, Typography, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CircleIcon from '@mui/icons-material/Circle'
import theme from '../../style/theme'
import { patchPatientStatus } from '../../api/patient/request'
import { ConfirmDateModal, ConfirmDateModalProps } from '../../components/ConfirmDateModal'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import useResetQuery from 'api/useResetQuery'

interface NumberToString {
  [key: number]: string
}

type PatientStatusBarProps = {
  patientId: number
  status: number
  fmeDate: string
  insDate: string
  diaDate: string
  atDate: string
  retDate: string
  cmpDate: string
  detailMode?: boolean
  setStatus: Function
}

export type ModalOnCloseProps = {
  modalStatus: string
  confirmDate: string
}

export type ConfirmModalOnCloseProps = {
  modalStatus: string
}

const PatientStatusBar = (props : PatientStatusBarProps) => {
  const {
    patientId, status, fmeDate, insDate, diaDate, atDate, retDate, cmpDate,
    detailMode = false, setStatus
  } = props
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()
  const [renderStatus, setRenderStatus] = useState<number>(status)
  const [renderFmeDate, setRenderFmeDate] = useState<string>(fmeDate)
  const [renderInsDate, setRenderInsDate] = useState<string>(insDate)
  const [renderDiaDate, setRenderDiaDate] = useState<string>(diaDate)
  const [renderAtDate, setRenderAtDate] = useState<string>(atDate)
  const [renderRetDate, setRenderRetDate] = useState<string>(retDate)
  const [renderCmpDate, setRenderCmpDate] = useState<string>(cmpDate)
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarFlag, setSnackBarFlag] = useState('')
  const [confirmDateModalConfig, setConfirmDateModalConfig] = useState<ConfirmDateModalProps | undefined>()
  const resetQuery = useResetQuery()

  useEffect(() => {
    setRenderStatus(status)
    if (fmeDate) {
      setRenderFmeDate(fmeDate)
    }
    if (insDate) {
      setRenderInsDate(insDate)
    }
    if (diaDate) {
      setRenderDiaDate(diaDate)
    }
    if (atDate) {
      setRenderAtDate(atDate)
    }
    if (retDate) {
      setRenderRetDate(retDate)
    }
    if (cmpDate) {
      setRenderCmpDate(cmpDate)
    }
  },[status, fmeDate, insDate, diaDate, atDate, retDate, cmpDate])

  const handleSnackClose = () => {
    setSnackBarOpen(false)
  }

  const calculateElapsedDays = (date: string | undefined) => {
    if (!date) {
      return ''
    }

    const converted = dayjs(date)
    const diffDays = dayjs().diff(converted, 'day')

    let count = 1
    let calcDays = diffDays
    let calcMonth = 0
    let calcYear = 0
    while (true) {
      const dates = dayjs().subtract(count, 'month').daysInMonth()
      if (calcDays - dates < 0) {
        break
      }
      calcDays -= dates
      calcMonth++
      if (calcMonth === 12) {
        calcMonth = 0
        calcYear++
      }
      count++
    }

    const outputs = []
    if (calcMonth === 0 && calcYear === 0) {
      outputs.push(`${calcDays}日`)
    } else if (calcYear > 0) {
      outputs.push(`${calcYear}年`)
      outputs.push(`${calcMonth}ヶ月`)
    } else {
      outputs.push(`${calcMonth}ヶ月`)
      if (calcDays > 0) {
        outputs.push(`${calcDays}日`)
      }
    }
    
    return outputs.join('')
  }

  const patientStatus: NumberToString = {
    0: '初診',
    1: '検査',
    2: '診断',
    3: '動的処置',
    4: '保定期間',
    5: '治療終了',
  }

  const changeStatusDate = (status: number, date: string) => {
    switch (status) {
      case 0:
        setRenderFmeDate(date)
        break
      case 1:
        setRenderInsDate(date)
        break
      case 2:
        setRenderDiaDate(date)
        break
      case 3:
        setRenderAtDate(date)
        break
      case 4:
        setRenderRetDate(date)
        break
      case 5:
        setRenderCmpDate(date)
        break
      default:
        break
    }
  }

  const handlePatient = async (newStatus :number) => {
    const ret = await new Promise<ModalOnCloseProps>((resolve) => {
      setConfirmDateModalConfig({
        onClose: resolve,
        title: `保存する${patientStatus[newStatus]}日を選択してください。`
      })
    })
    setConfirmDateModalConfig(undefined)
    if (ret.modalStatus === 'ok') {
      const res = await patchPatientStatus({ id: patientId,  req: {
        status: newStatus,
        statusDate: ret.confirmDate
      }})
      if (res.status === 'OK') {
        if (detailMode) {
          resetQuery(['patientList'])
        }
        setRenderStatus(newStatus)
        setStatus(newStatus)
        changeStatusDate(newStatus, ret.confirmDate)
        setSnackBarOpen(true)
        setSnackBarMessage('変更しました')
        setSnackBarFlag('success')
      } else {
        setSnackBarOpen(true)
        setSnackBarMessage('エラー 422')
        setSnackBarFlag('error')
      }
    } else if (ret.modalStatus === 'cancel') {
      return
    }
  }

  const containerWidth = detailMode ? "calc(100% - 40px)" : "300px"
  const unitSx = detailMode ? {width: "calc(100% / 6)"} : {width: "60px"}

  return (
    <>
      <Box sx={{ flex: 7, p: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "spece-between", position: "relative" }}>
          <Box sx={{ width: containerWidth, height: "2px", background:"#D9D9D9", position: "absolute", top:"50%", left:"20px" }}></Box>
          <Stack sx={unitSx}>
            <Typography sx={{ textAlign: "center",fontWight: "600", color: renderStatus === 0 ? "#F44498" : "#808080", fontSize: "12px" }}>
              初診
            </Typography>
            <IconButton sx={{ p: 0 , "&:hover": { backgroundColor: "transparent" }} } onClick={() => handlePatient(0)}>
              <CircleIcon sx={{ background: renderStatus === 0 ? "#F44498" : "#D9D9D9" ,color: renderStatus === 0 ? "#F44498" : "#D9D9D9", width: "32px",height: "32px", borderRadius: "50%" }} />
            </IconButton>
            <Typography sx={{ textAlign: "center", color: theme.palette.text.primary, fontSize: "10px" }}>
              {renderFmeDate ?? ''}
            </Typography>
          </Stack>
          <Stack sx={unitSx}>
            <Typography sx={{ textAlign: "center",fontWight: "600", color: renderStatus === 1 ? "#F44498" : "#808080", fontSize: "12px" }}>
              検査
            </Typography>
            <IconButton sx={{ p: 0 , "&:hover": { backgroundColor: "transparent" }} } onClick={() => handlePatient(1)}>
              <CircleIcon sx={{ background: renderStatus === 1 ? "#F44498" : "#D9D9D9",color: renderStatus === 1 ? "#F44498" : "#D9D9D9", width: "32px",height: "32px", borderRadius: "50%" }} />
            </IconButton>
            <Typography sx={{ textAlign: "center", color: theme.palette.text.primary, fontSize: "10px" }}>
              {renderStatus === 1 ? calculateElapsedDays(renderInsDate) : ''}
            </Typography>
          </Stack>
          <Stack sx={unitSx}>
            <Typography sx={{ textAlign: "center",fontWight: "600", color: renderStatus === 2 ? "#F44498" : "#808080", fontSize: "12px" }}>
              診断
            </Typography>
            <IconButton sx={{ p: 0 , "&:hover": { backgroundColor: "transparent" }} } onClick={() => handlePatient(2)}>
              <CircleIcon sx={{ background: renderStatus === 2 ? "#F44498" : "#D9D9D9",color: renderStatus === 2 ? "#F44498" : "#D9D9D9", width: "32px",height: "32px", borderRadius: "50%" }} />
            </IconButton>
            <Typography sx={{ textAlign: "center", color: theme.palette.text.primary, fontSize: "10px" }}>
              {renderStatus === 2 ? calculateElapsedDays(renderDiaDate) : ''}
            </Typography>
          </Stack>
          <Stack sx={unitSx}>
            <Typography sx={{ textAlign: "center",fontWight: "600", color: renderStatus === 3 ? "#F44498" : "#808080", fontSize: "12px" }}>
              動的処置
            </Typography>
            <IconButton sx={{ p: 0 , "&:hover": { backgroundColor: "transparent" }} } onClick={() => handlePatient(3)}>
              <CircleIcon sx={{ background: renderStatus === 3 ? "#F44498" : "#D9D9D9",color: renderStatus === 3 ? "#F44498" : "#D9D9D9", width: "32px",height: "32px", borderRadius: "50%" }} />
            </IconButton>
            <Typography sx={{ textAlign: "center", color: theme.palette.text.primary, fontSize: "10px" }}>
              {renderStatus === 3 ? calculateElapsedDays(renderAtDate) : ''}
            </Typography>
          </Stack>
          <Stack sx={unitSx}>
            <Typography sx={{ textAlign: "center",fontWight: "600", color: renderStatus === 4 ? "#F44498" : "#808080", fontSize: "12px" }}>
              保定期間
            </Typography>
            <IconButton sx={{ p: 0 , "&:hover": { backgroundColor: "transparent" }} } onClick={() => handlePatient(4)}>
              <CircleIcon sx={{ background: renderStatus === 4 ? "#F44498" : "#D9D9D9",color: renderStatus === 4 ? "#F44498" : "#D9D9D9", width: "32px",height: "32px", borderRadius: "50%" }} />
            </IconButton>
            <Typography sx={{ textAlign: "center", color: theme.palette.text.primary, fontSize: "10px" }}>
              {renderStatus === 4 ? calculateElapsedDays(renderRetDate) : ''}
            </Typography>
          </Stack>
          <Stack sx={unitSx}>
            <Typography sx={{ textAlign: "center",fontWight: "600", color: renderStatus === 5 ? "#F44498" : "#808080", fontSize: "12px" }}>
              治療終了
            </Typography>
            <IconButton sx={{ p: 0 , "&:hover": { backgroundColor: "transparent" }} } onClick={() => handlePatient(5)}>
              <CircleIcon sx={{ background: renderStatus === 5 ? "#F44498" : "#D9D9D9",color: renderStatus === 5 ? "#F44498" : "#D9D9D9", width: "32px",height: "32px", borderRadius: "50%" }} />
            </IconButton>
            <Typography sx={{ textAlign: "center", color: theme.palette.text.primary, fontSize: "10px" }}>
              {renderStatus === 5 ? renderCmpDate ?? '' : ''}
            </Typography>
          </Stack>
        </Box>
      </Box>
      {confirmDateModalConfig && <ConfirmDateModal {...confirmDateModalConfig} />}
      <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
          }}
          open={snackBarOpen}
          onClose={handleSnackClose}
      >
        <Alert
          severity={snackBarFlag === 'error' ? 'error' : 'success'}
          sx={{ width: '100%' }}
          variant="filled"
        >{snackBarMessage}</Alert>
      </Snackbar>
    </>
  )
}
export default PatientStatusBar
