import React from 'react';
import { Typography, Box } from '@mui/material';
import Layout from '../../components/Layout';
import PageBackButton from '../../components/PageBackButton';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../style/theme';
import { useParams } from 'react-router-dom';

const SettingPrivacyPolicy = () => {
  const urlParams = useParams<{clinicId: string | undefined}>();
  const clinicId = urlParams.clinicId ?? '';

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={`/${clinicId}/setting`} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 4, mt: 1, mb: 4}}>
            <Box sx={{ mt: 3, px: 4 }}>     
              <Typography sx={{ fontWeight: "600", fontSize: "24px", color: theme.palette.text.primary, textAlign: "center" }}>
                プライバシーポリシー
              </Typography>
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 6}}>
                本プライバシーポリシーは,asparagus株式会社(以下,"当社"といいます)が取り扱う,個人としてのお客様を直接的または間接的に識別できるすべての情報(以下"個人情報"といいます)について適用されます。
              </Typography>
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                当社ではお客様から個人情報をお預かりするにあたり,プライバシーを大切に保護することを企業の重要な社会的責務と考え,役員はじめ従業員がお客様の個人情報の適切な取り扱いに努めています。
              </Typography>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                1.個人情報の取得
              </Typography>              
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                当社は,以下の場合に個人情報をお預かりします。<br/>
                (1) 端末操作を通じてお客様にご入力いただく場合<br/>
                (2) お客様から直接または書面等の媒体を通じてご提供いただく場合<br/>
                (3) お客様によるサービス,商品,広告,コンテンツ(以下これらをまとめて"サービス等"といいます)の利用,閲覧に伴い自動的に送信される場合<br/>
                (4) 医療機関,研究機関が取得し,当社に管理を委託した医療従事者,患者,被験者などの個人情報<br/>
                (5) 上記の他,お客様の同意を得た第三者から提供を受ける場合など,適法に取得する場合
              </Typography>                            
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                2.個人情報の利用目的
              </Typography>              
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                当社は,以下のことを行うため個人情報を利用します。<br/>
                (1) お客様に適したサービス等をご提供するため<br/>
                (2) お客様からのお問い合わせに対応するため<br/>
                (3) 商品の配送,代金請求,などをするため<br/>
                (4) お客様にサービス等に関するお知らせをするため<br/>
                (5) サービス等を安全にご提供するため<br/>
                (6) サービス等の改善および新たなサービス等を検討するため<br/>
                (7) サービス等のご利用状況等を調査,分析するため
              </Typography>  
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                3.個人情報の開示に関する免責
              </Typography>              
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                当社は,本プライバシーポリシーに掲げる場合及び個人情報保護法その他の法令により認められる場合を除き,お客様の同意を得ることなく個人情報を第三者に開示することはありません.但し,次の各号のいずれかに該当する場合は,この限りではありません。<br/>
                (1) 個人情報法保護法,およびその他法令の定める範囲内での利用<br/>
                (2) 法律,法的手続,訴訟,公的もしくは政府機関からの要求により,当社がお客様の個人情報を開示することが必要である場合<br/>
                (3) 人命や財産などに対し差し迫った危険があり,緊急を要する場合<br/>
                (4) 統計的なデータなど特定の個人を識別することができない状態で開示,提供する場合
              </Typography>  
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                4.個人情報の保存について
              </Typography>              
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                当社は,本プライバシーポリシーに定める目的を達成するため必要な期間中,お客様の個人情報を保存します。<br/>係る期間の決定にあたっては,個人情報を収集する必要性を検討し,実際に必要があると判断した場合は,法律により長期間の保存が要求されない限り,収集の目的を達成するのに必要な最小限の期間のみ情報を保存します。<br/>個人情報の取り扱い,削除要請などのお問い合わせにつきましては,当社のサポートまでご連絡ください。
              </Typography>   
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                当社は,プライバシーポリシーを随時更新します。<br/>プライバシーポリシーの重要な変更は,最新のプライバシーポリシーとともに当社のウェブサイトに掲示します。
              </Typography>   
              <Typography sx={{ fontSize: "16px", color: theme.palette.text.primary, lineHeight: "20px", pt: 4 }}>
                2024年7月1日<br/>asparagus株式会社
              </Typography>                                            
            </Box>
          </Box>
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default SettingPrivacyPolicy;
