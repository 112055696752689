import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import Layout from '../../components/Layout'
import { Typography, Box, Stack, Button } from '@mui/material'
import PageBackButton from '../../components/PageBackButton'
import ListItem from '@mui/material/ListItem'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../style/theme'
import { useParams } from "react-router-dom"
import { useGetPatient } from '../../api/patient'
import type { Patient }  from '../../api/patient/responseType'
import { useGetPhotoListByExamResponse } from '../../api/photo'
import type { Photo }  from '../../api/photo/responseType'
import { getImageUrl } from 'lib/getImagePath'
import OperationProof from 'components/OperationProof'
import { useLocation } from "react-router-dom"
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import Tab from "@mui/material/Tab"
import PhotoFrame from 'components/PhotoFrame'
import ReactToPrint from 'react-to-print'
import { calculateThatTimeAge } from 'lib/patientService'
import { defaultAffine, getAlterPath, getAngleName } from 'types/PhotoObject'
import AvatarRender from 'components/AvatarRender'
import { useGetAngleNameSetting } from 'api/setting'
import { AngleName } from 'api/setting/responseType'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

type PrintMontageProps = {
  id: string
  clinicId: string
  examId: string
}

const frameWidth = 320
const frameHeight = 240

const frameLargeWidth = 352
const frameLargeHeight = 264

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  )
}

const PrintMontage = () => {
  const [value, setValue] = useState(0)
  const urlParams = useParams<PrintMontageProps>()
  const id = urlParams.id ? parseInt(urlParams.id) : 0
  const clinicId = urlParams.clinicId ?? ''
  const examId = urlParams.examId ? parseInt(urlParams.examId) : 0
  const loc = useLocation()
  const printRef = useRef(null)
  const [ items, setItems ] = useState<Patient | null>(null)
  const [ photos, setPhotos ] = useState<Photo[]>([])
  const [ examinationDate, setExaminationDate ] = useState<string>('')
  const {
    data: patientData, isError: isPatientError, isPending: isPatientPending,
    isRefetching: isPatientRefetchPending, isRefetchError: isPatientRefetchError
  } = useGetPatient(id)
  const {
    data: photoData, isError: isPhotoError, isPending: isPhotoPending,
    isRefetchError: isPhotoRefetchError, isRefetching: isPhotoRefetchPending
  } = useGetPhotoListByExamResponse(id, examId)
  const {
    data: angleNameSettingData, isError: isAngleNameSettingError, isPending: isAngleNameSettingPending
  } = useGetAngleNameSetting()
  const [ angleNameSetting, setAngleNameSetting ] = useState<AngleName[]>([])
  const [ headerClass, setHeaderClass ] = useState('hide');

  const getImagePath = useCallback(() => {
    const path = patientData?.patient.face

    if (path === undefined || !path ) {
      return ''
    }

    return getImageUrl(path)
  }, [patientData])

  const getFaceAffine = useCallback(() => {
    const affine = patientData?.patient.affine

    if (affine === undefined || !affine) {
      return defaultAffine
    }

    return affine
  }, [patientData])

  const imagePath = getImagePath()
  const affine = getFaceAffine()

  const previosPagePath = useMemo(() => {
    return loc.state ?? `/${clinicId}/patient/${id}`
  }, [clinicId, loc.state, id])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    if (newValue === 1 || newValue === 3) {
      setHeaderClass('printArea showPrint')
    } else {
      setHeaderClass('hide')
    }
  }

  const imageGetter = (angle: number) => {
    return photos.find((photo:Photo) => photo.angle === angle)
  }

  useEffect(() => {
    if (isPatientError || isPhotoError || isPatientRefetchError || isPhotoRefetchError || isAngleNameSettingError) {
      console.error(isPatientError)
      console.error(isPhotoError)
      console.error(isPatientRefetchError)
      console.error(isPhotoRefetchError)
      console.error(isAngleNameSettingError)
      return
    }
    if(!isPatientPending && !isPatientRefetchPending && patientData !== undefined){
      setItems(patientData.patient)
    }
    if(!isPhotoPending && !isPhotoRefetchPending && photoData !== undefined){
      setExaminationDate(photoData.examinationDate)
      setPhotos(photoData.photos)
    }
    if (!isAngleNameSettingPending && angleNameSettingData !== undefined) {
      setAngleNameSetting(angleNameSettingData.angleNames)
    }
  }, [
    isPatientError,
    patientData,
    isPatientPending,
    isPhotoError,
    photoData,
    isPhotoPending,
    isPatientRefetchPending,
    isPatientRefetchError,
    isPhotoRefetchError,
    isPhotoRefetchPending,
    angleNameSettingData,
    isAngleNameSettingError,
    isAngleNameSettingPending,
  ])

  if (isPatientPending || isPatientRefetchPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  const getSettingAngleName = (angle: number) => {
    const setting = angleNameSetting.find(item => item.angle === angle)
    const defaultName = getAngleName(angle)
    return setting ? setting.name : defaultName
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={previosPagePath} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1, mb: 4 }} ref={printRef}>
            <Stack sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} direction = "row">
              <ListItem alignItems="center" sx={{ display: "flex", columnGap: "24px", p: 0 }} className="hidePrint">
                <AvatarRender imagePath={imagePath} affine={affine} frameWidth={64} frameHeight={64} />
                <Box>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: theme.palette.text.primary }}>
                    {items?.kana}
                  </Typography>
                  <Typography sx={{ fontSize: "24px", fontWeight: "bold", color: theme.palette.text.primary }}>
                    {items?.name}
                  </Typography>
                </Box>
              </ListItem>
              <Stack sx={{ display: "flex", columnGap: "16px" }} direction = "row" className="hidePrint">
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="outlined"
                      sx={{
                        py: 0.5, px: 2, width: "160px", 
                        background: theme.palette.primary.main, color: "white",
                        borderRadius: "60px", fontWeight: "bold", fontSize: "14px",
                        "&:hover": { backgroundColor: "white", color: theme.palette.primary.main },
                        "&:disabled": { backgroundColor: "white", color: theme.palette.secondary.dark },
                      }}
                    >
                      PDFで出力
                    </Button> 
                  )}
                  content={() => printRef.current}
                />                                                                                                               
              </Stack>
              <Stack maxWidth={2/3} sx={{ p: 0, mb: 2, justifyContent: "space-between", alignItems: "flex-start" }} direction="row" className={headerClass}>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)", tableLayout: "fixed" }}>
                  <TableBody sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)", borderRight: "1px solid rgba(224, 224, 224, 1)", borderLeft: "1px solid rgba(224, 224, 224, 1)"}} >
                      <TableRow>
                        <TableCell width={40} sx={{ p: 0, background: "#F4F4F4", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                          <Typography sx={{ pl: 1, fontSize: "12px", fontWeight: "bold" }}>
                            名前
                          </Typography>
                        </TableCell>
                        <TableCell align="left" width={140} sx={{p: 0, borderRight: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px"}}>
                          <Typography sx={{ pl: 1, fontSize: "10px", fontWeight: "bold", color: theme.palette.text.primary }}>
                            {items?.kana}
                          </Typography>
                          <Typography sx={{ pl: 1, fontSize: "12px", fontWeight: "bold", color: theme.palette.text.primary }}>
                            {items?.name}
                          </Typography>
                        </TableCell>                        
                        <TableCell width={72} sx={{ p: 0, background: "#F4F4F4", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                          <Typography sx={{ pl: 1, fontSize: "12px", fontWeight: "bold" }}>
                            患者番号
                          </Typography>
                        </TableCell>
                        <TableCell align="left" width={100} sx={{p: 0, borderRight: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px"}}>
                          <Typography sx={{ pl: 1 }}>
                            {items?.patientNo ?? '-'}
                          </Typography>
                        </TableCell>                        
                        <TableCell width={72} sx={{ p: 0, background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)"}}>
                          <Typography sx={{ pl: 1, fontSize: "12px", fontWeight: "bold" }}>
                            撮影日
                          </Typography>
                        </TableCell>
                        <TableCell align="left" width={110} sx={{p: 0, borderRight: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px"}}>
                          <Typography sx={{ pl: 1 }}>
                            {examinationDate}
                          </Typography>
                        </TableCell>
                        <TableCell width={88} sx={{ p: 0, background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)"}}>
                          <Typography sx={{ pl: 1, fontSize: "12px", fontWeight: "bold" }}>
                            当時の年齢
                          </Typography>
                        </TableCell>
                        <TableCell align="left" width={100} sx={{ p: 0 }}>
                          <Typography sx={{ pl: 1 }}>
                            {calculateThatTimeAge(items?.birthday, examinationDate)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </Stack> 
            </Stack>
            <Stack sx={{ p: 0, mt: 2 , justifyContent: "space-between", alignItems: "flex-start" }} direction="row" className="hidePrint">
              <Table sx={{ brder: "1px solid rgba(224, 224, 224, 1)", width:"44%" }}>
                <TableBody sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)", borderRight: "1px solid rgba(224, 224, 224, 1)", borderLeft: "1px solid rgba(224, 224, 224, 1)"}} >
                    <TableRow>
                      <TableCell sx={{ width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)", }}>
                        患者番号
                      </TableCell>
                      <TableCell align="left">{items?.patientNo ?? '-'}</TableCell>
                    </TableRow> 
                    <TableRow>
                      <TableCell sx={{ width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                        撮影日
                      </TableCell>
                      <TableCell align="left">{examinationDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                        当時の年齢
                      </TableCell>
                      <TableCell align="left">{calculateThatTimeAge(items?.birthday, examinationDate)}</TableCell>
                    </TableRow>                                       
                </TableBody>
              </Table>
            </Stack>    
            <Box sx={{ width: '100%'}}>
              <Tabs
                value={value}
                centered
                variant = "fullWidth"
                onChange={handleChange}
                sx={{ borderBottom: "1px solid #F4F4F4", mt: 1, mb: 3 }}
                className="hidePrint"
              >
                <Tab label="5枚法" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="6枚法" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="顔面写真・口腔内" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="顔面写真" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
              </Tabs>
              <TabPanel value={value} index={0} >
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: 0.5 }} direction='row'>
                  <Box>
                    <Stack
                      sx={{
                        boxSizing: 'border-box', p: 1,
                        width: frameWidth + 2, height: frameHeight + 2,
                        alignItems: "flex-start", justifyContent: "flex-start"
                      }}
                    >
                    <Typography className='printArea showPrint' sx={{ fontSize: "18px", fontWeight: "bold", color: theme.palette.text.primary }}>
                      {items?.kana}
                    </Typography>
                    <Typography className='printArea showPrint' sx={{ fontSize: "26px", fontWeight: "bold", color: theme.palette.text.primary}}>
                      {items?.name}
                    </Typography>
                    <Typography className='printArea showPrint' sx={{ fontSize: "22px", color: theme.palette.text.primary, mt: 1 }}>
                      患者番号 : {items?.patientNo ?? '-'}
                    </Typography>
                    <Typography className='printArea showPrint' sx={{ fontSize: "22px", color: theme.palette.text.primary }}>
                      撮影日 : {examinationDate}
                    </Typography>
                    <Typography className='printArea showPrint' sx={{ fontSize: "22px", color: theme.palette.text.primary }}>
                      当時の年齢 : {calculateThatTimeAge(items?.birthday, examinationDate)}
                    </Typography>
                    </Stack>
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(11)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(11)}
                    />
                  </Box>
                  <Box>
                    <Stack sx={{ width: frameWidth, height: frameHeight, border: "1px solid #00000000"}}/>
                  </Box>
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: 0.5, mt: 0.5 }} direction='row'>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(13)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(13)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(10)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(10)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(14)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(14)}
                    />
                  </Box>
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', mt: 0.5 }} direction='row'>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(12)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(12)}
                    />
                  </Box>
                </Stack>                
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: 1 }} direction='row'>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(13)}
                      frameWidth={frameLargeWidth}
                      frameHeight={frameLargeHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(13)}
                    />
                  </Box>                 
                  <Box>
                    <PhotoFrame
                      image={imageGetter(10)}
                      frameWidth={frameLargeWidth}
                      frameHeight={frameLargeHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(10)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(14)}
                      frameWidth={frameLargeWidth}
                      frameHeight={frameLargeHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(14)}
                    />
                  </Box>
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: 1, mt: 1 }} direction='row'>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(11)}
                      frameWidth={frameLargeWidth}
                      frameHeight={frameLargeHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(11)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(12)}
                      frameWidth={frameLargeWidth}
                      frameHeight={frameLargeHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(12)}
                    />
                  </Box>    
                  <Box>
                    <PhotoFrame
                      image={imageGetter(20)}
                      frameWidth={frameLargeWidth}
                      frameHeight={frameLargeHeight}
                      isNotLazy={true}
                      angleName={getSettingAngleName(20)}
                      isTextAlter={true}
                    />
                  </Box>                                
                </Stack>                
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: 0.5 }} direction='row'>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(32)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(32)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(30)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(30)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(33)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(33)}
                    />
                  </Box>
                </Stack>                  
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: 0.5, mt: 0.5 }} direction='row'>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(11)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(11)}
                    />
                  </Box>
                  <Box>
                    <Stack
                      sx={{
                        boxSizing: 'border-box', p: 1,
                        width: frameWidth + 2, height: frameHeight + 2,
                        alignItems: "flex-start", justifyContent: "flex-start"
                      }}
                    >
                      <Typography className='printArea showPrint' sx={{ fontSize: "10px", fontWeight: "bold", color: theme.palette.text.primary }}>
                        {items?.kana}
                      </Typography>
                      <Typography className='printArea showPrint' sx={{ fontWeight: "bold", color: theme.palette.text.primary }}>
                        {items?.name}
                      </Typography>
                      <Typography className='printArea showPrint' sx={{ fontSize: "14px", color: theme.palette.text.primary, mt: 1 }}>
                        患者番号 : {items?.patientNo ?? '-'}
                      </Typography>
                      <Typography className='printArea showPrint' sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                        撮影日 : {examinationDate}
                      </Typography>
                      <Typography className='printArea showPrint' sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                        当時の年齢 : {calculateThatTimeAge(items?.birthday, examinationDate)}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(12)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(12)}
                    />
                  </Box>
                </Stack>                
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: 0.5, mt: 0.5 }} direction='row'>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(13)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(13)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(10)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(10)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(14)}
                      frameWidth={frameWidth}
                      frameHeight={frameHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(14)}
                    />
                  </Box>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: 1 }} direction='row'>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(32)}
                      frameWidth={frameLargeWidth}
                      frameHeight={frameLargeHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(32)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(30)}
                      frameWidth={frameLargeWidth}
                      frameHeight={frameLargeHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(30)}
                    />
                  </Box>
                  <Box>
                    <PhotoFrame
                      image={imageGetter(33)}
                      frameWidth={frameLargeWidth}
                      frameHeight={frameLargeHeight}
                      isNotLazy={true}
                      alterPath={getAlterPath(33)}
                    />
                  </Box>
                </Stack>
              </TabPanel>              
            </Box>                    
          </Box>
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default PrintMontage
