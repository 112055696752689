import React, {useState, useEffect} from 'react'
import { Typography, Box, Stack, CircularProgress, Grid, Button } from '@mui/material'
import Layout from '../../components/Layout'
import { ThemeProvider } from '@mui/material/styles'
import CollectionsIcon from '@mui/icons-material/Collections'
import theme from '../../style/theme'
import { useGetUnassignedPhoto } from '../../api/photo'
import type { Photo }  from '../../api/photo/responseType'
import OperationProof from 'components/OperationProof'
import UnassignedPhotoGallery, { GalleryModalProps } from 'components/UnassignedPhotoGallery'
import PhotoFrame from 'components/PhotoFrame'

const UnassignedPhotos = () => {
  const [ unassigned, setUnassigned ] = useState<Photo[] | null>(null)
  const {
    data: photoData, isError: isPhotoError, isPending: isPhotoPending
  } = useGetUnassignedPhoto()
  const [modalConfig, setModalConfig] = useState<GalleryModalProps | undefined>()


  useEffect(() => {
    if (isPhotoError) {
      console.error(isPhotoError)
      return
    }
    if(!isPhotoPending && photoData !== undefined){
      setUnassigned(photoData.unassigned)
    }
  }, [
    photoData, isPhotoError, isPhotoPending,
  ])

  if (isPhotoPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  const handleOpen = async (index: number) => {
    await new Promise<GalleryModalProps>((resolve) => {
      setModalConfig({ onClose: resolve, images: unassigned, index: index})
    })
    setModalConfig(undefined)
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1, mb: 4 }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Stack direction="row" alignItems="center" columnGap={2}>
                <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CollectionsIcon sx={{ color: theme.palette.text.primary }}/>
                </Box>
                <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>患者未割当写真</Typography>
              </Stack>
            </Stack>
            {
              isPhotoPending ?
              <Stack justifyContent="center" alignItems="center" width={1 / 1} mt={1}>
                <CircularProgress />
              </Stack>
              :
              <Grid container spacing={2} mt={2} px={2}>
                {
                  unassigned?.map((item, index) => (
                    <Grid key={index}>
                      <Button
                        onClick={() => handleOpen(index)}
                      >
                        <Box>
                          <PhotoFrame
                            image={item}
                          />
                        </Box>
                      </Button>
                    </Grid>
                  ))
                }
              </Grid>
            }
          </Box>
          {modalConfig && <UnassignedPhotoGallery {...modalConfig} />}
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default UnassignedPhotos
