import React, { MutableRefObject, useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Button, IconButton, Typography, Tooltip, TooltipProps, tooltipClasses, Stack, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import ControlledImage from 'components/ControlledImage'
import theme from '../style/theme'
import { PhotoObject } from 'types/PhotoObject'
import { useWindowSize } from 'lib/useWindowSize'

interface UploadPhotoFrameProps {
  image: PhotoObject
  inputRefs: MutableRefObject<(HTMLInputElement | null)[]>
  onChangeTeethPhoto: Function
  handleEditTeethPhoto: Function
  handleDeleteTeethPhoto: Function
  handleClickTeethPhoto: Function
  onDragTeethPhoto: Function
  imageRefs: MutableRefObject<(HTMLImageElement | null)[]>
  angleName?: string
}


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#242424',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    fontSize: theme.typography.pxToRem(12),
    border: '#242424',
  },
}))

const UploadPhotoFrame = (props: UploadPhotoFrameProps) => {
  const { 
    image, inputRefs, 
    onChangeTeethPhoto, handleEditTeethPhoto, handleDeleteTeethPhoto, 
    handleClickTeethPhoto, onDragTeethPhoto, imageRefs, angleName
  } = props
  const [ tooltipOpen, setTooltipOpen ] = useState(false)
  const { windowWidth } = useWindowSize()
  const [ isIpad, setIsIpad] = useState(windowWidth < 1025)
  const [ isScreen, setIsScreen] = useState(windowWidth >= 1400)
  const ref = useRef<HTMLDivElement | null>(null)

  const frameWidth = isScreen ? 280 : (isIpad ? 240 : 260)
  const frameHeight = isScreen ? 210 : (isIpad ? 180 : 195)

  const ImageBoxStyle = {
    width: `${frameWidth}px`, height: `${frameHeight}px`, fontWeight: '600',
    border: '1px solid #A6A6A6', borderRadius: '8px',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    overflow: 'hidden', backgroundColor: 'inherit'
  }

  const ImageBoxStyleClone = structuredClone(ImageBoxStyle)

  const onDrop = useCallback((acceptedFiles: any) => {
    onDragTeethPhoto(image.angle, acceptedFiles)
  }, [image.angle, onDragTeethPhoto])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: onDrop,
      noClick: true
    })

  if (!image.initial || image.loading) {
    ImageBoxStyleClone.backgroundColor = '#777777'
  }

  const style = useMemo(
    () => ({
      ...ImageBoxStyleClone,
      ...(isDragActive ? {backgroundColor: '#33333333'} : {})
    }),
    [ImageBoxStyleClone, isDragActive]
  )

  const buttonStyle = {
    width: 24, height: 24, padding: 1, borderRadius: '50%',
    color: theme.palette.primary.main, backgroundColor: '#FFFFFF55'
  }

  const handleButtonClicked = () => {
    if (image.initial){
      handleClickTeethPhoto(image.angle)
    } else {
      setTooltipOpen(!tooltipOpen)
    }
  }

  const TooltipContent = () => {
    const style = {
      p: 0,
      '&:hover': { color: theme.palette.primary.main, backgroundColor: 'transparent' }
    }
    return (
      <>
        <Button sx={{width: 100, justifyContent: 'left'}} onMouseDown={() => {
          handleEditTeethPhoto(image)
        }}>
          <Typography color='white' sx={style}>
            編集
          </Typography>
        </Button>
        <Button sx={{width: 100, justifyContent: 'left'}} onMouseDown={() => {
          handleClickTeethPhoto(image.angle)
        }}>
          <Typography color='white' sx={style}>
            置き換える
          </Typography>
        </Button>
        <Button sx={{width: 100, justifyContent: 'left'}} onMouseDown={() => {
          handleDeleteTeethPhoto(image.angle)
        }}>
          <Typography color='white' sx={style}>
            削除
          </Typography>
        </Button>
      </>
    )
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  useEffect(() => {
    setIsIpad(windowWidth < 1025)
    setIsScreen(windowWidth >= 1400)
  }, [windowWidth])

  return (
    <Stack position="relative" ref={ref}>
      <Box {...getRootProps({style})} sx={{objectFit: 'cover'}}>
        <input {...getInputProps} type='file' accept="image/jpg, image/jpeg, image/tif, image/tiff, image/png, image/jfif" ref={el => (inputRefs.current[image.angle] = el)} style={{ display: 'none' }} onChange={(e) => onChangeTeethPhoto(image.angle, e)} />
        {
          image.loading ?
          <CircularProgress /> :
          image.path === '' ?
          <Typography sx={{ textAlign: 'center' }}>{angleName ?? image.anglename}</Typography> :
          // 画像表示は「表示枠」「移動枠」「画像」の入れ子になっている
          // ここでは「移動枠」と「画像」の設定を行う
          <ControlledImage image={image} frameWidth={frameWidth} frameHeight={frameHeight} imageRefs={imageRefs} isEdit={false} />
        }
      </Box>
      <Box sx={{ position: 'absolute', bottom: '0', right: '0' }}>
        <HtmlTooltip
          title={
            <TooltipContent />
          }
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={tooltipOpen}
          onBlur={handleTooltipClose}
        >
          {
            image.loading ?
            <></> :
            <IconButton sx={{ p: 0, '&:hover': { backgroundColor: 'transparent' } }} onClick={handleButtonClicked}>
              {
                image.initial ?
                <AddCircleIcon sx={buttonStyle} /> :
                <EditIcon sx={buttonStyle} />
              }
            </IconButton>
          }
        </HtmlTooltip>
      </Box>
    </Stack>
  )
}
export default UploadPhotoFrame
