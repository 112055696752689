import React, { useState } from 'react'
import { Typography, Box, Stack, FormControl, FormLabel, TextField, MenuItem } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'style/theme'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
import CircularProgressWithLabel from './CircularProgressWithLabel'

export type Patient03Props = {
  show: boolean
  handleChange: Function
  handleChangeDate: Function
  sex: number
  birthday: Dayjs
  sexError: string[]
  birthdayError: string[]
}

const Patient03 = (props: Patient03Props) => {
  const { show, handleChange, handleChangeDate, sex, birthday, sexError, birthdayError } = props
  const [progress] = useState(60)

  const display = show ? "block" : "none"

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: display }}>
          <Stack sx={{ display: "flex", alignItems: "center", columnGap: "24px" }} direction = "row">
            <Box sx={{ mt: 3, position: 'relative' }}>
              <Box
                sx={{ 
                  margin: "auto",
                  width: "54px",
                  height: "54px",
                  position: 'absolute',
                  border: "solid #D8EAE9 5px",
                  borderRadius: "50%",
                  top: 0,
                  left: 0,
                }}>
              </Box>
              <CircularProgressWithLabel progress={progress} page={3} />
            </Box>
            <Box><Typography sx={{ fontWeight: "600", backgroundColor: "#F1F1F1", borderRadius: "8px", py: 2, px: 4, mt: 2 }} color="#364F4E">患者の生年月日を追加してください。</Typography></Box>
          </Stack>
          <Stack sx={{ display: "flex", rowGap: "24px", mt: 3, ml: 8 }}>
            <FormControl>
              <Stack sx={{ display: "flex", alignItems: "center", columnGap: "16px" }} direction = "row">
                <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "16px" }}>性別</FormLabel>
                <Typography sx={{ fontWeight: "600", color: "white", background: "#F4817A", borderRadius: "24px", fontSize: "12px", px: 2 }}>必須</Typography>
              </Stack> 
              <TextField
                variant="outlined" select placeholder="入力してください。" value={sex}
                sx={{ background: "white",borderRadius: "8px", width: "240px", mt: 1 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'sex')}
                error={sexError.length > 0}
              >
                <MenuItem value={0}>女性</MenuItem>
                <MenuItem value={1}>男性</MenuItem>
                <MenuItem value={2}>その他</MenuItem>
              </TextField>
              {sexError.map((error, index) => {
                return (
                  <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
                )
              })}
            </FormControl>  
            <FormControl>
              <Stack sx={{ display: "flex", alignItems: "center", columnGap: "16px" }} direction = "row">
                <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "16px" }}>生年月日</FormLabel>
                <Typography sx={{ fontWeight: "600", color: "white", background: "#F4817A", borderRadius: "24px", fontSize: "12px", px: 2 }}>必須</Typography>
              </Stack>   
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    defaultValue={birthday}
                    value={birthday} format='YYYY - MM - DD'
                    slotProps={{ calendarHeader: { format: 'YYYY年MM月' } }}
                    sx={{ background: "white",borderRadius: "8px", width: "240px", mt: 1 }}
                    onChange={(date: Dayjs) => handleChangeDate(date)}
                    disableFuture
                  />
                  {birthdayError.map((error, index) => {
                    return (
                      <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
                    )
                  })}
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Patient03
