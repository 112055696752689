import { useMutation, useQuery } from '@tanstack/react-query'
import { settingKeys } from './queryKey'
import { getFlipSetting, getAngleNameSetting, patchFlipSetting, patchAngleNameSetting, getExportSetting, patchExportSetting } from './request'

export const useGetFlipSetting = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: settingKeys.flipSetting,
    queryFn: () => getFlipSetting(),
  })

  return { data, isPending, isError }
}

export const usePatchFlipSetting = () => {
  return useMutation({
    mutationFn: patchFlipSetting,
    onError: (e) => console.error(e),
  })
}

export const useGetAngleNameSetting = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: settingKeys.angleNameSetting,
    queryFn: () => getAngleNameSetting(),
  })

  return { data, isPending, isError }
}

export const usePatchAngleNameSetting = () => {
  return useMutation({
    mutationFn: patchAngleNameSetting,
    onError: (e) => console.error(e),
  })
}

export const useGetExportSetting = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: settingKeys.exportSetting,
    queryFn: () => getExportSetting(),
  })

  return { data, isPending, isError }
}

export const usePatchExportSetting = () => {
  return useMutation({
    mutationFn: patchExportSetting,
    onError: (e) => console.error(e),
  })
}
