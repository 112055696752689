import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useGetPhotoListByTimelapseResponse } from 'api/photo'
import Modal from '@mui/material/Modal'
import Slider from '@mui/material/Slider'
import type { Examination, Photo } from 'api/photo/responseType'
import DiffPhotoFrame from 'components/DiffPhotoFrame'
import { PhotoObject } from 'types/PhotoObject'
import dayjs from 'dayjs'
import { useWindowSize } from 'lib/useWindowSize'
import { Patient } from 'api/patient/responseType'

type Mark = {
  value: number
  label: string
}

export type DiffPhotoModalState = {}

export type DiffPhotoModalProps = {
  onClose: (DiffPhotoModalState: {}) => void
  patientData: Patient
  examinationData: Examination
  angleNum: number
  angleNameGetter: Function
}

const minDistance = 1

export const DiffPhoto = (props : DiffPhotoModalProps) => {
  const { onClose, patientData, examinationData, angleNum, angleNameGetter } = props
  const [ timelapse, setTimelapse ] = useState<(Photo & Pick<Examination, 'examinationDate'>)[]>([])
  const {
    data: timelapseData, isError: isTimelapseError, isPending: isTimelapsePending,
    isRefetchError: isTimelapseRefetchError, isRefetching: isTimelapseRefetchPending
  } = useGetPhotoListByTimelapseResponse(patientData.id, angleNum)
  const [ diff, setDiff ] = useState([0, 0])
  const [ marks, setMarks ] = useState<Mark[]>([{
    value: 0,
    label: "",
  }])
  const [ image01, setImage01 ] = useState<PhotoObject | undefined>()
  const [ image02, setImage02 ] = useState<PhotoObject | undefined>()
  const [ imageLoaded, setImageLoaded ] = useState<boolean>(false)
  const { windowWidth } = useWindowSize()
  const ref = useRef<HTMLDivElement | null>(null)

  const handleChangeDiff = (_event: Event, newValue: number[], activeThumb: number) => {
    if (activeThumb === 0) {
      const changed = Math.min(newValue[0], diff[1] - minDistance)
      setDiff([changed, diff[1]])
      setImage01(timelapse[changed])
      setImage02(timelapse[diff[1]])
    } else {
      const changed = Math.max(newValue[1], diff[0] + minDistance)
      setDiff([diff[0], changed])
      setImage01(timelapse[diff[0]])
      setImage02(timelapse[changed])
    }
  }

  useEffect(() => {
    if (isTimelapseError || isTimelapseRefetchError) {
      console.error(isTimelapseError)
      console.error(isTimelapseRefetchError)
      return
    }

    if(!isTimelapsePending && !isTimelapseRefetchPending && timelapseData !== undefined && !imageLoaded){
      setTimelapse(timelapseData.photos)
      const marks: Mark[] = timelapseData.photos.map((item, index) => {
        const label = timelapseData.photos.length > 9 && index > 0 && index < timelapseData.photos.length - 1 ?
          "'" :
          (item.examinationDate ?? '')
        const marked: Mark = {
          value: index,
          label: label
        }
        return marked
      })
      setMarks(marks)
      const index = timelapseData.photos.findIndex((item) => item.examinationId === examinationData.id)
      const image02Index = index === 0 ? timelapseData.photos.length - 1 : index
      const newDiff = diff
      newDiff[1] = image02Index
      setDiff(newDiff)
      setImage01(Object.assign(timelapseData.photos[0], { initial: false }))
      setImage02(Object.assign(timelapseData.photos[image02Index], { initial: false }))
      setImageLoaded(true)
    }
  }, [
    diff,
    timelapseData, isTimelapseError, isTimelapsePending, isTimelapseRefetchError, isTimelapseRefetchPending,
    examinationData, imageLoaded
  ])

  const getBalloonText = (value: number) => {
    if (timelapse.length > 0) {
      return timelapse[value].examinationDate ?? ''
    }
    return ''
  }

  const pastYearMonth = useMemo(() => {
    if (!timelapse[diff[0]] || !timelapse[diff[1]]) return
    if (!image01 || !image02) return

    const dateFrom = dayjs(timelapse[diff[0]].examinationDate)
    const dateTo = dayjs(timelapse[diff[1]].examinationDate)

    const diffMonth = dateTo.diff(dateFrom, 'month')

    if (diffMonth < 0 || (diffMonth === 0 && dateFrom.unix() > dateTo.unix())) {
      return '0か月'
    }

    if (diffMonth === 0) {
      const diffDays = dateFrom.diff(dateTo, 'day')
      return `${Math.abs(diffDays)}日`
    }

    if (diffMonth >= 12) {
      const years = Math.floor(diffMonth / 12)
      const months = diffMonth % 12

      return months === 0 ? `${years}年` : `${years}年${months}か月`
    }

    return `${diffMonth}か月`
  }, [diff, timelapse, image01, image02])

  return (
    <>
      <Modal
        open onClose={() => onClose({})}
        ref={ref}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: windowWidth >= 1400 ? 1300 : (windowWidth < 1025 ? 630 : 740),
          bgcolor: '#333333',
          boxShadow: 24,
          p: 4
        }}>
          <Typography color="#F4F4F4" align="center" fontSize="16px" mt={-1} mb={2}>
            {angleNameGetter(angleNum)}
          </Typography>
          <Stack sx={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: 4, mb: 4 }} direction = "row">
            <Box sx={{ border: "4px solid #22BDB4"}}>
              <DiffPhotoFrame
                image={image01}
              />
            </Box>
            <Box sx={{ border: "4px solid #EAB221"}}>
              <DiffPhotoFrame
                image={image02}
              />
            </Box>
          </Stack>
          <Box sx={{ textAlign: '-webkit-center', color: 'white' }}>{pastYearMonth}</Box>
          <Box sx={{
            width: windowWidth < 1025 ? '80%': '90%',
            margin:  '0 auto 16px auto',
          }}>
            <Slider
              step={1}
              min={0}
              max={marks.length - 1}
              marks={marks}
              defaultValue={diff}
              value={diff}
              onChange={handleChangeDiff}
              valueLabelDisplay="auto"
              track={false}
              disableSwap
              getAriaValueText={getBalloonText}
              valueLabelFormat={getBalloonText}
              sx={{
                '& .MuiSlider-markLabel': {color: 'white'},
                '& .MuiSlider-thumb[data-index="1"]' : {background: '#EAB221'}
              }}
            />
          </Box>       
        </Box>
      </Modal>
    </>
  )
}
export default DiffPhoto
