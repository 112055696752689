import { PropsWithChildren, useEffect, useState } from 'react'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

const PrivateRoute: React.FC<PropsWithChildren> = () => {
  const { isAuthenticated } = useAuth()
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null)
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const authenticated = await isAuthenticated(clinicId)
        setLoggedIn(authenticated)
      } catch (error) {
        setLoggedIn(false)
      }
    }

    checkAuthentication()
  }, [clinicId, isAuthenticated])

  if (loggedIn === null) {
    // ログイン状態の確認中の場合は、何も表示しない
    return null
  }

  return loggedIn ? <Outlet/> : <Navigate to={`/${clinicId}/login`} replace />
}

export default PrivateRoute

