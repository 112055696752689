import { AuthTokens, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth'
import { confirmSignIn, getCurrentUser, signIn, signOut } from 'aws-amplify/auth/cognito'
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

export interface UseAuth {
  isLoading: boolean
  username: string
  clinicid: string
  clinicname: string
  SignIn: (username: string, password: string, clinicid: string) => Promise<Result>
  getAuthenticatedUser: () => Promise<AuthResult>
  SignOut: () => void
  isAuthenticated: (clinicid: string) => Promise<boolean>
}

interface Result {
  success: boolean
  message: string
}

export interface AuthResult {
  username: string
  clinicname: string
  clinicid: string
  session: AuthTokens | undefined
}

interface UserAttributes {
  'custom:clinic_id'?: string
  'custom:clinic_name'?: string
  [key: string]: any
}

const AuthContext = createContext<UseAuth>({
  isLoading: false,
  username: '',
  clinicid: '',
  clinicname: '',
  SignIn: async (username: string, password: string, clinicid: string) => ({ success: false, message: '' }),
  getAuthenticatedUser: async () => ({ username: '', clinicid: '', clinicname: '', session: undefined }),
  SignOut: () => {},
  isAuthenticated: async (clinicid: string) => false,
})

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useProvideAuth()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true)
  const [username, setUsername] = useState('')
  const [clinicid, setClinicid] = useState('')
  const [clinicname, setClinicname] = useState('')
  const ATTRIBUTES_COOKIE_KEY = 'userAttributes'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCurrentUser()

        setUsername(result.username)
      } catch (e) {
        console.error(e)

        setUsername('')
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  })


  // ユーザー属性を取得してCookieに保存する関数
  const fetchUserAttributesWithCookie = async (): Promise<UserAttributes> => {
    const COOKIE_EXPIRATION_HOURS = 1 // Cookieの有効期限（1時間）
    const cachedAttributes = Cookies.get(ATTRIBUTES_COOKIE_KEY)

    if (cachedAttributes) {
      // Cookieが存在する場合、パースして返す
      return JSON.parse(cachedAttributes)
    }

    // Cookieが存在しない場合、fetchUserAttributesを呼び出して取得
    const attributes = await fetchUserAttributes()

    // Cookieに保存（有効期限を設定）
    Cookies.set(ATTRIBUTES_COOKIE_KEY, JSON.stringify(attributes), {
      expires: COOKIE_EXPIRATION_HOURS / 24,
      secure: true,
      sameSite: 'strict',
    })

    return attributes
  }

  const getAuthenticatedUser = async () => {
    const { username } = await getCurrentUser()

    const { tokens: session } = await fetchAuthSession()

    const attr = await fetchUserAttributesWithCookie()
    const clinicid = attr['custom:clinic_id'] ?? ''
    const clinicname = attr['custom:clinic_name'] ?? ''
    setClinicid(clinicid)
    setClinicname(clinicname)

    return {
      username,
      clinicid,
      clinicname,
      session,
    }
  }

  const deleteCookie = (key: string) => {
    document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }

  const isAuthenticated = async (clinicid: string): Promise<boolean> => {
  try {
      const { userId } = await getCurrentUser()

      if (!userId) return false

      const attr = await fetchUserAttributesWithCookie()
      const cognito_clinicid = attr['custom:clinic_id'] ?? ''

      if (clinicid !== cognito_clinicid) {
        await signOut()
        return false
      }

      return true
    } catch (e) {
      console.error(e)

      return false
    }
  }

  const SignIn = async (username: string, password: string, clinicid: string) => {
    try {
      deleteCookie(ATTRIBUTES_COOKIE_KEY)
      await signOut()

      const { nextStep } = await signIn({username, password, options: {authFlowType: 'USER_SRP_AUTH'}})
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        await confirmSignIn({challengeResponse: password})
      }

      const isValidAuth = await isAuthenticated(clinicid)
      if (!isValidAuth) {
        deleteCookie(ATTRIBUTES_COOKIE_KEY)
        throw new Error('Clinic ID does not match.')
      }

      setUsername(username)

      return { success: true, message: '' }
    } catch (error) {
      console.error(error)

      return {
        success: false,
        message: '認証に失敗しました。',
      }
    }
  }

  const SignOut = async () => {
    try {
      deleteCookie(ATTRIBUTES_COOKIE_KEY)
      await signOut()

      setUsername('')

      return { success: true, message: '' }
    } catch (error) {
      console.error(error)

      return {
        success: false,
        message: 'ログアウトに失敗しました。',
      }
    }
  }

  return {
    isLoading,
    username,
    clinicid,
    clinicname,
    SignIn,
    getAuthenticatedUser,
    SignOut,
    isAuthenticated,
  }
}
