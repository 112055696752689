import z from 'zod'

const SearchDetailSchema = z.object({
  status: z.string().optional(),
  age: z.string().optional(),
  skeletalElements: z.array(z.number()).optional(),
  occlusionRight: z.array(z.number()).optional(),
  facialFeatures: z.array(z.number()).optional(),
  occlusionLeft: z.array(z.number()).optional(),
  otherFactors: z.array(z.number()).optional(),
}).strict()

/* eslint-disable */
export type SearchDetailSchema = z.infer<typeof SearchDetailSchema>
/* eslint-disable */

export default SearchDetailSchema
