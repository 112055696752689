import { client } from 'lib/AxiosClientProvider'
import { GetFlipSettingResponse, GetAngleNameResponse, PatchFlipSettingResponse, PatchAngleNameResponse, GetExportSettingResponse, PatchExportSettingResponse } from './responseType'
import { AngleName, ExportSetting, FlipSetting } from './requestType'

export const getFlipSetting = async (): Promise<GetFlipSettingResponse> => {
  const response = await client.get('/setting/flip')

  return response.data
}

export const patchFlipSetting = async ({
  req
}: {
  req: FlipSetting
}): Promise<PatchFlipSettingResponse> => {
  const response = await client.patch(`/setting/flip`, req)

  return response.data
}

export const getAngleNameSetting = async (): Promise<GetAngleNameResponse> => {
  const response = await client.get('/setting/anglename')

  return response.data
}

export const patchAngleNameSetting = async ({
  req
}: {
  req: AngleName
}): Promise<PatchAngleNameResponse> => {
  const response = await client.patch(`/setting/anglename`, req)

  return response.data
}

export const getExportSetting = async (): Promise<GetExportSettingResponse> => {
  const response = await client.get('/setting/export')

  return response.data
}

export const patchExportSetting = async ({
  req
}: {
  req: ExportSetting
}): Promise<PatchExportSettingResponse> => {
  const response = await client.patch(`/setting/export`, req)

  return response.data
}