import z from 'zod'

export const LoginSchema = z
  .object({
    id: z.string().min(1, { message: 'IDを入力してください。' }),
    password: z.string().min(1, { message: 'パスワードを入力してください。' }),
    isSecretMode: z.boolean(),
  })
  .strict()

/* eslint-disable */
export type LoginSchema = z.infer<typeof LoginSchema>
/* eslint-disable */

export default LoginSchema
