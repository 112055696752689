import React from 'react';
import { Typography, Stack } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { NavLink, useParams } from "react-router-dom";
import ListItem from '@mui/material/ListItem';

const SideNews = (props : any) => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  return (
    <>
      <ListItem sx={{ background: "white" }} >
        <ListItemButton
          role="listitem"
          to={`/${clinicId}/setting/news/post/` + props.newsItemData.id}
          component={NavLink}
          sx={{ p: 3 }}
        >
          <Stack sx={{ display: "flex", rowGap: "8px"}}>
              <Typography sx={{ background: props.newsItemData.read ? "#00BCC8" : "#EC5264", color: "white", fontWeight: "bold", fontSize: "12px", borderRadius:"26px", py: "2px", px: 2, width: "54px", textAlign: "center" }}>{props.newsItemData.read ? "開封済み" : "未読"}</Typography>
              <Typography sx={{ color: "#333333" }} >
              {props.newsItemData.title} 
              </Typography>
              <Typography sx={{ color: "#9D9D9D", fontSize: "14px", mt: "16px" }} >
              {props.newsItemData.publishDatetime}
              </Typography>
            </Stack>
        </ListItemButton>
      </ListItem>
    </>
  )
}
export default SideNews;
