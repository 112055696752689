import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import env from 'env'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

const SESSION_EXPIRED_STATUS_CODE = 401

export const client = axios.create({
  baseURL: env.API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
})

export function AxiosClientProvider({children}: {children: React.ReactElement}) {
  const navigate = useNavigate()
  const auth = useAuth()

  // リクエスト インターセプター
  client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      const { session } = await auth.getAuthenticatedUser()
      if (config.headers['Content-Type'] !== 'multipart/form-data'){
        config.headers.Authorization = `Bearer ${session?.accessToken}`

        // data を snake_case に変換
        if (config.data) {
          config.data = snakecaseKeys(config.data, { deep: true })
        }
      }

      // paramerter を snake_case に変換
      if (config.params) {
        config.params = snakecaseKeys(config.params, { deep: true })
      }
    } catch (error) {
      console.error('Error fetching authenticated user:', error)
    }

    return config
  })

  // レスポンス インターセプター
  client.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      const { data } = response
      if (!data) return response

      return { ...response, data: camelcaseKeys(data, { deep: true }) }
    },
    (error: AxiosError) => {
      switch (error.response?.status) {
        case SESSION_EXPIRED_STATUS_CODE:
          navigate('/login')
          break
        default:
          break
      }

      return Promise.reject(error)
    }
  )

  return (<>{children}</>)
}
