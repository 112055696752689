import React from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import theme from '../style/theme';

export type ConfirmModalOnCloseProps = {
  modalStatus: string
}

export type ConfirmModalProps = {
  onClose: (ConfirmModalOnCloseProps:{ modalStatus :string }) => void
  title?: string
  body?: string
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const { onClose, title, body } = props

  return (
    <>
      <Modal
        open onClose={() => onClose({modalStatus: 'close'})}
      >
        <Box sx={{ p:6, width: 480, position: "absolute",top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", borderRadius: "8px"}}>
          <Stack sx={{ alignItems: "center" }} direction="column">
            <Typography fontSize={18} textAlign="center" fontWeight="bold" mt={1}>
            {title}
            </Typography>
            {
              body && (
                <Typography textAlign="center" mt={1}>
                {body}
                </Typography>
              )
            }
            <Stack sx={{ alignItems: "center", columnGap: "24px", justifyContent: "flex-end", mt: 4 }} direction="row">
              <Button
                onClick={() => onClose({ modalStatus: 'ok' })}
                sx={{ textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 8, fontWeight: "bold", mt: 3, "&:hover": { background: "#11867F" } }}
                >
                  はい
              </Button>
              <Button
                onClick={() => onClose({modalStatus: 'close' })}
                sx={{ textAlign: "center", fontSize: "14px", color: theme.palette.primary.main, background: "white", borderRadius: "40px", px: 8, fontWeight: "bold", border: "1px solid #22BDB4",mt: 3, "&:hover": { background: theme.palette.primary.main, color: "white" } }}>
                  いいえ
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
export default ConfirmModal;