import React, { MutableRefObject, useRef, useEffect, useState } from 'react'
import { Box, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import ControlledImage from 'components/ControlledImage'
import { PhotoObject, } from 'types/PhotoObject'
import { useWindowSize } from 'lib/useWindowSize'

interface PhotoFrameProps {
  image: PhotoObject
  imageRefs: MutableRefObject<(HTMLImageElement | null)[]>
}

const PhotoEditFrame = (props: PhotoFrameProps) => {
  const { image, imageRefs } = props
  const { windowWidth } = useWindowSize()
  const [ isIpad, setIsIpad] = useState(windowWidth < 1025)
  const [ isScreen, setIsScreen] = useState(windowWidth >= 1400)
  const ref = useRef<HTMLDivElement | null>(null)
  const frameWidth = isScreen ? 680 : (isIpad ? 480 : 640)
  const frameHeight = isScreen ? 510 : (isIpad ? 360 : 480)

  const ImageBoxStyle = {
    width: `${frameWidth}px`, height: `${frameHeight}px`, fontWeight: '600',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    objectFit: 'cover', overflow: 'hidden',
    position: 'relative', backgroundColor: '#777777'
  }

  useEffect(() => {
    setIsIpad(windowWidth < 1025)
    setIsScreen(windowWidth >= 1400)
  }, [windowWidth])

  return (
    <Stack ref={ref}>
      <Box sx={ImageBoxStyle}>
        <ControlledImage image={image} frameWidth={frameWidth} frameHeight={frameHeight} imageRefs={imageRefs} isEdit={true} />
        <Grid container columns={2} className="photoGrid" sx={{
          position: 'absolute', top: -1, left: -1,
          width: 'calc(100% + 2px)', height: 'calc(100% + 2px)'
        }}>
          {
            [...Array(4)].map((e1, i1) => {
              return (
                <Grid item key={i1} sx={{
                  width: 1 / 2, height: 1 / 2
                }}>
                  <TableContainer sx={{height: 1 / 1}}>
                    <Table sx={{height: 1 / 1}}>
                      <TableBody sx={{height: 1 / 1}}>
                        {[...Array(4)].map((e2, i2) => {
                          return (
                            <TableRow key={i2}>
                              {[...Array(4)].map((e3, i3) => {
                                return (
                                    <TableCell sx={{ width: 1 / 8, height: 1 / 8, border: '1px solid #333333'}} key={i3}></TableCell>
                                )
                              })}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            })
          }
        </Grid>
      </Box>
    </Stack>
  )
}
export default PhotoEditFrame
