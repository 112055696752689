import React from 'react'
import { Stack, Typography } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import theme from '../style/theme'
import dayjs , { Dayjs } from 'dayjs'
import { DateView } from '@mui/x-date-pickers'

export type DateRangePickerProps = {
  from: Dayjs | null
  to: Dayjs | null
  setFrom: Function
  setTo: Function
  changeTerm: Function
  monthMode?: boolean
  labelLess?: boolean
}

export const DateRangePicker = (props: DateRangePickerProps) => {
  const { from, to, setFrom, setTo, changeTerm, monthMode, labelLess } = props
  const fromMaxDate = monthMode ? dayjs().subtract(7, 'month') : dayjs().subtract(1, 'month')
  const format = monthMode ? 'YYYY/MM' : 'YYYY/MM/DD'
  const views: DateView[] = monthMode ? ['year', 'month'] : ['year', 'month', 'day']

  return (
    <Stack direction="column">
      {
        labelLess ? (
          <></>
        ) : (
          <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, mb: 2 }}>期間で検索する</Typography>
        )
      }
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker', 'DatePicker']}>
          <DatePicker
            label="開始期間"
            value={from}
            defaultValue={from}
            minDate={dayjs().subtract(2, 'year').add(1, 'day')}
            maxDate={to ?? fromMaxDate}
            onChange={(newValue) => {
              setFrom(newValue)
              changeTerm()
            }}
            format={format}
            slotProps={{
              calendarHeader: { format: 'YYYY年MM月' },
              monthButton: {lang: 'ja' }
            }}
            views={views}
          />
          <DatePicker
            label="終了期間"
            value={to}
            defaultValue={to}
            minDate={from ?? dayjs()}
            onChange={(newValue) => {
              setTo(newValue)
              changeTerm()
            }}
            format={format}
            slotProps={{
              calendarHeader: { format: 'YYYY年MM月' },
              monthButton: {lang: 'ja' }
            }}
            views={views}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Stack>
  )
}
