import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Box, Typography, Button, Link } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Divider from '@mui/material/Divider'
import theme from '../../style/theme'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavLink, useParams } from "react-router-dom"
import PatientStatusBar from './PatientStatusBar'
import { Patient } from 'api/patient/responseType'
import { getImageUrl } from 'lib/getImagePath'
import AvatarRender from 'components/AvatarRender'
import { defaultAffine } from 'types/PhotoObject'
import { useWindowSize } from 'lib/useWindowSize'

interface NumberToString {
  [key: number]: string
}

export type ModalOnCloseProps = {
  modalStatus: string
  confirmDate: string
}

export type ConfirmModalOnCloseProps = {
  modalStatus: string
}

type PatientItemProps = {
  patientItemData: Patient
  isSecret: boolean
  page?: number
}

const PatientListItem = ({patientItemData, isSecret}: PatientItemProps) => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  const navigate = useNavigate()
  const loc = useLocation()
  const [status, setStatus] = useState(patientItemData.status)
  const { windowWidth } = useWindowSize()
  const [ isIpad, setIsIpad] = useState(windowWidth < 1025)
  const [ isScreen, setIsScreen] = useState(windowWidth >= 1400)
  const ref = useRef<HTMLDivElement | null>(null)
  const patientId = useMemo(() => {
    return patientItemData.id
  }, [patientItemData.id])

  useEffect(() => {
    setStatus(patientItemData.status)
  }, [patientItemData])

  const handleUpload = (patientId: number) => {
    navigate(`/${clinicId}/patient/upload/${patientId}`)
  }

  const patientSex: NumberToString = {
    0: '女',
    1: '男',
    2: 'その他',
  }

  const patientStatus: NumberToString = {
    0: '初診',
    1: '検査',
    2: '診断',
    3: '動的処置',
    4: '保定期間',
    5: '治療終了',
  }

  const getImagePath = useCallback(() => {
    const path = patientItemData.face

    if (path === undefined || !path || isSecret) {
      return ''
    }

    return getImageUrl(path)
  }, [patientItemData.face, isSecret])

  const getFaceAffine = useCallback(() => {
    const affine = patientItemData?.affine

    if (affine === undefined || !affine || isSecret) {
      return defaultAffine
    }

    return affine
  }, [patientItemData, isSecret])

  const imagePath = getImagePath()
  const affine = getFaceAffine()

  useEffect(() => {
    setIsIpad(windowWidth < 1025)
    setIsScreen(windowWidth >= 1400)
  }, [windowWidth])

  return (
    <>
    <ThemeProvider theme={theme}>
      <ListItem alignItems="center" sx={{ columnGap: "8px", p: 0, pl: 1, borderBottom: "1px solid #D9D9D9", overflow: "scroll", whiteSpace: "nowrap", scrollbarWidth: "none",  msOverflowStyle: "none" }} >
        <AvatarRender imagePath={imagePath} affine={affine} frameWidth={64} frameHeight={64} />
        <Box sx={{ flex: 4}} ref={ref}>
          <Link
            to={`/${clinicId}/patient/${patientId}`}
            component={NavLink}
            underline="none"
            sx = {{"&:hover .MuiTypography-root": { color: theme.palette.primary.main }, width: isScreen ? 'auto' : ( isIpad ? '16vw' : '200px' ), display: 'block' }}
          >
              <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: theme.palette.text.primary }}>
                {patientItemData.kana}
              </Typography>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: theme.palette.text.primary }}>
                {patientItemData.name}
              </Typography>
          </Link>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography sx={{ flex: 1, textAlign: "center"}}>
            {patientSex[patientItemData.sex]}
        </Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <PatientStatusBar
          patientId={patientId}
          status={status}
          fmeDate={patientItemData.fmeDate ?? ''}
          insDate={patientItemData.insDate ?? ''}
          diaDate={patientItemData.diaDate ?? ''}
          atDate={patientItemData.atDate ?? ''}
          retDate={patientItemData.retDate ?? ''}
          cmpDate={patientItemData.cmpDate ?? ''}
          setStatus={setStatus}
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box sx={{ flex: 2}}>
          <Typography sx={{ border: "1px solid #808080", borderRadius: "40px", py: "4px", px: 1, fontSize: "12px", textAlign: "center", width: isScreen ? 'auto' : ( isIpad ? '8vw' : '100px' )}}>
            {patientStatus[status]}
          </Typography>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box sx={{ flex: 2}}>
          <Button onClick={() => handleUpload(patientId)} variant="contained" sx={{ px: 1 ,py: "4px" ,background: theme.palette.primary.main , color: "white", borderRadius: "60px", fontWeight: "bold", width: "120px", fontSize: "12px" }}>アップロード</Button>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box sx={{ flex: 1, textAlign: "center"}}>
          <IconButton
            sx={{ p: 0 }}
            to={`/${clinicId}/patient/` + patientId}
            state={loc.pathname + loc.search}
            component={NavLink}
            >
            <ArrowForwardIcon sx={{ background: theme.palette.primary.main, width: "24px",height: "24px", color: "white",borderRadius: "50%" }} />
          </IconButton>
        </Box>
      </ListItem>
      </ThemeProvider>
    </>
  )
}

export default PatientListItem
