import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import Layout from '../../components/Layout';
import PageBackButton from '../../components/PageBackButton';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../style/theme';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const SettingTermsOfService = () => {
  const urlParams = useParams<{clinicId: string | undefined}>();
  const clinicId = urlParams.clinicId ?? '';

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={`/${clinicId}/setting`} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 4, mt: 1, mb: 4}}>
            <Box sx={{ mt: 3, px: 4 }}>     
              <Typography sx={{ fontWeight: "600", fontSize: "24px", color: theme.palette.text.primary, textAlign: "center" }}>
                利用規約
              </Typography>
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 6}}>
                この利用規約（以下「本利用規約」といいます。）は、asparagus株式会社（以下「当社」といいます。）が提供する歯科矯正治療向け口腔内写真クラウド管理システムサービス「oralis」（以下「本サービス」といいます。）の利用に関する条件を定めるものです。本利用規約は、本サービスを利用する全ての登録ユーザー（第2条で定義します。）に適用されます。本サービスをご利用になる前に、本利用規約の内容をよくお読みください。本利用規約に同意されない場合は、本サービスを利用することはできません。
              </Typography>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第1条（目的）
              </Typography>              
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    1.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    本利用規約は、本サービスの利用（パソコン、スマートフォンその他の利用端末の種類を問わず、また、ウェブサイト及びアプリケーションを通じた利用を含みます。）に関する条件及び当社と登録ユーザーとの間の権利義務関係を定めることを目的としています。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    2.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    当社が当社ウェブサイト上で掲載する本サービス利用に関するルールは、本利用規約の一部を構成するものとします。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    3.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は,本規約の規定が優先して適用されるものとします。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    4.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    登録ユーザーは、本サービスを利用するにあたり、本利用規約を遵守するものとします。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    5.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    登録ユーザーは、本利用契約の期間中、本サービスの通常想定される利用目的の範囲内で、本利用規約に従って本サービスを利用することができます。
                  </Typography>
                </Stack>                                                                   
              </Stack>                    
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第2条（定義）
              </Typography>  
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                本利用規約において使用する以下の用語は、以下に定める意味を有するものとします。
              </Typography>            
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    (1)
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    「本利用契約」とは、当社と登録ユーザーとの間で締結される本サービスの利用に関する契約をいいます。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    (2)
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  「登録ユーザー」とは、第３条に基づき当社との間で本利用契約を締結した法人及び団体並びに当社が本サービスの利用を許諾した者をいいます。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    (3)
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  「知的財産権」とは、特許権、実用新案権、商標権、意匠権、著作権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含む。）をいいます。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    (4)
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  「アップロードデータ」とは、登録ユーザーが本サービスを利用して投稿その他送信するコンテンツ(文章、画像、動画その他のデータを含みますがこれらに限りません)をいいます。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    (5)
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  「当社ウェブサイト」とは、そのドメインが「oralis.jp」である、当社が運営するウェブサイト(理由の如何を問わず、当社のウェブサイトのドメイン又は内容が変更された場合は,当該変更後のウェブサイトを含みます)をいいます。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    (6)
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  「反社会的勢力等」とは、暴力団、暴力団員、暴力団員でなくなったときから５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロ又は特殊知能暴力集団等、その他これらに準ずる者をいいます。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    (7)
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  「法令等」とは、法律、政令、省令、通達、規則、条例、裁判所の判決、決定、命令又は強制力のある行政処分、ガイドラインその他の規制の総称をいいます。
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    (8)
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  「個人情報」とは、個人に関する情報であって、個人情報の保護に関する法律第２条第１項により定義されたものをいいます。
                  </Typography>
                </Stack>                                                                                                 
              </Stack> 
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第3条（本サービスの申込・登録）
              </Typography>              
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    1.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    本サービスの利用を希望する方（以下「登録希望者」といいます。）は、本利用規約を遵守することに同意し、かつ当社の定める情報（以下「登録情報」といいます。）を当社指定の方法で当社に提供することにより、登録を申請することができます。なお、法人その他の団体に所属する個人が当該団体を代表して登録の申請を行った場合、当該個人は、当該団体を代表して申し込みを行う正当な権限を有するものとみなします。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    2.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    登録希望者は登録の申請を行う時点で、本利用規約に同意したものとみなします。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    3.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    当社は、当社の基準に従って登録の可否を判断し、その結果を登録希望者に通知します。当社が登録を認める旨の通知を行った時点で登録が完了し、登録ユーザーは本サービスに従い利用することができるようになります。
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    4.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    登録希望者が以下の各号のいずれかに該当し又は該当するおそれがあると当社が判断した場合、登録を拒否することができます。なお、登録申請が承認されなかったとしても、当社はその理由を開示する義務を負いません。
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", rowGap: "8px", pl:"16px", my: "12px" }} >
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      （1）
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                      当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記入漏れがある場合
                    </Typography>
                  </Stack> 
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      （2）
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                      反社会的勢力等に所属し若しくは過去に所属していた場合、その他反社会的勢力等と何らかの交流若しくは関与等がある場合
                    </Typography>
                  </Stack>  
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      （3）
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                      過去に、本サービスその他当社のサービスの利用登録の拒否若しくは取消等の措置を受けた場合、第１１条に基づく利用停止の措置を受け若しくは本利用契約を解約された場合、又は当社との契約その他の合意に違反した場合
                    </Typography>
                  </Stack>  
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      （4）
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    その他当社が登録を不適当と認める場合
                    </Typography>
                  </Stack>                                                        
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px", mb: "2" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    5.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本利用契約の有効期間中に登録ユーザーが前項の各号のいずれかに該当した場合、当該登録ユーザーはただちに当社に通知しなければならないものとします。
                  </Typography>
                </Stack>    
                <Stack sx={{ display: "flex", columnGap: "8px", mb: "2" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    6.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーは、登録情報に誤り等があった場合又は変更が生じた場合、当社所定の方法により、当該内容を当社に通知し又は本サービス上で自ら修正、変更しなければなりません。
                  </Typography>
                </Stack>                                                               
              </Stack>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第4条（登録事項の変更）
              </Typography>              
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                登録ユーザーは,登録事項に変更があった場合,当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
              </Typography>     
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第5条（パスワード及びユーザーIDの管理）
              </Typography>              
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    1.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    登録ユーザーは、ＩＤ、パスワードその他自己のアカウントに関する情報を、自己の責任において安全に管理・保管し、第三者による不正使用を防止するために必要な措置を講じるものとします。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    2.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーは、自己のアカウントを第三者に貸与、共有、譲渡、名義変更その他の方法により第三者に使用させてはなりません。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    3.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社は、ＩＤとパスワードの一致を確認した場合、当該ＩＤ及びパスワードの保有者として登録された登録ユーザーが本サービスを利用したものとみなします。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    4.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーは、本サービスのアカウントの不正利用又は第三者による使用又はそれらのおそれが判明した場合には、ただちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
                  </Typography>
                </Stack>                 
              </Stack>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第6条（利用料金等）
              </Typography>              
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    1.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    本サービスを利用するためのプランの詳細は、以下のとおりとします。
                  </Typography>
                </Stack>  
              </Stack>
              <TableContainer sx={{my: 2, borderLeft: "1px solid #e0e0e0", borderRight: "1px solid #e0e0e0"}}>
                <Table size="small">
                  <TableHead>
                    <TableRow sx={{ borderTop: "1px solid #e0e0e0" }}>
                      <TableCell sx={{ fontWeight: "600", width: "20%", background: "#eee" }} align="center">プラン名</TableCell>
                      <TableCell sx={{ fontWeight: "600", width: "30%", background: "#eee" }} align="center">サービスの内容</TableCell>
                      <TableCell sx={{ fontWeight: "600", width: "35%", background: "#eee" }} align="center">料金</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">スタンダードプラン</TableCell>
                      <TableCell align="left">・口腔内写真管理<br/>※時系列に写真を配列することができます。<br/>※症例検索に必要な分類が可能です。</TableCell>
                      <TableCell align="left">【月額利用料金】<br/>22,000円（税込）（内消費税2,000円）<br/>※1アカウントにつき300GBのストレージが付与されます。<br/>※月額利用料金には，保守料金を含みます。</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">追加ストレージ</TableCell>
                      <TableCell align="left">追加で100GBのストレージが付与されます。</TableCell>
                      <TableCell align="left">【月額利用料金】<br/>1,100円（税込）（内消費税100円）</TableCell>
                    </TableRow>                    
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    2.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーは、別途追加料金を支払うことで以下のサービスを受けることができます。
                  </Typography>
                </Stack>  
              </Stack>
              <TableContainer sx={{my: 2, borderLeft: "1px solid #e0e0e0", borderRight: "1px solid #e0e0e0"}}>
                <Table size="small">
                  <TableHead>
                    <TableRow sx={{ borderTop: "1px solid #e0e0e0" }}>
                      <TableCell sx={{ fontWeight: "600", width: "20%", background: "#eee" }} align="center">プラン名</TableCell>
                      <TableCell sx={{ fontWeight: "600", width: "30%", background: "#eee" }} align="center">サービスの内容</TableCell>
                      <TableCell sx={{ fontWeight: "600", width: "35%", background: "#eee" }} align="center">料金</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">データバックアップサービス</TableCell>
                      <TableCell align="left">患者様の名前、撮影日、患者番号などによって分けられた写真データのバックアップデータを外付けHDDに保存して年1回ご郵送します。</TableCell>
                      <TableCell align="left">【利用料金】<br/>22,000円（税込）（内消費税2,000円）<br/>※1アカウントのご利用料金となります。</TableCell>
                    </TableRow>                  
                  </TableBody>
                </Table>
              </TableContainer>  
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    3.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーは当月分の月額利用料金及びオプションサービスの利用料金を、クレジットカードを利用した決済により行なうものとします。なお、請求の締日については、ご利用のクレジットカード発行会社にご確認ください。
                  </Typography>
                </Stack>  
              </Stack>  
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    4.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社は、本サービスの利用に関する支払いの決済及び情報処理に、クレジットカード決済サービスを利用しています。当社は、当社の故意又は重過失に起因する場合を除き、クレジットカード決済サービスの利用に起因又は関連する論争、クレーム、訴訟、権利侵害又は損害について一切の責任を負いません。
                  </Typography>
                </Stack>  
              </Stack> 
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第7条（禁止事項）
              </Typography>         
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                登録ユーザーは、本サービスの利用に関して、自己又は第三者をして、以下の各号の行為を行わないものとします。
              </Typography>     
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （1）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本利用規約に違反する行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （2）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  法令等に違反する行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （3）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  犯罪行為又は犯罪を助長する行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （4）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社又は第三者に対する詐欺又は脅迫行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （5）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  公序良俗に反する行為
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （6）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  反社会的勢力等に対する利益供与その他反社会的勢力等に関与する行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （7）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社又は第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （8）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社又は第三者を誹謗中傷する行為
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （9）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  以下の情報を本サービスを通じて発信する行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", rowGap: "8px", pl:"16px", my: "12px" }} >
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      1.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                      当社又は第三者の名誉又は信用を毀損する表現を含む情報
                    </Typography>
                  </Stack> 
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      2.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    暴力的又は残虐な表現を含む情報
                    </Typography>
                  </Stack>  
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      3.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    わいせつな表現を含む情報
                    </Typography>
                  </Stack>  
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      4.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    差別を助長する表現を含む情報
                    </Typography>
                  </Stack>    
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      5.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    自殺、自傷行為等又は薬物の不適切な利用を助長する表現を含む情報
                    </Typography>
                  </Stack> 
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      6.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    フェイクニュース等の虚偽の情報又はその拡散を求める情報
                    </Typography>
                  </Stack>  
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      7.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    スパム、チェーンメール等の情報の拡散を求める情報
                    </Typography>
                  </Stack>   
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      8.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    違法な勧誘、宣伝等を含む情報
                    </Typography>
                  </Stack> 
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      9.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    他人に不快感を与える表現を含む情報
                    </Typography>
                  </Stack>    
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      10.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    適法かつ正当な権利又は許諾を得ていない個人情報その他の情報
                    </Typography>
                  </Stack>   
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      11.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    違法な方法で入手した情報
                    </Typography>
                  </Stack>  
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      12.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    コンピュータ・ウィルスその他の有害なコンピュータ・プログラムを含む情報
                    </Typography>
                  </Stack>   
                  <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                      13.
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    宗教の勧誘を目的とする情報
                    </Typography>
                  </Stack>                                                                                                                                                                                                                          
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （10）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  虚偽の登録情報を提供する行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （11）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  第三者に成りすます行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （12）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  他の登録ユーザーのアカウントを利用する行為
                  </Typography>
                </Stack>    
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （13）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  他の登録ユーザーのアカウント情報等を収集する行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （14）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本利用規約で明示的に認められる場合又は別途当社が認める場合を除き、本サービスを通じて入手した情報を、複製、販売、出版その他利用する行為
                  </Typography>
                </Stack>      
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （15）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本利用規約において明示的に認められる場合又は別途当社が認める場合を除き、第三者に利益を与える目的で利用する行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （16）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本サービスの一部を複製、頒布又は不正に開示する行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （17）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社のネットワーク又はシステム等に過度な負荷をかける行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （18）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社のネットワーク又はシステム等へ不正にアクセスする行為又はそれを試みる行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （19）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本サービス又は当社サイトに関連するプログラムを変更、削除、逆コンパイル、逆アセンブル又はリバースエンジニアリングする行為
                  </Typography>
                </Stack>      
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （20）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本サービスの運営を妨害する行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （21）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社の信用を毀損し又はそのおそれのある行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （22）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社又は第三者に損害等を与える行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （23）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  その他当社が不適当と認める場合
                  </Typography>
                </Stack>                                                                                                                                                                                                                                                                                                                                 
              </Stack>     
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第8条（非保証）
              </Typography>              
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    1.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本サービスを利用するために必要な機器、通信回線その他の利用環境は、登録ユーザーが自らの責任と費用負担で用意するものとします。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    2.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    当社は、本サービスが、登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性・完全性・安全性を有すること、及び不具合が生じないことについて、何ら保証するものではありません。ただし、本サービスにセキュリティ上の欠陥、エラー、バグその他の不具合が存在する場合、当社は実務上可能な限りこれを修正するよう努めるものとします。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    3.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社は、明示又は黙示を問わず、本サービスを通じて提供されるアップロードデータその他の当社以外の第三者が提供する情報に関して何ら保証するものではありません。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    4.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社は、登録ユーザーが本サービスにアップロードするデータが、本サービスの利用に起因して消失、消滅、変化等しないことについて保証を行うものではありません。登録ユーザーは、自己の責任において、データの管理をし、適宜バックアップ等を行ってから本サービスを利用するものとします。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    5.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社は、本サービスと連携する外部サービスの提供者である第三者が提供するサービス、情報、個人情報の管理等について一切の責任を負いません。登録ユーザーは、自己の責任において、外部サービスを利用するものとします。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    6.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーは、本サービスの利用に関連して第三者との間で生じた取引、連絡、紛争等については、自己の費用負担と責任において対応及び解決するものとします。
                  </Typography>
                </Stack>                                                 
              </Stack>  
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第9条（知的財産権等）
              </Typography>              
              <Stack sx={{ pt: 1 }}>
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    1.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本サービスに関する知的財産権は全て当社又は当社に使用を許諾する正当な権利者（以下本条において「当社等」といいます。）に帰属しており、本利用契約の成立又は本サービスの利用の許諾は、登録ユーザーに対して本サービスの利用を超える当社等の知的財産権の利用許諾を意味するものではありません。
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    2.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本利用規約に明示的に規定されている場合を除き、本サービス及び本利用規約に関連して当社が登録ユーザーに提供した文章、画像、映像、音声、プログラム等一切のコンテンツに関する知的財産権その他一切の権利及び権限は、当社等に帰属します。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    3.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーは、投稿データについて、自らがデータ送信等を行う適法な権利を有していること、及び当該データが第三者の権利を侵害していないことについて、当社に表明し、保証するものとします。
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    4.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  投稿データの知的財産権は登録ユーザーに帰属します。登録ユーザーは当社に対し、本サービス上にコンテンツをアップロードした時点で、本サービス提供上必要な範囲における複製、翻案、派生著作物の作成、表示及び実行に関するライセンスを無償にて付与するものとします。なお、当該コンテンツについて登録ユーザーは著作者人格権を行使しないものとします。
                  </Typography>
                </Stack>                                                  
              </Stack> 
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第10条（データの利用）
              </Typography>     
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
              当社は、登録データその他の登録ユーザーに関するデータ（ただし、個人情報に該当するものを除きます。）を、本サービスを含む当社が提供し又は提供しようとしているサービスの品質向上、これらのサービスに関する登録ユーザーへのご案内、その他当社がプライバシーポリシーに定める目的で利用することができるものとします。
              </Typography>  
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第11条（利用停止及び解約）
              </Typography>     
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーが以下の各号のいずれかの事由に該当すると当社が判断した場合、当社は事前に通知することなく、当該登録ユーザーによる本サービスへのアクセスを禁止し、又は当該登録ユーザーが本サービスに関して当社に提供した情報の全部若しくは一部を削除することができるものとします（以下、総称して「利用停止」といいます。）。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", rowGap: "8px", pl:"16px", my: "12px" }} >
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （1）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    本利用規約のいずれかの条項に違反した場合
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （2）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    第３条第４項各号の登録拒否事由のいずれかに該当した場合
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （3）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    月額利用料金その他本利用契約に基づく支払いを期日に行わず、当社の催促から７日以内に支払遅延が解消されない場合
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （4）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    本サービスに関する不正な支払いが判明した場合
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （5）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    前各号に準ずる事態が生じた場合
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （6）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    その他本サービスの利用又は登録が不適当である場合
                  </Typography>
                </Stack>                                                                          
              </Stack>  
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーが以下の各号のいずれかの事由に該当する場合、当社は登録ユーザーに通知の上、ただちに本利用契約を解約することができます。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", rowGap: "8px", pl:"16px", my: "12px" }} >
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （1）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    前項各号のいずれかに該当した場合
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （2）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    登録ユーザーが支払停止又は支払不能となった場合
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （3）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    登録ユーザーについて破産、民事再生、会社更生、特別清算、又はこれらに類する手続の開始の申立てがあった場合
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （4）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    登録ユーザーについて解散又は清算の手続開始が決議又は決定された場合
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （5）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    個人事業主である登録ユーザーが死亡した場合
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （6）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    当社が登録ユーザーの本サービスの利用、登録又は本利用契約の継続を適当でないと判断した場合
                  </Typography>
                </Stack>                                                                          
              </Stack>    
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  3.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  前二項に定める措置は、当社から当該登録ユーザーに対する損害賠償請求を行うことを妨げるものではありません。また、前二項に定める措置を行ったことについて、当社は一切の損害賠償義務、金銭返還義務を負いません。
                </Typography>
              </Stack>  
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第12条（契約期間）
              </Typography>     
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーは、本利用契約の締結の日から１か月間、無償で本サービスを利用できるものとし（以下「無償サービス利用期間」といいます。）、無償サービス利用期間満了後、本利用契約は同一条件で、1か月毎に自動的に更新されるものとします。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  前項の期間満了の1か月前までに登録ユーザー又は当社からの書面による更新拒絶又は契約条件の変更等の申し出がない場合、本利用契約は同一条件で更に1か月間自動的に更新されるものとし、以降も同様とします。
                </Typography>
              </Stack>    
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第13条（登録ユーザーによる解約）
              </Typography>     
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーが前条第２項に定める日時までに当社の定める本利用契約の更新拒絶手続きを行った場合、本利用契約の有効期間の満了により契約終了の効力が生じます。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  登録ユーザーは、本利用契約が終了した場合であっても、当社及び第三者に対して既に生じた本利用契約上の一切の義務及び債務（損害賠償義務を含みますが、これに限りません。）を免れるものではありません。
                </Typography>
              </Stack> 
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第14条（本サービスの停止）
              </Typography>           
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  当社は以下のいずれかに該当する場合には、登録ユーザーに事前に通知することにより、本サービスの全部又は一部の提供を停止できるものとします。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", rowGap: "8px", pl:"16px", my: "12px" }} >
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （1）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    本サービスに関わるハードウェア、ソフトウェア、通信機器その他関連する機器若しくはシステムの点検又は保守作業を定期的に又は緊急に行う場合
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （2）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    コンピューター若しくは通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング、その他予期せぬ要因により本サービスの提供が困難となった場合
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （3）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    本サービスに関するセキュリティ上の問題が生じた場合
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （4）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    天災地変、戦争、戦争のおそれ、封鎖、通商停止、革命、暴動、伝染病若しくはその他の疫病、物資若しくは施設の破壊若しくは損傷、火災、台風、地震、洪水、その他当社の支配を超える事由
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （5）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    法令等又はこれらに基づく措置により本サービスの運営が困難となった場合
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （6）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    その他前各号に準じる事由により当社が必要と判断した場合
                  </Typography>
                </Stack>                                                                          
              </Stack>
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                前項により当社が行った措置によって生じた損害について、一切の責任を負いません。
                </Typography>
              </Stack>   
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第15条（本サービスの変更・追加）
              </Typography>     
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                当社は、当社の都合により、本サービスの性質に重大な変更が生じない範囲で本サービスの内容の変更又は追加ができるものとします。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                前項により当社が行った措置によって生じた損害について、一切の責任を負いません。
                </Typography>
              </Stack>  
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第16条（本サービスの終了）
              </Typography>     
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                当社は、登録ユーザーへ事前に通知することにより、本サービスの全部又は一部の提供を終了できるものとします。ただし、緊急の場合その他事前の通知が困難な場合には、事後に通知するものとします。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                本サービスの全部を終了する場合、当社は登録ユーザーに対して終了予定日の１か月前までに通知を行うものとします。
                </Typography>
              </Stack>  
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  3.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                本サービス終了後の期間に対応する利用料金を支払い済の場合、当該期間にかかる利用料金の返金を行います。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  4.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                第1項及び第2項により当社が行った措置によって生じた損害について、一切の責任を負いません。
                </Typography>
              </Stack>    
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第17条（解除・解約後の処理）
              </Typography>       
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                理由の如何に関わらず、本利用契約が終了した場合、登録ユーザーは登録情報、投稿データ及び本サービスの利用情報にアクセスすることができなくなります。
              </Typography>         
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第18条（損害賠償）
              </Typography>    
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                登録ユーザーによる本利用規約に違反する行為又は本サービスに関連して登録ユーザーの責めに帰すべき事由により当社又は第三者に損害が生じたときは、登録ユーザーはその一切の損害につき賠償する義務を負うものとします。
                </Typography>
              </Stack>          
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                当社は、本サービス又は本利用規約に関連して登録ユーザーに生じた損害につき、当社の故意又は重過失に基づく場合を除き、一切の責任を負いません。
                </Typography>
              </Stack>  
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  3.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                当社の責めに帰すべき事由により登録ユーザーに損害（現実に生じた直接かつ通常の損害に限り、逸失利益を含まない。）が生じた場合の当社の責任賠償額の総額は、直近3か月に当社が登録ユーザーから受領した月額利用料金の合計金額を超えないものとします。
                </Typography>
              </Stack>    
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第19条（個人情報の取扱い）
              </Typography>       
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                当社は、登録ユーザーに関する個人情報を、当社の個人情報保護方針に従った当社プライバシーポリシー従って取扱います。登録ユーザーは、当社がこのプライバシーポリシーに従って個人情報を取り扱うことに同意するものとします。
              </Typography>   
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第20条（反社会的勢力の排除）
              </Typography>  
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                登録ユーザーは、自ら又はその役員（取締役、執行役、執行役員、監査役又はこれらに準ずる者をいう。）若しくは従業員において、反社会的勢力等に該当しないこと、及び次の各号のいずれにも該当せず、かつ将来にわたっても該当しないことを確約し、これを保証するものとします。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", rowGap: "8px", pl:"16px", my: "12px" }} >
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （1）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  反社会的勢力等が経営を支配していると認められる関係を有すること
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （2）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    反社会的勢力等が経営に実質的に関与していると認められる関係を有すること
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （3）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力等を利用していると認められる関係を有すること
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （4）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    反社会的勢力等に対して暴力団員等であることを知りながら資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （5）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    役員又は経営に実質的に関与している者が反社会的勢力等と社会的に非難されるべき関係を有すること
                  </Typography>
                </Stack>                                                 
              </Stack>
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                登録ユーザーは、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないことを確約し、これを保証します。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", rowGap: "8px", pl:"16px", my: "12px" }} >
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （1）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  暴力的な要求行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （2）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  法的な責任を超えた不当な要求行為
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （3）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  取引に関して、脅迫的な言動をし、又は暴力を用いる行為
                  </Typography>
                </Stack>  
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （4）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                    風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
                  </Typography>
                </Stack>   
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （5）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  その他前各号に準ずる行為
                  </Typography>
                </Stack>                                                 
              </Stack>  
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  3.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                当社は、登録ユーザーが本条に違反した場合には、催告その他の手続きを要しないで、ただちに本利用契約を解除することができるものとします。
                </Typography>
              </Stack> 
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  4.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                当社は、本条に基づく解除により登録ユーザーに損害が生じた場合であっても、当該損害の賠償義務を負いません。また、当該解除に起因して当社に生じた損害につき、登録ユーザーに対し損害賠償請求することができるものとします。
                </Typography>
              </Stack>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第21条（本利用規約の変更）
              </Typography>   
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                当社は、以下の各号のいずれかに該当する場合、本利用規約を変更することができるものとします。
                </Typography>
              </Stack>   
              <Stack sx={{ display: "flex", rowGap: "8px", pl:"16px", my: "12px" }} >
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （1）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本利用規約の変更が、登録ユーザーの一般の利益に適合するとき
                  </Typography>
                </Stack> 
                <Stack sx={{ display: "flex", columnGap: "8px" }} direction = "row">
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                    （2）
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                  本利用規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更にかかる事情に照らして合理的なものであるとき
                  </Typography>
                </Stack>
              </Stack> 
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                前項の場合、当社は、変更後の本利用規約の効力発生日の１４日前までに、本利用規約を変更する旨及び変更後の本利用規約の内容とその効力発生日を、当社が適切と判断する方法（当社サイト・本サービス内への掲示又は登録ユーザーに電子メールを送信する方法等）により通知します。
                </Typography>
              </Stack>  
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  3.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                本利用規約の変更に同意しない登録ユーザーは、前項に定める変更の効力発生日の前日までに、本利用契約を解約するものとします。効力発生日までに本利用契約の解約を行わず、本サービスの利用を継続した場合には、当該変更に同意したものとみなします。
                </Typography>
              </Stack>     
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  4.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                第１項に定める他、当社は、登録ユーザーの同意を得ることにより本利用規約を変更することができるものとします。
                </Typography>
              </Stack> 
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  5.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                当社は、本条に基づいた本利用規約の変更により登録ユーザーに損害が生じたとしても、一切の責任を負いません。
                </Typography>
              </Stack>  
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第22条（権利義務の譲渡禁止）
              </Typography>       
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                登録ユーザーは、当社の事前の書面による承諾なく、本利用契約上の地位又は本利用契約に基づく権利若しくは義務を、第三者に譲渡、移転（合併若しくは会社分割による場合を含みます。）、担保設定又はその他の処分をしてはなりません。
              </Typography> 
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第23条（分離可能性）
              </Typography>       
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                本利用規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本利用規約の残りの規定又は部分は、継続して完全に効力を有するものとし、当社と登録ユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力をもたせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
              </Typography> 
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第24条（協議）
              </Typography>       
              <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, lineHeight: "26px", pt: 1}}>
                本利用規約の解釈に疑義が生じた場合、又は本利用規約に定めのない事項については、当社と登録ユーザーは、誠意をもって協議の上これを解決するものとします。
              </Typography>   
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary, pt: 3}}>
                第25条（準拠法及び管轄）
              </Typography>       
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  1.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                本利用契約は、日本法を準拠法として、日本法に従い解釈されるものとします。
                </Typography>
              </Stack>  
              <Stack sx={{ display: "flex", columnGap: "8px", pt: 1 }} direction = "row">
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
                  2.
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary}}>
                本利用契約に関する紛争については、訴額に応じ東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。なお、調停を行う場合についても同様とします。
                </Typography>
              </Stack>  
              <Typography sx={{ fontWeight: "600", fontSize: "16px", color: theme.palette.text.primary, pt: 3 }}>
              付則<br/>2024年7月1日 制定・施行
              </Typography>                                                                                                                                                                                                                                              
            </Box>
          </Box>
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default SettingTermsOfService;
