import React, {useState} from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import theme from '../style/theme';

export type ModalOnCloseProps = {
  modalStatus: string,
  fmeDate: string
}

export type ConfirmDateModalProps = {
  onClose: (ModalOnCloseProps:{ modalStatus :string, confirmDate :string }) => void
  title?: string
}

export const ConfirmDateModal = (props: ConfirmDateModalProps) => {
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.guess();

  return (
    <>
      <Modal
        open onClose={() => props.onClose({modalStatus: 'close', confirmDate:''})}
      >
        <Box sx={{ p:6, width: 480, position: "absolute",top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", borderRadius: "8px"}}>
          <Stack sx={{ alignItems: "center" }} direction="column">
            <Typography sx={{ textAlign: "center", fontSize: "18px", mb: 3 }} fontWeight = "bold">
            {props.title}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="期間を選択"
                  format="YYYY/MM/DD"
                  defaultValue={dayjs()}
                  onChange={(newDateValue) => setDateValue(newDateValue)}
              />
            </LocalizationProvider>               
            <Stack sx={{ alignItems: "center", columnGap: "24px", justifyContent: "flex-end", mt: 4 }} direction="row">
              <Button
                onClick={() => props.onClose({ modalStatus: 'ok', confirmDate: dateValue?.format("YYYY-MM-DD") ?? ''})}
                sx={{ textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 8, fontWeight: "bold", mt: 3, "&:hover": { background: "#11867F" } }}
                >
                  確認しました
              </Button>
              <Button
                onClick={() => props.onClose({modalStatus: 'close', confirmDate:''})}
                sx={{ textAlign: "center", fontSize: "14px", color: theme.palette.primary.main, background: "white", borderRadius: "40px", px: 8, fontWeight: "bold", border: "1px solid #22BDB4",mt: 3, "&:hover": { background: theme.palette.primary.main, color: "white" } }}>
                  キャンセル
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
export default ConfirmDateModal;
