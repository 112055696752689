import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'style/theme'
import { Stack, CircularProgress } from '@mui/material'
import Layout from './Layout'

const OperationProof = (props: {initial: boolean}) => {
  const { initial } = props
  return initial ? (
    <ThemeProvider theme={theme}>
      <Layout>
        <Stack alignItems="center" justifyContent="center" sx={{height: 1 / 1}}>
          <CircularProgress />
        </Stack>
      </Layout>
    </ThemeProvider>
  ) : (
    <Stack
      alignItems="center" justifyContent="center"
      sx={{
        width: 1 / 1, height: 1 / 1, zIndex: 9999,
        position: 'fixed', top: 0, left: 0,
        backgroundColor: 'white', opacity: 0.8
      }}
    >
      <CircularProgress />
    </Stack>
  )
}

export default OperationProof
