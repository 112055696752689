import React , { useState, useEffect, useRef} from 'react'
import SideMenu from 'components/SideMenu'
import theme from '../style/theme'
import logo from '../assets/ref-logo.png'
import IconButton from '@mui/material/IconButton'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Drawer from '@mui/material/Drawer'
import LogoutIcon from '@mui/icons-material/Logout'
import CircleIcon from '@mui/icons-material/Circle'
import { Container, Link, Box, Stack, Button, Typography, Badge } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useAuth } from 'hooks/useAuth'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import { useGetInformationList } from '../api/information'
import type { Information }  from '../api/information/responseType'
import { ConfirmModal, ConfirmModalProps } from 'components/ConfirmModal'
import SideNews  from '../components/SideNews'
import { useParams } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useWindowSize } from 'lib/useWindowSize'
import { Cached, CheckCircle, Download } from '@mui/icons-material'
import { useGetExportStatus } from 'api/export'
import useResetQuery from 'api/useResetQuery'

type ConfirmModalOnCloseProps = {
  modalStatus: string
}

type LayoutProps = {
  children: React.ReactNode
}

const DrawerNav = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: 200,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(6),
        },
      }),
    },
  }),
)

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  const { windowWidth } = useWindowSize()
  const [open, setOpen] = useState(windowWidth >= 1025)
  const [openDraw, setOpenDraw] = useState(false)
  const [confirmModalConfig, setConfirmModalConfig] = useState<ConfirmModalProps | undefined>()
  const [ posts, setPosts ] = useState<Information[]>([])
  const {
    data: informationData, isError: isInformationError, isPending: isInformationPending
  } = useGetInformationList()
  const {
    data: exportStatusData, isError: isExportStatusError, isPending: isExportStatusPending
  } = useGetExportStatus()
  const [ exportProcessing, setExportProcessing ] = useState<boolean>(false)
  const [ exportCompleted, setExportCompleted ] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const [ loaded, setLoaded ] = useState<boolean>(false)
  const resetQuery = useResetQuery()

  if (!loaded) {
    setLoaded(true)
    resetQuery(['exportStatus'])
  }

  useEffect(() => {
    if (isInformationError || isExportStatusError) {
      console.error(isInformationError)
      console.error(isExportStatusError)
      return
    }

    if(!isInformationPending && informationData !== undefined){
      setPosts(informationData.information)
    }

    if(!isExportStatusPending && exportStatusData !== undefined){
      setExportProcessing(exportStatusData.processing)
      setExportCompleted(exportStatusData.completed)
    }
  }, [
    isInformationError, informationData, isInformationPending,
    exportStatusData, isExportStatusError, isExportStatusPending
  ])

  useEffect(() => {
    setOpen(windowWidth >= 1025)
  }, [windowWidth])

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const auth = useAuth()
  const executeSignOut = async () => {
    const ret = await new Promise<ConfirmModalOnCloseProps>((resolve) => {
      setConfirmModalConfig({
        onClose: resolve,
        title: 'ログアウトしますか？'
      })
    })
    setConfirmModalConfig(undefined)
    if (ret.modalStatus === 'cancel') {
      return
    }else if(ret.modalStatus === 'ok') {
      auth.SignOut()
    }
  }

  const toggleNewsDrawer = (openDraw: boolean) => () => {
    setOpenDraw(openDraw)
  }

  const DrawerList = (
    <Box sx={{ width: 500 }} onClick={toggleNewsDrawer(false)}>
      <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "16px" }} >
            お知らせ
          </Typography>
      </Box>      
      <List sx={{overflow: "scroll"}} >
      {posts.map((post, index) => {
            const divider = posts.length - 1 > index ? (<Divider component="li" sx={{ background: "#B9B9B9"}} /> ) : (<></>)
            return (
              <React.Fragment key={post.id}>
                <SideNews newsItemData={post}/>
                {divider}
              </React.Fragment>
            )}              
        )}                                                       
      </List>
    </Box>
  )

  return (
    <>
      <Box sx={{ display: "flex" }} ref={ref}>
        <ThemeProvider theme={theme}>
          <DrawerNav variant="permanent" open={open}>
            <Stack sx={{ pt: 4 }}>
              { open ? <img src={logo} alt="oralisロゴ" style={{ width: "90px", display: "block", margin: "0 auto" }}/>
                : <Box sx={{ height: "59px" }}></Box> }
            </Stack>
            <SideMenu />
            <Stack sx={{ pt: 6, alignItems: "flex-start", pl: 1 }} >
              <IconButton onClick={toggleDrawer} sx={{ p: 0, "&:hover": { backgroundColor: "transparent" } }}>
                { open ? <ArrowCircleLeftIcon sx={{ color: "white", minWidth: "32px" }} />
                : <ArrowCircleRightIcon sx={{ color: "white", minWidth: "32px" }} /> }
              </IconButton>
            </Stack>
          </DrawerNav>
          <Box
            component="main"
            sx={{
              backgroundColor:"#F2F3F7",
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
              position: "relative",
              width: "100%",
            }}
          >
            <Container maxWidth="lg" sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  width: "calc(100% - 48px)",
                  height: "64px",
                  zIndex: "1",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    background: "white",
                    borderBottomRightRadius:"12px",
                    borderBottomLeftRadius:"12px",
                    py: "16px",
                    px: "24px",
                    justifyContent: "flex-end",
                    columnGap: "24px",
                    boxShadow: "1px 1px 6px -2px rgba(0, 0, 0, 0.1)",
                  }}>
                  <Box sx={{ display: "flex", columnGap: "4px", alignItems: "center" }}>
                    {
                      exportProcessing ?
                      <Badge 
                        badgeContent={
                          <Cached fontSize='small' sx={{color: theme.palette.primary.main}}/>
                        }
                      >
                        <Download sx={{ fontSize: "xs", color: theme.palette.text.primary}}/>
                      </Badge> :
                      exportCompleted ?
                      <Badge 
                        badgeContent={
                          <CheckCircle fontSize='small' sx={{color: theme.palette.primary.main}}/>
                        }
                      >
                        <Download sx={{ fontSize: "xs", color: theme.palette.text.primary}}/>
                      </Badge> :
                      <Download sx={{ fontSize: "xs", color: theme.palette.text.primary}}/>
                    }
                    <Link href={`/${clinicId}/export`} sx={{ color: theme.palette.text.primary, fontWeight: "bold", textDecoration: "none", fontSize: "small"}}>
                      エクスポート
                    </Link>
                  </Box>
                  <Box sx={{ display: "flex", columnGap: "4px", alignItems: "center" }}>
                    <NotificationsIcon sx={{ fontSize: "xs", color: theme.palette.text.primary}}/>
                    <Button onClick={toggleNewsDrawer(true)}>
                      <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", textDecoration: "none", fontSize: "small"}}>
                        お知らせ
                      </Typography>
                    </Button>
                    <Drawer
                      anchor='right'
                      open={openDraw}
                      onClose={toggleNewsDrawer(false)}
                    >
                      {DrawerList}
                    </Drawer>
                  </Box>  
                  <Box sx={{ display: "flex", columnGap: "4px", alignItems: "center" }}>
                    <CircleIcon sx={{ fontSize: "18px", color: "#30C196"}}/>
                    <Link href={`/${clinicId}/setting`} sx={{ color: theme.palette.text.primary, fontWeight: "bold", textDecoration: "none", fontSize: "small"}}>
                      {auth.clinicname}
                    </Link>
                  </Box>
                  <Box sx={{ display: "flex", columnGap: "4px", alignItems: "center" }}>
                    <LogoutIcon sx={{ fontSize: "xs", color: theme.palette.text.primary}}/>
                    <Link href="#" sx={{ color: theme.palette.text.primary, fontWeight: "bold", textDecoration: "none", fontSize: "small"}} onClick={executeSignOut}>
                      ログアウト
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Container>
            <Scrollbars style={{ height: "100vh" }}>
              <Container maxWidth="lg" sx={{ position: "relative", pt: 8 }}>
                {children}
              </Container>
            </Scrollbars>
          </Box>
          {confirmModalConfig && <ConfirmModal {...confirmModalConfig} />}
        </ThemeProvider>
      </Box>
    </>
  )
}
export default Layout
