import React, { useState, useRef, useEffect } from 'react'
import { Typography, Box, Stack, FormControl, RadioGroup, ImageListItem, ImageList, Radio, Checkbox, FormGroup, FormLabel, ImageListItemBar } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../../style/theme'
import CircularProgressWithLabel from './CircularProgressWithLabel'
import { ImageType, skeletalImages, faceTypeImages, angleRightImages, angleLeftImages, otherElementImages } from 'types/PatientObjects'
import { useWindowSize } from 'lib/useWindowSize'

export type Patient04Props = {
  show: boolean
  handleChange: Function
  skeletal?: number
  faceType?: number
  angleRight?: number
  angleLeft?: number
  otherElement: number[]
  skeletalError: string[]
  faceTypeError: string[]
  angleRightError: string[]
  angleLeftError: string[]
  otherElementError: string[]
}

const ImageListItemCheck = (props: {image: ImageType, checked: boolean}) => {
  const { image, checked } = props
  const border = checked ? "2px solid #22BDB4" : "2px solid #808080"

  const { windowWidth } = useWindowSize()
  const [ isIpad, setIsIpad] = useState(windowWidth >= 1025)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setIsIpad(windowWidth >= 1025)
  }, [windowWidth])

  return (
    <Box ref={ref}>
      <ImageListItem sx={{
        border: border, borderRadius: "8px", "&:hover": { background: "transparent" },
        width: isIpad ? "136px" : "110px" , height: isIpad ? "136px" : "110px", pt: 0, pb: 4, px: 2
      }}
      >
        {
          image.key !== 99 ? (
            <img
              src={`${process.env.PUBLIC_URL}${image.url}`}
              srcSet={`${process.env.PUBLIC_URL}${image.url}`}
              loading='lazy'
              alt=''
            />
          ) : (
            <Box width={isIpad ? 136 : 100} height={isIpad ? 136 : 100} />
          )
        }
        <ImageListItemBar
          position="below"
          sx={{textAlign: "center"}}
          title={`${image.title}`}
        />
      </ImageListItem>
    </Box>
  )
}

const Patient04 = (props: Patient04Props) => {
  const { show, handleChange, skeletal, faceType, angleRight, angleLeft, otherElement, skeletalError, faceTypeError, angleRightError, angleLeftError, otherElementError } = props
  const [progress] = useState(80)
  const display = show ? "block" : "none"

  const { windowWidth } = useWindowSize()
  const [ isIpad, setIsIpad] = useState(windowWidth >= 1025)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setIsIpad(windowWidth >= 1025)
  }, [windowWidth])

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: display }} ref={ref}>
          <Stack sx={{ display: "flex", alignItems: "center", columnGap: isIpad ? "24px" : 1 }} direction = "row">
            <Box sx={{ mt: 3, position: 'relative' }}>
              <Box
                sx={{ 
                  margin: "auto",
                  width: "54px",
                  height: "54px",
                  position: 'absolute',
                  border: "solid #D8EAE9 5px",
                  borderRadius: "50%",
                  top: 0,
                  left: 0,
                }}>
              </Box>
              <CircularProgressWithLabel progress={progress} page={4} />
            </Box>
            <Box><Typography sx={{ fontWeight: "600", backgroundColor: "#F1F1F1", borderRadius: "8px", py: 2, px: 4, mt: 2 }} color="#364F4E">状態を追加してください。</Typography></Box>
          </Stack>
          <Stack sx={{ mt: 4 }}>
            <Stack sx={{ ml: 4 }} alignItems="center" columnGap={isIpad ? 4 : 1} direction = "row">
              <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "14px" }}>骨格的要素</FormLabel>
              <Typography sx={{ fontWeight: "600", color: "white", background: "#F4817A", borderRadius: "24px", fontSize: "12px", px: 2 }}>必須</Typography>
            </Stack>
            <FormControl>
              <RadioGroup
                value={skeletal}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'skeletal')}
              >
                <ImageList
                  variant='woven'
                  cols={4}
                >
                  {skeletalImages.map((image) => (
                    <Radio
                      key={image.key}
                      value={image.key}
                      icon={
                        <ImageListItemCheck image={image} checked={false} />
                      }
                      checkedIcon={
                        <ImageListItemCheck image={image} checked={true} />
                      }
                    />
                  ))}
                </ImageList>
              </RadioGroup>
            </FormControl>
            {skeletalError.map((error, index) => {
              return (
                <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
              )
            })}
          </Stack>
          <Stack sx={{ mt: 4 }}>
            <Stack sx={{ ml: 4 }} alignItems="center" columnGap={isIpad ? 4 : 2} direction = "row">
              <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "14px" }}>顔貌</FormLabel>
              <Typography sx={{ fontWeight: "600", color: "white", background: "#F4817A", borderRadius: "24px", fontSize: "12px", px: 2 }}>必須</Typography>
            </Stack>
            <FormControl>
              <RadioGroup
                value={faceType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'faceType')}
              >
                <ImageList
                  variant='woven'
                  cols={4}
                >
                  {faceTypeImages.map((image) => (
                    <Radio
                      key={image.key}
                      value={image.key}
                      icon={
                        <ImageListItemCheck image={image} checked={false} />
                      }
                      checkedIcon={
                        <ImageListItemCheck image={image} checked={true} />
                      }
                    />
                  ))}
                </ImageList>
              </RadioGroup>
            </FormControl>
            {faceTypeError.map((error, index) => {
              return (
                <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
              )
            })}
          </Stack>
          <Stack sx={{ mt: 4 }}>
            <Stack sx={{ ml: 4 }} alignItems="center" columnGap={isIpad ? 4 : 2} direction = "row">
              <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "14px" }}>咬合関係（右）</FormLabel>
              <Typography sx={{ fontWeight: "600", color: "white", background: "#F4817A", borderRadius: "24px", fontSize: "12px", px: 2 }}>必須</Typography>
            </Stack>
            <FormControl>
              <RadioGroup
                value={angleRight}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'angleRight')}
              >
                <ImageList
                  variant='woven'
                  cols={5}
                >
                  {angleRightImages.map((image) => (
                    <Radio
                      key={image.key}
                      value={image.key}
                      icon={
                        <ImageListItemCheck image={image} checked={false} />
                      }
                      checkedIcon={
                        <ImageListItemCheck image={image} checked={true} />
                      }
                    />
                  ))}
                </ImageList>
              </RadioGroup>
            </FormControl>
            {angleRightError.map((error, index) => {
              return (
                <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
              )
            })}
          </Stack>
          <Stack sx={{ mt: 4 }}>
            <Stack sx={{ ml: 4 }} alignItems="center" columnGap={isIpad ? 4 : 2} direction = "row">
              <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "14px" }}>咬合関係（左）</FormLabel>
              <Typography sx={{ fontWeight: "600", color: "white", background: "#F4817A", borderRadius: "24px", fontSize: "12px", px: 2 }}>必須</Typography>
            </Stack>
            <FormControl>
              <RadioGroup
                value={angleLeft}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'angleLeft')}
              >
                <ImageList
                  variant='woven'
                  cols={5}
                >
                  {angleLeftImages.map((image) => (
                    <Radio
                      key={image.key}
                      value={image.key}
                      icon={
                        <ImageListItemCheck image={image} checked={false} />
                      }
                      checkedIcon={
                        <ImageListItemCheck image={image} checked={true} />
                      }
                    />
                  ))}
                </ImageList>
              </RadioGroup>
            </FormControl>
            {angleLeftError.map((error, index) => {
              return (
                <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
              )
            })}
          </Stack>
          <Stack sx={{ mt: 4 }}>
            <Stack sx={{ ml: 4 }} alignItems="center" columnGap={isIpad ? 4 : 2} direction = "row">
              <FormLabel sx={{ color: theme.palette.text.primary , fontWeight: "bold", fontSize: "14px" }}>その他要素</FormLabel>
            </Stack>
            <FormControl>
              <FormGroup
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'otherElement')}
              >
                <ImageList
                  variant='woven'
                  cols={3}
                  gap={1}
                >
                  {otherElementImages.map((image) => (
                    <Checkbox
                      key={image.key}
                      value={image.key}
                      checked={otherElement.includes(image.key)}
                      icon={
                        <ImageListItemCheck image={image} checked={false} />
                      }
                      checkedIcon={
                        <ImageListItemCheck image={image} checked={true} />
                      }
                    />
                  ))}
                </ImageList>
              </FormGroup>
            </FormControl>
            {otherElementError.map((error, index) => {
              return (
                <Typography key={index} sx={{ color: "red", fontSize: "12px"}}>{error}</Typography>
              )
            })}
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Patient04
