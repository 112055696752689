import env from 'env'

interface EnvironmentMap {
  [key: string]: string
}

export const getImageUrl = (input: string): string => {
  const bucketPrefix = 'oralis-photo'

  const environmentMap: EnvironmentMap = {
    [`${bucketPrefix}-dev`]: 'https://dev.img.oralis.jp/',
    [`${bucketPrefix}-stg`]: 'https://stg.img.oralis.jp/',
    [`${bucketPrefix}-prod`]: 'https://img.oralis.jp/',
    [`${bucketPrefix}-migrate-dev`]: 'https://dev.img.oralis.jp/migrate/',
    [`${bucketPrefix}-migrate-stg`]: 'https://stg.img.oralis.jp/migrate/',
    [`${bucketPrefix}-migrate-prod`]: 'https://img.oralis.jp/migrate/',
  }

  const pathRegex = input.includes('migrate')
    ? new RegExp(`^(${bucketPrefix}-migrate-(dev|stg|prod))/(.*)$`)
    : new RegExp(`^(${bucketPrefix}-(dev|stg|prod))/(.*)$`)
  const match = input.match(pathRegex)

  if (!match) {
    return input
  }

  const environmentKey = match[1]
  const path = match[3]
  const baseUrl = environmentMap[environmentKey]

  if (baseUrl) {
    return baseUrl + path
  } else {
    throw new Error('Unknown environment key: ' + environmentKey)
  }
}

export const getExportUrl = (clinicId: string, filename: string): string => {
  if (env.ENVIRONMENT === 'prod') {
    return `https://img.oralis.jp/${clinicId}/export/${filename}`
  }
  return `https://${env.ENVIRONMENT}.img.oralis.jp/${clinicId}/export/${filename}`
}
