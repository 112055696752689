import React, { useEffect } from 'react'
import { Typography, Box, Stack, CircularProgress } from '@mui/material'
import Layout from '../../components/Layout'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../style/theme'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import PageBackButton from '../../components/PageBackButton'
import { useParams } from 'react-router-dom'
import { useGetCapacityDetailReport } from 'api/report'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useInView } from 'react-intersection-observer'
import OperationProof from 'components/OperationProof'

const DashboardPaymentReport = () => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()
  dayjs.locale(ja)

  const { ref, inView } = useInView()

  const {
    data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage
  } = useGetCapacityDetailReport()

  useEffect(() => {
    if (isError) {
      console.error(isError)
      return
    }

    if (hasNextPage && inView) {
      fetchNextPage()
    }
  }, [inView, isError, hasNextPage, fetchNextPage])

  if (isLoading || !data) {
    return <OperationProof initial/>
  }

  const capacity = data.pages.map(item => item.data).flat()

  const convertMonth = (date: string) => {
    const converted = dayjs(date).format('YYYY年MM月')
    return converted
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={`/${clinicId}/dashboard`} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1, mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <QueryStatsIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>使用データ量</Typography>
            </Box>
            {
              capacity.map((item, index) => {
                return (
                  <Box sx={{ border: "1px solid #E4E4E4", mt: 3 }} key={index}>
                    <Box sx={{ background: "#FBFBFB", borderBottom: "1px solid #E4E4E4" }}>
                      <Typography sx={{ fontWeight: "600", fontSize: "16px", p: 2 }}>
                        {convertMonth(item.month)}のデータ量
                      </Typography>
                    </Box>
                    <List>
                      <ListItem>
                        <Stack sx={{ alignItems: "center", columnGap: "24px" }} direction="row">
                          <Typography sx={{ fontSize: "14px", p: 0 }}>データ量</Typography>
                          <Typography sx={{ fontSize: "18px", p: 0, fontWeight: "600" }}>{item.capacity}</Typography>
                        </Stack>
                      </ListItem>
                      <ListItem>
                        <Stack sx={{ alignItems: "center", columnGap: "24px" }} direction="row">
                          <Typography sx={{ fontSize: "14px", p: 0 }}>超過量</Typography>
                          <Typography sx={{ fontSize: "18px", p: 0, fontWeight: "600" }}>{item.excessed}</Typography>
                        </Stack>
                      </ListItem>
                      <Divider component="li"  sx={{ my: 1 }}/>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>基本料金 {item.fee.toLocaleString()}円</Typography>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontSize: "14px", p: 0 }}>超過料金 {item.excessFee.toLocaleString()}円</Typography>
                      </ListItem>
                      <Divider component="li"  sx={{ my: 1 }}/>
                      <ListItem sx={{ justifyContent: "flex-end" }}>
                        <Stack sx={{ display: "flex", alignItems: "center", columnGap: "24px"}} direction="row">
                          <Typography sx={{ fontSize: "14px", p: 0 }}>請求金額</Typography>
                          <Typography sx={{ fontSize: "22px", p: 0, fontWeight: "600" }}>
                            {(item.fee + item.excessFee).toLocaleString()}円
                          </Typography>
                        </Stack>
                      </ListItem>
                    </List>
                  </Box>
                )
              })
            }
          </Box>
          {
            isFetchingNextPage ? (
              <Stack alignItems="center" justifyContent="center" mb={2}>
                <CircularProgress />
              </Stack>
            ) : (
              <Box ref={ref}/>
            )
          }
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default DashboardPaymentReport