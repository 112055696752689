import React from 'react';
import { Box, Typography, Stack, Button, Table, TableBody, TableRow, TableCell, Link } from '@mui/material';
import Modal from '@mui/material/Modal';
import theme from '../style/theme';
import { ExportSetting } from 'api/setting/responseType';

export type ConfirmExportModalOnCloseProps = {
  modalStatus: string
}

export type ConfirmExportModalProps = {
  onClose: (ConfirmExportModalOnCloseProps:{ modalStatus :string }) => void
  setting: ExportSetting | null
  target?: string
  clinicId: string
}

export const ConfirmExportModal = (props: ConfirmExportModalProps) => {
  const { onClose, setting, target, clinicId } = props

  return (
    <>
      <Modal
        open onClose={() => onClose({modalStatus: 'close'})}
      >
        <Box sx={{ p:6, width: 600, position: "absolute",top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", borderRadius: "8px"}}>
          <Stack sx={{ alignItems: "center" }} direction="column">
            <Typography fontSize={18} textAlign="center" fontWeight="bold" mt={1}>
              以下の設定でエクスポートを開始します。よろしいでしょうか？
            </Typography>
            <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)", tableLayout: "fixed", mt: 2 }}>
              <TableBody sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)", borderRight: "1px solid rgba(224, 224, 224, 1)", borderLeft: "1px solid rgba(224, 224, 224, 1)"}} >
                <TableRow>
                  <TableCell width={40} sx={{ p: 1, background: "#F4F4F4", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    <Typography fontSize={12} fontWeight="bold">
                      出力対象
                    </Typography>
                  </TableCell>
                  <TableCell width={80} sx={{ p: 1, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    <Typography fontSize={12}>
                      {target}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={40} sx={{ p: 1, background: "#F4F4F4", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    <Typography fontSize={12} fontWeight="bold">
                      患者フォルダ名
                    </Typography>
                  </TableCell>
                  <TableCell width={80} sx={{ p: 1, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    <Typography fontSize={12}>
                      {setting?.patientType ? '患者URL ID' : '患者番号'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={40} sx={{ p: 1, background: "#F4F4F4", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    <Typography fontSize={12} fontWeight="bold">
                      撮影日フォルダ名
                    </Typography>
                  </TableCell>
                  <TableCell width={80} sx={{ p: 1, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    <Typography fontSize={12}>
                      {setting?.examType ? '撮影日URL ID' : '撮影日'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography mt={2} width={1 / 1} fontSize={14}>
              設定を変更する場合は、
              <Link href={`/${clinicId}/setting/export`} sx={{ color: theme.palette.primary.main, fontWeight: "bold", textDecoration: "none"}}>
                エクスポート設定
              </Link>
              より設定を変更してください。
            </Typography>
            <Typography width={1 / 1} fontSize={14}>
              エクスポート完了には時間がかかります。エクスポート一覧メニューより、状況を確認してください。
            </Typography>
            <Stack sx={{ alignItems: "center", columnGap: "24px", justifyContent: "flex-end", mt: 2 }} direction="row">
              <Button
                onClick={() => onClose({ modalStatus: 'ok' })}
                sx={{ textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 8, fontWeight: "bold", mt: 3, "&:hover": { background: "#11867F" } }}
                >
                  はい
              </Button>
              <Button
                onClick={() => onClose({modalStatus: 'close' })}
                sx={{ textAlign: "center", fontSize: "14px", color: theme.palette.primary.main, background: "white", borderRadius: "40px", px: 8, fontWeight: "bold", border: "1px solid #22BDB4",mt: 3, "&:hover": { background: theme.palette.primary.main, color: "white" } }}>
                  いいえ
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
export default ConfirmExportModal;